import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Routes, RouterModule } from '@angular/router';
//import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { passwordComponent } from './password.component';
import { SegmentService } from '../common/_service/segment.service';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatProgressSpinnerModule, MatInputModule } from '@angular/material';

@NgModule({
  imports: [RouterModule, CommonModule, FormsModule, ReactiveFormsModule, MatProgressSpinnerModule, NgbModule, MatInputModule, MatButtonModule, MatIconModule, MatFormFieldModule],
  declarations: [passwordComponent],
  providers: [SegmentService]
})
export class passwordModule { }