import { Component, OnInit, ViewChild, Inject, Optional } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MatTableDataSource,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppUrl } from "../../common/config/apiurl";
import { SegmentService, UtilService } from "../../common/_service/index";
import { ConfigSettings } from "../../common/config/config";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
export interface Fruit {
  name: string;
}
@Component({
  templateUrl: "./report-scheduler.component.html",
  styleUrls: ["./report-scheduler.component.css"],
})
export class ReportSchedulerComponent implements OnInit {
  @ViewChild("successrule", { static: true }) successrule: any;
  @ViewChild("errorrrule", { static: true }) errorrrule: any;
  @ViewChild("emaillist", { static: true }) emaillist: any;
  @ViewChild("rspageslist", { static: true }) rspageslist: any;
  loader: boolean = false;
  op: any = "add";
  reportSchDT: object = [];
  config: any = ConfigSettings;
  success_msg: any = "";
  errorMessage: any = "";
  public roles: any = "";
  selectedValue: any = "";
  repeatfreqweeklysel: any = "";
  showRepeatFreqWeekly = false;
  minDate: Date;
  maxDate: Date;
  emaillistdd: any = [];
  rspageslistdd: any = [];
  ELEMENT_DATA: any = [];
  tblDT: any = [];
  tblDTPages: any = [];
  repeatFreqDD: any = {};
  reportSchForm: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReportSchedulerComponent>,
    private _service: SegmentService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private _utilservice: UtilService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reportSchDT = data ? data : {};
    // if (data) {
    if (data.hasOwnProperty("email_ids")) {
      this.tblDT = this.reportSchDT["email_ids"];
      this.tblDTPages = this.reportSchDT["page_ids"];
      this.op = this.reportSchDT["op"] ? this.reportSchDT["op"] : "add";
      this.showRepeatFreqWeekly =
        this.reportSchDT["repeatfreq"] == "weekly" ? true : false;
      this.repeatfreqweeklysel = this.reportSchDT["repeatfreqweekly"];
    }
    // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(); //new Date(currentYear - 1, 0, 1);
    this.maxDate = new Date(
      currentYear + 2,
      new Date().getMonth(),
      new Date().getDate()
    );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our email
    if ((value || "").trim()) {
      this.tblDT.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  remove(fruit: Fruit): void {
    const index = this.tblDT.indexOf(fruit);
    if (index >= 0) {
      this.tblDT.splice(index, 1);
    }
  }

  closeMe() {
    this.dialogRef.close();
  }

  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  ngOnInit() {
    this.reportSchForm = this.formBuilder.group({
      sdate: [this.reportSchDT["sdate"], [Validators.required]],
      repeatfreq: [this.reportSchDT["repeatfreq"], [Validators.required]],
      repeatfreqweekly: [this.reportSchDT["repeatfreqweekly"]],
    });
    this.renderRepeatFreqDD();
    this.getRspagesList();
    this.selectedValue = this.reportSchDT["repeatfreq"];
  }

  /**
   * @Method : getEmailList
   * @description : Get emails list
   **/
  getRspagesList() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/admin/adv/getRspagesList";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.rspageslistdd = data["d"];
          this.renderRspagesList(data["d"]);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
          this.closeMe();
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
   * @Method : renderRspagesList
   * @description : Render advertiser list
   **/
  renderRspagesList(data: any) {
    let xDtPages = [];
    let sarrPages = [];
    if (this.tblDTPages && this.tblDTPages.length > 0) {
      this.tblDTPages.filter((elm: any) => {
        sarrPages.push(String(elm));
      });
    }
    for (let key in data) {
      if (data[key]["id"]) {
        let _ccode = data[key]["id"];
        let _show = sarrPages.indexOf(String(_ccode)) != -1 ? true : false;
        xDtPages.push({ id: _ccode, n: data[key]["name"], show: _show });
      }
    }
    this.rspageslist ? this.rspageslist.rspagesDataList(xDtPages) : "";
  }

  removeRspagesTag(tagname: any) {
    for (let i = 0; i < this.rspageslist.jsObjPages.length; i++) {
      if (this.rspageslist.jsObjPages[i]["id"] == tagname["id"]) {
        this.rspageslist.jsObjPages[i]["show"] = false;
      }
    }
    this.rspageslist.rspagesDataList(this.rspageslist.jsObjPages);
  }

  /**
   * @Method : renderRepeatFreqDD
   * @description : render repeat frequency dropdown
   **/
  renderRepeatFreqDD() {
    this.repeatFreqDD = this.config["REPEAT_FREQ"];
  }

  onRepeatFreqChange(repeatVal) {
    if (repeatVal == "weekly") {
      this.showRepeatFreqWeekly = true;
    } else {
      this.repeatfreqweeklysel = "";
      this.showRepeatFreqWeekly = false;
    }
  }
  /**
   * @Method : addReportSceduler
   * @description : Save user detail/roles/permission
   **/
  addReportSceduler() {
    let rdt = {};
    let _userDT = this._service._getUserDt();
    let activeAdvId = this._service.getParamFromLocalStorage("activeAdvId");
    let _url = AppUrl.getAPIURL();
    _url = _url + "/admin/adv/saveReportScheduler";
    let data = this.reportSchForm.value;
    let repeatfreqweeklyval =
      data["repeatfreq"] == "weekly" && data["repeatfreqweekly"]
        ? data["repeatfreqweekly"]
        : "";
    rdt = {
      op: this.op,
      adv_id: activeAdvId,
      email_ids: this.tblDT.join(),
      page_ids: this.rspageslist.rspagesLbl.join(),
      sdate: data["sdate"],
      repeatfreq: data["repeatfreq"],
      repeatfreqweekly: repeatfreqweeklyval,
    };
    let isvalid = this.isValidForm(data);
    if (isvalid == false) {
      return;
    }

    this.loader = true;
    rdt["puser_id"] = _userDT["user_id"];
    this._service._postApi(_url, rdt).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.success_msg = data.msg;
          this.success(this.successrule);
          this.closeMe();
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  email_empty: boolean = false;
  invalid_email: boolean = false;
  pages_empty: boolean = false;
  weekday_empty: boolean = false;
  isValidForm(data: any) {
    if (!this.tblDT || this.tblDT.length == 0) {
      this.email_empty = true;
      return false;
    } else {
      this.email_empty = false;
    }

    for (let emailid of this.tblDT) {
      if (this._utilservice.validateEmail(emailid).status == false) {
        this.invalid_email = true;
        return false;
      } else {
        this.invalid_email = false;
      }
    }

    if (
      !this.rspageslist.rspagesLbl ||
      this.rspageslist.rspagesLbl.length == 0
    ) {
      this.pages_empty = true;
      return false;
    } else {
      this.pages_empty = false;
    }

    if (
      this.reportSchForm.get("repeatfreq").value == "weekly" &&
      this.reportSchForm.get("repeatfreqweekly").value == ""
    ) {
      this.weekday_empty = true;
      return false;
    } else {
      this.weekday_empty = false;
    }
    if (this.reportSchForm["status"] == this.config["INVALID_FORM"]) {
      return false;
    }
    return true;
  }

  /**
   * @Method : renderRepeatFreqDD
   * @description : render repeat frequency dropdown
   **/
  deleteReportSceduler() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/admin/adv/delAdvReportSchedulerData";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.success_msg = data.msg;
          this.success(this.successrule);
          this.closeMe();
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
          this.closeMe();
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  closeError() {
    this.activeModal.close();
  }
}

export interface PeriodicElement {
  account: string;
}
