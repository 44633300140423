import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { SegmentService } from "../../common/_service/segment.service";
import { UtilService } from "../../common/_service/util.service";
import { ConfigSettings } from "../../common/config/config";
import { AppUrl } from "../../common/config/apiurl";
@Component({
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ReportComponent implements OnInit {
  @Input() event: Event;
  loader: boolean = false;
  _config: any = ConfigSettings;
  public iFrameUrl: any;
  public showNoDataDiv: any = false;
  constructor(
    private _service: SegmentService,
    private _router: Router,
    private _utilservice: UtilService
  ) {
    _service.validateUser();
  }

  ngOnInit() {
    // this.renderDashboard();
  }

  renderReport(data: any) {
    this.loader = true;
    this.showNoDataDiv = false;
    let page_id =
      data && data["page_id"] != ""
        ? data["page_id"]
        : this._service.getCurrentPageId();
    // this._service.setCurrentPageIdS(page_id);
    console.log('page_id in render report ', page_id);
    var _url = AppUrl.getAPIURL();
    let user_id = this._service.getUserid();
    _url =
      _url + "/analytics/getActivePageIframeUrl/" + page_id + "/" + user_id;
    this._service.getDefaultApi(_url).subscribe(
      (data: any) => {
        if (data.s == 200) {
          let iFrameUrl =
            data["d"][0] && data["d"][0]["iframe_url"] != ""
              ? data["d"][0]["iframe_url"]
              : "";
          if (iFrameUrl == "") {
            this.loader = false;
            this.showNoDataDiv = true;
          } else {
            this._service.setCurrentPageIdS(page_id);
            let ifrm = this._utilservice.prepareIframe(iFrameUrl);
            document.getElementById("dashboard").appendChild(ifrm);
            window.setTimeout(() => {
              var iframe: any = document.getElementById("frameStyle");
              var iframeDoc =
                iframe.contentDocument || iframe.contentWindow.document;
              if (iframeDoc.readyState == "complete") {
                iframe.onload = () => {
                  this.loader = false;
                };
                return;
              }
            }, 100);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
}
