import { NgModule } from '@angular/core';
import { CoreModule } from "./core/core.analytics.module";
import { sharedModule } from "../../common/shared.module";
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  imports: [CoreModule, sharedModule],
  declarations: [SidebarComponent],
  exports: [SidebarComponent, sharedModule]
})

export class cSharedModule { }
