import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  templateUrl: "./analytics.component.html",
  styleUrls: ["./analytics.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AnalyticsComponent implements OnInit {
  sendNavData: any = [];
  constructor(private router: Router) {}

  ngOnInit() {}

  sendSidebarNav($event: any) {
    this.sendNavData = $event;
  }

  // public compRef: any;
  onActivate(componentRef: any) {
    componentRef.renderReport(this.pageData);
  }

  public pageData: any;
  childEventClicked(data: any) {
    if (data) {
      this.pageData = data;
    }
  }
}
