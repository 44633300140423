import { NgModule } from "@angular/core";
import { CoreModule } from "./common/core/core.analytics.module";
import { AnalyticsComponent } from "./analytics.component";
import { AnalyticsRoutingModule } from "./analytics.routing.module";
import { ReportComponent } from "./report/report.component";
import { StaticContentComponent } from "./staticcontent/staticcontent.component";
import { CampanalyticsComponent } from "./campanalytics/campanalytics.component";
import { ReportSchedulerComponent } from "./report-scheduler/report-scheduler.component";
import { RspagesListComponent } from "./common/rspages-list/rspages-list.component";
import { cSharedModule } from "./common/shared.analytics.module";
@NgModule({
  declarations: [
    AnalyticsComponent,
    ReportComponent,
    CampanalyticsComponent,
    StaticContentComponent,
    ReportSchedulerComponent,
    RspagesListComponent,
  ],
  imports: [CoreModule, AnalyticsRoutingModule, cSharedModule],
  entryComponents: [ReportSchedulerComponent],
})
export class AnalyticsModule {}
