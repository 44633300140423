import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatTableDataSource } from "@angular/material";
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUrl } from '../../../common/config/apiurl';
import { SegmentService } from '../../../common/_service/segment.service';
import { ConfigSettings } from '../../../common/config/config';

@Component({
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.css"],
})
export class AddUserComponent implements OnInit {
  @ViewChild('successrule', { static: true }) successrule: any;
  @ViewChild('errorrrule', { static: true }) errorrrule: any;
  @ViewChild('advertiserlist', { static: true }) advertiserlist: any;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddUserComponent>,
    private _service: SegmentService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
  ) { }
  loader: boolean = false;
  config: any = ConfigSettings;
  success_msg: any = '';
  errorMessage: any = '';
  public roles: any = '';
  selectedValue: any = '';
  advertiserList: any = [];
  ELEMENT_DATA: any = [];
  tblDT: any = [];
  ruleForm: FormGroup;
  closeMe() {
    this.dialogRef.close();
  }

  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  ngOnInit() {
    this.ruleForm = this.formBuilder.group({
      "uname": ['', [Validators.required, Validators.pattern(this.config['USER_NAME_PATTERN'])]],
      "uemail": ['', [Validators.required, Validators.pattern(this.config['EMAIL_PATTERN'])]],
      "role": ['', [Validators.required]],
    });

    this.getRoleList();
    this.getAdvertiser();
  }

  getRoleList() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/user/getRoleList';
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.loader = false;
          this.roles = data['d'] ? data['d'] : [];
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
 * @Method : getAdvertiser
 * @description : Get advertiser details
 **/
  getAdvertiser() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/user/getAdvertiserList';
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.advertiserList = data['d'];
          this.renderAdvertiserList(data['d']);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
          this.closeMe();
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
 * @Method : renderAdvertiserList
 * @description : Render advertiser list
 **/
  renderAdvertiserList(data: any) {
    let xDt = [];
    let sarr = [];
    this.tblDT.filter((elm: any) => {
      sarr.push(String(elm['id']));
    });
    for (let key in data) {
      if (data[key]['id']) {
        let _ccode = data[key]['id'];
        let _show = sarr.indexOf(String(_ccode)) != -1 ? true : false;
        xDt.push({ id: _ccode, n: data[key]['name'], show: _show });
      }
    }
    this.advertiserlist ? this.advertiserlist.pubDataList(xDt) : "";
  }

  removeAdvertiserTag(tagname: any) {
    for (let i = 0; i < this.advertiserlist.jsObj.length; i++) {
      if (this.advertiserlist.jsObj[i]['id'] == tagname['id']) {
        this.advertiserlist.jsObj[i]['show'] = false;
      }
    }
    this.advertiserlist.pubDataList(this.advertiserlist.jsObj);
  }

  /**
  * @Method : addUserAccount
  * @description : Save user detail/roles/permission
  **/
  addUser() {
    let rdt = {};
    let _userDT = this._service._getUserDt();
    let _url = AppUrl.getAPIURL();
    _url = _url + '/user/createNewUser';
    let data = this.ruleForm.value;

    if (data['role'] == 1) {
      rdt = {
        uname: data['uname'],
        uemail: data['uemail'],
        urole: data['role'],
        adv_ids: this.config['DEFAULT_DEMO_ADV_ID']
      };
    } else {
      rdt = {
      uname: data['uname'],
      uemail: data['uemail'],
      urole: data['role'],
      adv_ids: this.advertiserlist.advLbl.join()
    };

    let isvalid = this.isValidForm();
    if (isvalid == false) {
      return
    }
    }
    this.loader = true;
    rdt['puser_id'] = _userDT['user_id'];
    this._service._postApi(_url, rdt).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.success_msg = data.msg;
          this.success(this.successrule);
          this.closeMe();
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  account_role: boolean = false;
  isValidForm() {
    if (!this.advertiserlist.advLbl || this.advertiserlist.advLbl.length == 0) {
      this.account_role = true;
      return false;
    } else {
      this.account_role = false;
    }
    if (this.ruleForm['status'] == this.config['INVALID_FORM']) {
      return false;
    }
    return true;
  }

  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }

  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }

  closeError() {
    this.activeModal.close();
  }
}

export interface PeriodicElement {
  account: string;
}
