import { Component, OnInit, ViewChild, Inject, Optional } from "@angular/core";
import { MatPaginator, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Validators, FormBuilder, FormGroup, FormArray } from "@angular/forms";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppUrl } from "../../../common/config/apiurl";
import { SegmentService } from "../../../common/_service/segment.service";
import { ConfigSettings } from "../../../common/config/config";
import * as moment from 'moment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
  templateUrl: "./edit-advertiser.component.html",
  styleUrls: ["./edit-advertiser.component.css"]
})
export class EditAdvertiserComponent implements OnInit {
  @ViewChild("errorrrule", { static: true }) errorrrule: any;
  @ViewChild("successrule", { static: true }) successrule: any;
  @ViewChild("mconfirm", { static: true }) mconfirm: any;
  @ViewChild("addPage", { static: true }) addPage: any;
  @ViewChild("editPage", { static: true }) editPage: any;
  @ViewChild("accountlist", { static: true }) accountlist: any;


  loader: boolean = false;
  accountDT: object = [];
  config = ConfigSettings;
  ruleEditForm: FormGroup;
  addPageForm: FormGroup;
  success_msg: any = "";
  errorMessage: any = "";
  validPageAccess: boolean = false;
  modalWidth: any = "700px";
  ELEMENT_DATA: any = [];
  page_list: any = [];
  accountList: any = [];
  tblDT: any = [];
  rowDT: any = [];
  fileInputLabel: string;
  previousSelFile: any = "";
  isOverlapIndex: boolean = false;
  isCampaignNotes: boolean = false;
  mmplist: any;
  selMonth: any = "";
  filesArr = [];
  currentMonth: any;
  minDate: any;
  maxDate: any;
  overlap_filetype_not_allowed: boolean = false;
  overlap_filesize_limit_exceeded: boolean = false;
  pagesNameList: any = {};
  selectedEditItem: any;
  isAddPopC: boolean = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditAdvertiserComponent>,
    private _service: SegmentService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataAssign(data);
    this.previousSelFile = this.accountDT["advlogo"];
  }
  dataAssign(data) {
    this.accountDT = data ? data : {};
    let arrt = [];
    for (let key in this.accountDT["page"]) {
      arrt[key] = {
        id: this.accountDT["page"][key]["id"],
        n: this.accountDT["page"][key]["n"],
        pid: this.accountDT["page"][key]["id"],
        pn: this.accountDT["page"][key]["n"],
        iframe_url: this.accountDT["page"][key]["iframe_url"],
        advertiser: this.accountDT["page"][key]["advertiser"],
        custom: this.accountDT["page"][key]["custom"] ? this.accountDT["page"][key]["custom"] : 0,
        show: true,
      };
    }
    this.tblDT = arrt;
  }

  closeMe() {
    this.dialogRef.close();
  }

  displayedColumns: string[] = ["page", "iframe_url", "advertiser", "action"];
  dataSource = new MatTableDataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.addPageForm = this.formBuilder.group({
      main_page: [null, [Validators.required, Validators.maxLength(30)]],
      sub_page: [null, [Validators.maxLength(30)]]
    })
    this.mmplist = this.accountDT['overlap_mmp'];
    this.ruleEditForm = this.formBuilder.group({
      id: [this.accountDT["id"]],
      advid: [
        this.accountDT["advid"],
        [Validators.required, Validators.pattern(this.config["ID_PATTERN"])],
      ],
      advname: [
        this.accountDT["advname"],
        [Validators.required, Validators.pattern(this.config["NAME_PATTERN"])],
      ],
      advlogo: [this.accountDT["advlogo"], [Validators.required]],
      overlapIndex: [],
      campaignNotes: [],
      page: this.formBuilder.array([]),
      mmp: [this.accountDT["overlap_index_mmp"]],
      sdate: [this.accountDT["overlap_index_sdate"]],
      edate: [this.accountDT["overlap_index_edate"]],
      file_overlap: []
    });
    this.minDate = moment().subtract(6, 'month').startOf('month').format("YYYY-MM-DD");
    this.maxDate = moment().format("YYYY-MM-DD");

    this.currentMonth = this.accountDT["overlap_index_month"];

    this.isOverlapIndex = this.accountDT["overlapIndex"] == 1 ? true : false;
    this.isCampaignNotes = this.accountDT["campaignNotes"] == 1 ? true : false;
    this.dataSource.paginator = this.paginator;
    this.getAccount();
    this.setUsersForm();
  }

  onListDrop(event: CdkDragDrop<string[]>) {
    // Swap the elements around

    // console.log(`Moving item from ${event.previousIndex} to index ${event.currentIndex}`, this.dataSource.data)

    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    // console.log('event.container', event.container.data)
    this.dataSource.data = event.container.data;
    this.loader = true;
    setTimeout(() => {
      this.loader = false;
    }, 500)

  }

  onResetList($event: any) {
    this.resetFormArray();
    this.dataSource.data = this.accountDT["page"];
    this.setUsersForm();
  }

  closeUserModalBX() {
    this.dialogRef.close();
  }

  filetype_not_allowed: boolean = false;
  onFileChange(event) {
    const file = event.target.files[0];
    this.fileInputLabel = file.name;
    if (!this.validateFile(this.fileInputLabel)) {
      this.filetype_not_allowed = true;
      return false;
    } else {
      this.filetype_not_allowed = false;
    }
    this.ruleEditForm.get("advlogo").setValue(file);
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg"
    ) {
      return true;
    } else {
      return false;
    }
  }

  onChangeOverlapIndex(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      this.ruleEditForm.get("overlapIndex").setValue(1);
    } else {
      this.ruleEditForm.get("overlapIndex").setValue(0);
    }
  }

  onChangeCampaignNotes(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      this.ruleEditForm.get("campaignNotes").setValue(1);
    } else {
      this.ruleEditForm.get("campaignNotes").setValue(0);
    }
  }

  /**
   * @Method : getAccount
   * @description : Get account details
   **/
  getAccount() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/admin/adv/getPagesList";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.accountList = data["d"];
          this.renderAccountList(data["d"]);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
          this.closeMe();
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
   * @Method : renderAccountList
   * @description : Render account list
   **/
  renderAccountList(data: any) {
    let xDt = [];
    let sarr = [];
    let customs = [];
    this.tblDT.filter((elm: any) => {
      sarr.push(String(elm["id"]));
      if (elm['id'] > 37) {
        let cus = {
          "id": elm["id"],
          "n": elm["n"],
          "show": true
        }
        customs.push(cus);
      }
    });
    for (let key in data) {
      if (data[key]["page_id"]) {
        let _ccode = data[key]["page_id"];
        let _show = sarr.indexOf(String(_ccode)) != -1 ? true : false;
        xDt.push({ id: _ccode, n: data[key]["page_name"], show: _show });
      }
    }
    for (let item of customs) {
      xDt.push(item);
    }
    this.getPageListWithCount(xDt);
    this.accountlist.pubDataList(xDt);
  }

  getPageListWithCount(xDt) {
    this.pagesNameList = {};
    for (let item of xDt) {
      if (item.n.match(":") != null) {
        let pageName = item.n.split(":");
        let selectedPageName = pageName[0].trim().toLowerCase();
        if (this.pagesNameList[selectedPageName]) {
          this.pagesNameList[selectedPageName].sub_count += 1;
          this.pagesNameList[selectedPageName].sub_pages.push(pageName[1].trim().toLowerCase());
        } else {
          this.pagesNameList[selectedPageName] = {
            name: selectedPageName,
            main_page: 0,
            sub_count: 1,
            sub_pages: [pageName[1].trim().toLowerCase()]
          }
        }
      } else {
        this.pagesNameList[item.n.toLowerCase()] = {
          name: item.n,
          main_page: 1,
          sub_count: 0,
          sub_pages: []
        }
      }
    }
    //console.log(this.pagesNameList, "pagesNameList")
  }
  setUsersForm1() {
    this.resetFormArray();
    const userCtrl = <FormArray>this.ruleEditForm.controls["page"];
    this.dataSource.data.forEach((user: any) => {
      userCtrl.push(this.setUsersFormArray(user));
    });
    this.dataSource.data = userCtrl.value;
  }

  setUsersForm() {
    this.resetFormArray();
    const userCtrl = <FormArray>this.ruleEditForm.controls["page"];
    this.tblDT.forEach((user: any) => {
      if (user["show"] == true) {
        userCtrl.push(this.setUsersFormArray(user));
      }
    });
    this.dataSource.data = userCtrl.value;
  }

  setUsersFormArray(user: any) {
    return this.formBuilder.group({
      pid: [user["id"]],
      pn: [user["n"]],
      iframe_url: [user["iframe_url"]],
      advertiser: [user["advertiser"]],
      custom: [user["custom"]] ? [user["custom"]] : 0,
    });
  }

  resetFormArray() {
    const userCtrl = <FormArray>this.ruleEditForm.controls["page"];
    while (userCtrl["value"].length != 0) {
      userCtrl.removeAt(0);
    }
  }

  onAccountList(event: any) {
    let oldPageData = {};
    const userCtrl = <FormArray>this.ruleEditForm.controls["page"];

    for (let i = 0; i < userCtrl["value"].length; i++) {
      let pageData = {};
      pageData["iframe_url"] = userCtrl["value"][i]["iframe_url"];
      pageData["advertiser"] = userCtrl["value"][i]["advertiser"];
      pageData["custom"] = userCtrl["value"][i]["custom"];
      oldPageData[userCtrl["value"][i]["pid"]] = pageData;
    }
    this.tblDT = event.filter((elm: any) => {
      if (elm.show == true) {
        elm.iframe_url =
          oldPageData[elm["id"]] && oldPageData[elm["id"]]["iframe_url"]
            ? oldPageData[elm["id"]]["iframe_url"]
            : "";
        elm.advertiser =
          oldPageData[elm["id"]] &&
            oldPageData[elm["id"]]["advertiser"] == false
            ? ""
            : true;
        elm.custom =
          oldPageData[elm["id"]] && oldPageData[elm["id"]]["custom"]
            ? oldPageData[elm["id"]]["custom"]
            : 0;
        return elm;
        return elm;
      }
    });

    this.tblDT = event.filter((elm: any) => {
      if (elm.show == true) {
        return elm;
      }
    });
    this.setUsersForm();
  }

  /**
   * @Method : deleteRow
   * @description : Delete Row roll and change/update formArray
   **/
  deleteRow(elm: any, indx: any) {
    this.rowDT = { elm: elm, indx: indx };
    this.removeUser();
    // this.confirmtionBox();
  }

  removeUser() {
    let tblElm = this.tblDT;
    let elm = this.rowDT["elm"];
    let elmt = tblElm.filter((elm1: any) => {
      if (elm1["id"] != elm["pid"]) {
        return elm1;
      }
    });

    this.tblDT = elmt;
    this.setUsersForm();
    this.renderAccountList(this.accountList);
    // this.success_msg = "Page Deleted Successfully";
    // this.success(this.successrule);
  }

  /**
   * @Method : updateAdvertiserDetails
   * @description : Save user detail/roles/permission
   **/
  updateAdvertiserDetails() {
    let now: any;
    let sdate = this.ruleEditForm.get("sdate").value;
    let sd = "";
    if (sdate) {
      now = new Date(sdate);
      sd = (now.getFullYear() + "-" + (now.getMonth() + 1 < 10 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1) + "-" + (now.getDate() < 10 ? "0" + now.getDate() : now.getDate()));
    }

    let edate = this.ruleEditForm.get("edate").value;
    let ed = "";
    if (edate) {
      now = new Date(edate);
      ed = (now.getFullYear() + "-" + (now.getMonth() + 1 < 10 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1) + "-" + (now.getDate() < 10 ? "0" + now.getDate() : now.getDate()));
    }

    let overlapIndex: any = this.ruleEditForm.get("overlapIndex").value ? 1 : 0;
    let campaignNotes: any = this.ruleEditForm.get("campaignNotes").value ? 1 : 0;

    let _url = AppUrl.getAPIURL();
    _url = _url + "/admin/adv/updateAdvertiserDetails";
    let data = this.ruleEditForm.value;
    const editFormData = new FormData();
    editFormData.append("advid", this.ruleEditForm.get("advid").value);
    editFormData.append("id", this.ruleEditForm.get("id").value);
    editFormData.append("advname", this.ruleEditForm.get("advname").value);
    editFormData.append("advlogo", this.ruleEditForm.get("advlogo").value);
    editFormData.append("overlapIndex", overlapIndex);
    editFormData.append("mmp", this.ruleEditForm.get("mmp").value);
    editFormData.append("month", this.selMonth);
    editFormData.append("sdate", sd);
    editFormData.append("edate", ed);
    if (this.filesArr && this.filesArr.length > 0) {
      for (let filename of this.filesArr) {
        editFormData.append("file_overlap", filename);
      }
    }
    editFormData.append("campaignNotes", campaignNotes);
    editFormData.append("previousSelFile", this.previousSelFile);
    editFormData.append("page", JSON.stringify(this.ruleEditForm.get("page").value));

    //If file format not allowed
    if (overlapIndex && this.overlap_filetype_not_allowed) {
      return;
    }

    //If file too large
    if (overlapIndex && this.overlap_filesize_limit_exceeded) {
      return;
    }

    let isvalid = this.isValidForm(data);
    if (isvalid == false) {
      return;
    }
    this.loader = true;
    this._service._postApi(_url, editFormData).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.success_msg = data.msg;
          //this.renderAccountList(data['d']);
          this.success(this.successrule);
          this.closeUserModalBX();
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  formateFormDT(data: any) {
    let rdt = {
      id: data["id"],
      advid: data["advid"],
      advname: data["advname"],
      overlapIndex: data["overlapIndex"],
      page: [],
    };
    if (data["page"].length != 0) {
      for (let k in data["page"]) {
        if (
          data["page"][k]["pid"] &&
          data["page"][k]["pid"] != "" &&
          data["page"][k]["pid"] != undefined &&
          data["page"][k]["pid"] != null
        ) {
          rdt["page"].push(data["page"][k]);
        }
      }
    }
    return rdt;
  }

  pages_not_selected: boolean = false;
  file_not_selected: boolean = false;
  isValidForm(data: any) {
    this.file_not_selected = false;
    if (!this.ruleEditForm.get("advlogo").value) {
      this.file_not_selected = true;
      return false;
    }
    if (data["page"].length == 0) {
      this.pages_not_selected = true;
      return false;
    } else {
      this.pages_not_selected = false;
    }

    if (this.ruleEditForm["status"] == this.config["INVALID_FORM"]) {
      return false;
    }
    return true;
  }

  getMonthAndYear(month: string) {
    this.selMonth = month;
  }

  onOverlapFileChange(event) {
    this.overlap_filetype_not_allowed = false;
    this.overlap_filesize_limit_exceeded = false;
    this.filesArr = event.target.files;
    let fileSize = 0;
    for (let filename of this.filesArr) {
      let fileLabel = filename.name;
      fileSize += filename.size;
      if (!this.validateOverlapFile(fileLabel)) {
        this.overlap_filetype_not_allowed = true;
        return false;
      }
    }

    if (fileSize > 1048576000) {
      this.overlap_filesize_limit_exceeded = true;
    }
  }

  validateOverlapFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "gz" || ext.toLowerCase() == "zip"
    ) {
      return true;
    } else {
      return false;
    }
  }

  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }
  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }
  confirmtionBox() {
    this.activeModal = this.modalService.open(this.mconfirm, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  addPagePop(ele: any, index: any) {
    this.selectedEditItem = null;
    this.addPageForm.reset();
    this.addPageForm.patchValue({
      main_page: null,
      sub_page: null
    })
    if (ele) {
      this.selectedEditItem = ele;
      if (ele.pn.match(":") != null) {
        let names = ele.pn.split(":");
        this.addPageForm.patchValue({
          main_page: names[0].trim(),
          sub_page: names[1].trim()
        })
      } else {
        this.addPageForm.patchValue({
          main_page: ele.pn
        })
      }
      this.isAddPopC = true;
      this.activeModal = this.modalService.open(this.addPage, {
        windowClass: "success-modal",
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.isAddPopC = true;
      this.activeModal = this.modalService.open(this.addPage, {
        windowClass: "success-modal",
        backdrop: "static",
        keyboard: false,
      });
    }
  }

  addEditPages(type) {
    let { main_page, sub_page } = this.addPageForm.value;
    let data = {
      "adv_id": this.ruleEditForm.get("id").value,
      "iframe_url": "",
      "status": 1,
      "page_name": sub_page ? `${main_page} : ${sub_page}` : main_page,
    }

    let main_pageC;
    let sub_pageC;
    if (this.addPageForm.status === "VALID") {
      main_pageC = main_page.toLowerCase();
      sub_pageC = sub_page && sub_page.toLowerCase();
      if (main_page.trim() == '' || sub_page && sub_page.trim() == '') {
        this.errorMessage = "Please Enter Valid Name"
        this.closeError()
        return this.error(this.errorrrule);
      }
      if (this.pagesNameList[main_pageC] && this.pagesNameList[main_pageC].main_page != 0 && !sub_pageC) {
        this.errorMessage = "Already Name Exist"
        this.closeError()
        return this.error(this.errorrrule);
      } else if (this.pagesNameList[main_pageC] && sub_pageC) {
        let findDuplicate = this.pagesNameList[main_pageC].sub_pages.filter((item) => item === sub_pageC);
        if (findDuplicate.length > 0) {
          this.closeError()
          this.errorMessage = "Sub Page Name Already Exist"
          return this.error(this.errorrrule);
        }
      } else if (this.pagesNameList[main_pageC] && sub_page && this.pagesNameList[main_pageC].sub_count >= 5) {
        this.closeError()
        this.errorMessage = "Can't Add More Sub Pages"
        return this.error(this.errorrrule);
      }
    } else {
      this.addPageForm.reset();
      this.closeError()
      this.errorMessage = "Please Enter Main Page"
      return this.error(this.errorrrule);
    }

    if (type == 'Edit') {
      data['page_id'] = this.selectedEditItem.pid;
      data.iframe_url = this.selectedEditItem.iframe_url;
      data.page_name = sub_page ? `${main_page} : ${sub_page}` : main_page;
      this.addAdvertiserPage(data, type)
    } else {
      data['custom'] = 1;
      this.addAdvertiserPage(data, type)
    }
  }

  addAdvertiserPage(data, type) {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    if (type == 'Edit') {
      _url = _url + "/admin/adv/updateCustomPages";
    } else {
      _url = _url + "/admin/adv/addCustomPages";
    }

    this._service._postApi(_url, data).subscribe(
      (data: any) => {
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.addPageForm.reset();
          this.isAddPopC = false;
          this.closeError()
          this.updateAdvertiser(this.ruleEditForm.get("id").value, type)
        } else {
          this.loader = false;
          this.closeError()
          this.errorMessage = data.msg;
          return this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }


  updateAdvertiser(elm: any, type) {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/admin/adv/getEditAdvertiserlist/' + elm;
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.dataAssign(data['d']);
          this.getAccount();
          this.setUsersForm();
        } else {
          this.closeError()
          this.errorMessage = data.msg;
          this.error(this.errorrrule);

        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  closeError() {
    this.activeModal.close();
  }
}
export interface PeriodicElement {
  page: string;
}