import { Component, OnInit } from "@angular/core";
import {
  UtilService,
  SegmentService,
  AnalyticsChartService,
} from "../../common/_service/index";
import { Router, NavigationEnd } from "@angular/router";
import { ConfigSettings } from "../../common/config/config";
import { AppUrl } from "../../common/config/apiurl";
@Component({
  templateUrl: "./campanalytics.component.html",
  styleUrls: ["./campanalytics.component.css"],
})
export class CampanalyticsComponent implements OnInit {
  _config: any = ConfigSettings;
  loader: boolean = false;
  campNotesData: any = [];
  campHeadings: any = [];
  constructor(
    private router: Router,
    private _service: SegmentService,
    private _router: Router,
    private _utilservice: UtilService
  ) {
    console.log("in camp analytics");
    _service.validateUser();
  }
  ngOnInit() {
    this.getAdvCampAnalyticsData();
  }

  getAdvCampAnalyticsData() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/admin/campnotes/getAdvCampAnalyticsData";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this._config["STATUS_CODE"]["OK"]) {
          this.campNotesData = data["d"] ? data["d"]["campAnalyticsData"] : [];
          this.campHeadings = data["d"] ? data["d"]["headingList"] : [];
        } else {
          if (data.s == 555) {
            this.router.navigate(["/login"]);
          } else {
            // this.errorMessage = data.msg;
            // this.error(this.errorrrule);
          }
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  renderReport() {}

  getData() {}
}
