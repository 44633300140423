import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import {
  MatPaginator,
  MatTableDataSource,
  MatSort,
  MatDialog,
} from "@angular/material";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppUrl } from "../../common/config/apiurl";
import { SegmentService } from "../../common/_service/segment.service";
import { ConfigSettings } from "../../common/config/config";
import { EditCampNotesComponent } from "./edit-campnotes/edit-campnotes.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  templateUrl: "./campnotes.component.html",
  styleUrls: ["./campnotes.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CampNotesComponent implements OnInit {
  @ViewChild("successrule", { static: true }) successrule: any;
  @ViewChild("errorrrule", { static: true }) errorrrule: any;
  @ViewChild("mconfirm", { static: true }) mconfirm: any;

  /**
   * @Method : constructor
   * @description : constructor function
   **/
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private _service: SegmentService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {}
  loader: boolean = false;
  config: any = ConfigSettings;
  deleteAccountMsg: any = "";
  ELEMENT_DATA: any = [];
  success_msg: any = "";
  errorMessage: any = "";
  addAppSettings() {
    this.router.navigate(["/admin/addcampnotes/"]);
  }

  updateCampaignNotes(elm: any) {
    this.router.navigate(["/admin/editcampnotes/"], {
      queryParams: { id: elm.id },
    });
  }

  // Material Table
  displayedColumns: string[] = ["date_range", "name", "action"];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getAdvCampData();
  }

  /**
   * @Method : applyFilter
   * @description : filter Advertiser Campaign Data
   **/
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * @Method : getAdvCampData
   * @description : get Advertiser Campaign Data
   **/
  getAdvCampData() {
    this.loader = true;
    let adv_id = this._service.getSelectedAdvId();
    var _url = AppUrl.getAPIURL();
    _url = _url + "/admin/campnotes/getAdvCampData";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.dataSource.data = data["d"] ? data["d"] : [];
        } else {
          if (data.s == 555) {
            this.router.navigate(["/login"]);
          } else {
            this.errorMessage = data.msg;
            this.error(this.errorrrule);
          }
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
   * @Method : success
   * @description : success modal box
   **/
  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  /**
   * @Method : error
   * @description : error modal box
   **/
  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  /**
   * @Method : confirmtionBox
   * @description : confirmtion modal box
   **/
  confirmtionBox() {
    this.activeModal = this.modalService.open(this.mconfirm, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }
}
export interface PeriodicElement {
  date_range: string;
  name: string;
}
