import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { MatDialog } from "@angular/material";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReportSchedulerComponent } from "../../analytics/report-scheduler/report-scheduler.component";
import { SegmentService } from "../_service/segment.service";
import { ConfigSettings } from "../config/config";
import { AppUrl } from "../config/apiurl";

@Component({
  selector: "analytics-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @Input() sendSidebar: any = [];
  _config: any = ConfigSettings;
  public userdata: any = {};
  public sidebarLinks: any;
  public pageLink: any;
  public showAdminLink: any = false;
  public defAnalyticsLink: any;
  public activeUrlTab: any;
  public completetiveAnalysis: any;
  sendNavData: any = [];
  aacountList: any = [];
  acct_id: any = "";
  advList: any = [];
  selAdvList: any = [];
  loader: boolean = false;
  errorMessage: any = "";
  @ViewChild("errorrrule", { static: true }) errorrrule: any;
  @ViewChild("errorrPermission", { static: true }) errorrPermission: any;

  // Dialog/Modals
  addReportScheduler() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    let user_id = this._service.getUserid();
    _url = _url + "/admin/adv/getAdvReportScheduler/" + user_id;
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data.s == this._config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.openReportSchBoxBox(data["d"]);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  openReportSchBoxBox(d: any) {
    let dialogRef = this.dialog.open(ReportSchedulerComponent, {
      width: "700px",
      disableClose: true,
      data: d,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.getAdvertiserList();
    });
  }

  constructor(
    private _route: Router,
    public dialog: MatDialog,
    private _service: SegmentService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    this._route.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        /* Your code goes here on every router change */
        this.setTopHeaderClass();
      }
    });
  }

  setTopHeaderClass() {
    let url = this._route.url.substring(0, this._route.url.length).split("/");
    this.activeUrlTab = url[1];
  }

  ngOnInit() {
    var userDetail = this._service._getUserDt();
    if (userDetail) {
      this.userdata = userDetail;
      if (this.userdata["role_id"] == 1 || this.userdata["role_id"] == 4) {
        this.showAdminLink = true;
        this.pageLink = "/admin/advertisers";
      } else if (this.userdata["role_id"] == 2) {
        this.showAdminLink = true;
        this.pageLink = "/admin/users";
      }
    }

    if (this.aacountList.length == 0) {
      this.getActiveAdvertisersList();
    }
    // else {
    //   window.location.href = AppUrl.FAAS_URL;
    // }
  }

  onAccountChange(adv_id: any) {
    this.loader = true;
    let userDt = this._service._getUserDt();
    this._service.setParamInLocalStorage("activeAdvId", adv_id);
    let _url = AppUrl.getAPIURL();
    _url =
      _url + "/admin/updateDefaultAccInfo/" + userDt["user_id"] + "/" + adv_id;
    this._service._postApi(_url, []).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s && data.s == this._config["STATUS_CODE"]["OK"]) {
          let page_url = data.d[0] ? data.d[0]["page_url"] : "";
          if (page_url != "") {
            this._service.setCurrentPageIdS(data['d'][0]['page_id']);
            this.defAnalyticsLink = page_url;
            this._route.navigate([page_url]);
            this.changeLocation(page_url);
            // setTimeout(() => {
            //   this._route.navigate([page_url]);
            //   this.changeLocation(page_url);
            // }, 200)
          }
        } else if (data.s && data.s == this._config["STATUS_CODE"]["DENIED"]) {
          this.errorMessage = data.msg;
          this.acct_id = this.aacountList["defAdvData"]["defAdvId"];
          this.error(this.errorrPermission);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  changeLocation(locationData) {
    // save current route first
    const currentRoute = this._route.url;
    this._route.navigateByUrl("/", { skipLocationChange: true }).then((e) => {
      if (e) {
        // console.log('locationData ', locationData);
        this._route.navigate([locationData]); // navigate to same route
      } else {
        // console.log('currentRoute ', currentRoute);
        this._route.navigate([currentRoute]); // navigate to same route
      }

      //   () => {
      //   console.log('currentRoute ', currentRoute);
      //   this._route.navigate([currentRoute]); // navigate to same route
    });
  }
  getActiveAdvertisersList() {
    var _url = AppUrl.getAPIURL();
    let user_id = this._service.getUserid();
    _url = _url + "/admin/getActiveAdvList/" + user_id;
    this._service.getDefaultApi(_url).subscribe(
      (data: any) => {
        if (data.s == 200) {
          this.aacountList = data["d"];
          let sortedAdvList = this.sortAdvertisers(data["d"]["activeAdvList"]);
          this.advList = sortedAdvList;
          this.selAdvList = sortedAdvList;
          this.defAnalyticsLink = this.aacountList["analyticsDefNavPage"];
          this.acct_id =
            this.aacountList["defAdvData"] &&
            this.aacountList["defAdvData"]["defAdvId"]
              ? parseInt(this.aacountList["defAdvData"]["defAdvId"])
              : "";
          this._service.setParamInLocalStorage("activeAdvId", this.acct_id);
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  sortAdvertisers(advertisersArr: any) {
    if (advertisersArr && advertisersArr.length > 0) {
      const sortedAdvertisers = advertisersArr.sort(function (a, b) {
        var nameA = a.n.toUpperCase(); // ignore upper and lowercase
        var nameB = b.n.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1; //nameA comes first
        }
        if (nameA > nameB) {
          return 1; // nameB comes first
        }
        return 0; // names must be equal
      });
      return sortedAdvertisers;
    } else {
      return advertisersArr;
    }
  }

  renderSidebar() {
    var _url = AppUrl.getAPIURL();
    let user_id = this._service.getUserid();
    _url = _url + "/admin/getLeftNavigationsByUserId/" + user_id;
    this._service.getDefaultApi(_url).subscribe(
      (data: any) => {
        if (data.s == 200) {
          this.sidebarLinks = data.d[0];
          this.completetiveAnalysis = this.sidebarLinks["maasex"];
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  updateDefAnalyticsPage(defAnalyticsLinknav: any) {
    let analyticsDefPageId = this.aacountList["analyticsDefPageId"];
    this._service.setCurrentPageIdS(analyticsDefPageId);
    this._route.navigate([defAnalyticsLinknav]);
  }

  onClick(event: Event): void {
    let page_id = event["page_id"]
      ? event["page_id"]
      : this._service.getCurrentPageId();
    this._service.setCurrentPageIdS(page_id);
    this._route.navigate([event["page_url"]]);
  }
  // User Log OUT
  userLogOut() {
    this._service.userLogOut();
  }

  getTopRouterClass() {
    return "active";
  }

  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  onKey(value) {
    this.selAdvList = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.advList.filter((i) => i.n.toLowerCase().indexOf(filter) >= 0);
  }
}
