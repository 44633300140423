import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatPaginator,
  MatTableDataSource,
  MatDialog,
  MatDialogRef
} from "@angular/material";
import { Validators, FormControl, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUrl } from '../../../common/config/apiurl';
import { SegmentService } from '../../../common/_service/segment.service';
import { ConfigSettings } from '../../../common/config/config';

@Component({
  selector: "app-add-advertiser",
  templateUrl: "./add-advertiser.component.html",
  styleUrls: ["./add-advertiser.component.css"],
})
export class AddAdvertiserComponent implements OnInit {
  config: any = ConfigSettings;
  @ViewChild('errorrrule', { static: true }) errorrrule: any;
  @ViewChild('successrule', { static: true }) successrule: any;
  @ViewChild('mconfirm', { static: true }) mconfirm: any;
  @ViewChild('accountlist', { static: true }) accountlist: any;
  loader: boolean = false;
  ruleForm: FormGroup;
  success_msg: any = '';
  errorMessage: any = '';
  modalWidth: any = '700px';
  ELEMENT_DATA: any = [];
  page_list: any = [];
  accountList: any = [];
  tblDT: any = [];
  fileInputLabel: string;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddAdvertiserComponent>,
    public _service: SegmentService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public formBuilder: FormBuilder
  ) { }
  displayedColumns: string[] = ["page", "iframe_url", "advertiser", "action"];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit() {
    this.ruleForm = this.formBuilder.group({
      "advid": ['', [Validators.required, Validators.pattern(this.config['ID_PATTERN'])]],
      "advname": ['', [Validators.required, Validators.pattern(this.config['NAME_PATTERN'])]],
      "advlogo": ['', [Validators.required]],
      "page": this.formBuilder.array([])
    });
    this.dataSource.paginator = this.paginator;
    this.getAccount();
  }

  closeMe() {
    this.dialogRef.close();
  }


  filetype_not_allowed: boolean = false;
  onFileChange(event) {
    const file = event.target.files[0];
    this.fileInputLabel = file.name;
    if (!this.validateFile(this.fileInputLabel)) {
      this.filetype_not_allowed = true;
      return false;
    }
    else {
      this.filetype_not_allowed = false;
    }
    this.ruleForm.get('advlogo').setValue(file);
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg') {
      return true;
    }
    else {
      return false;
    }
  }
  /**
 * @Method : getAccount
 * @description : Get account details
 **/
  getAccount() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/admin/adv/getPagesList';
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.loader = false;
          this.accountList = data['d'];
          this.renderAccountList(data['d']);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
          this.closeMe();
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
   * @Method : renderAccountList
   * @description : Render account list
   **/
  renderAccountList(data: any) {
    let xDt = [];
    let sarr = [];
    this.tblDT.filter((elm: any) => {
      sarr.push(String(elm['id']));
    });
    for (let key in data) {
      if (data[key]['page_id']) {
        let _ccode = data[key]['page_id'];
        let _show = sarr.indexOf(String(_ccode)) != -1 ? true : false;
        xDt.push({ id: _ccode, n: data[key]['page_name'], show: _show });
      }
    }
    this.accountlist.pubDataList(xDt);
  }

  setUsersForm() {
    const userCtrl = <FormArray>this.ruleForm.controls['page'];
    this.setTblIframe(userCtrl['value']);
    this.resetFormArray();
    this.tblDT.forEach((user: any, i: any) => {
      if (user['show'] == true) {
        userCtrl.push(this.setUsersFormArray(user));
      }
    });
    this.dataSource.data = userCtrl.value;
  };
  setTblIframe = (tblval: any) => {
    let ss = {};
    for (let i = 0; i < tblval.length; i++) {
      ss[tblval[i]['pid']] = tblval[i];
    }

    this.tblDT.forEach((user: any, i: any) => {
      if (ss[user['id']] && ss[user['id']] != 'undefined') {
        this.tblDT[i]['iframe_url'] = ss[user['id']]['iframe_url'];
      }
    });
  }

  setUsersFormArray(user: any) {
    return this.formBuilder.group({
      pid: [user['id']],
      pn: [user['n']],
      iframe_url: [user['iframe_url']],
      advertiser: [true],
    });
  };

  resetFormArray() {
    const userCtrl = <FormArray>this.ruleForm.controls['page'];
    while (userCtrl['value'].length != 0) {
      userCtrl.removeAt(0);
    }
  }

  /**
    * @Method : onAccountList
    * @description : onAccountList change update list in table/modalbox
    **/
  onAccountList(event: any) {
    this.tblDT = event.filter((elm: any) => {
      if (elm.show == true) {
        return elm;
      }
    });
    this.setUsersForm();
  }

  /**
  * @Method : deleteRow
  * @description : Delete Row roll and change/update formArray
  **/
  deleteRow(elm: any, indx: any) {
    let tblElm = this.tblDT;
    let elmt = tblElm.filter((elm1: any, i: number) => {
      if (i != indx) {
        return elm1;
      }
    });
    this.tblDT = elmt;
    this.setUsersForm();
    this.renderAccountList(this.accountList);

  }

  /**
    * @Method : addUserAccount
    * @description : Save user detail/roles/permission
    **/
  addAdvertiser() {
    let user_id = this._service.getUserid();
    let _url = AppUrl.getAPIURL();
    _url = _url + '/admin/adv/createNewAdvertiser';
    let data = this.ruleForm.value;
    const formData = new FormData();
    formData.append('advid', this.ruleForm.get('advid').value);
    formData.append('advname', this.ruleForm.get('advname').value);
    formData.append('advlogo', this.ruleForm.get('advlogo').value);
    formData.append('user_id', user_id);
    formData.append('page', JSON.stringify(this.ruleForm.get('page').value));
    let isvalid = this.isValidForm(data);
    if (isvalid == false) {
      return
    }
    this.loader = true;
    this._service._postApi(_url, formData).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.success_msg = data.msg;
          this.success(this.successrule);
          this.closeMe();
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  formateFormDT(data: any) {
    let rdt = { advid: data['advid'], advname: data['advname'], page: [] };
    if (data['page'].length != 0) {
      for (let k in data['page']) {
        if (data['page'][k]['pid'] && data['page'][k]['pid'] != '' && data['page'][k]['pid'] != undefined && data['page'][k]['pid'] != null) {
          rdt['page'].push(data['page'][k]);
        }
      }
    }
    return rdt;
  }

  pages_not_selected: boolean = false;
  file_not_selected: boolean = false;
  isValidForm(data: any) {
    this.file_not_selected = false;
    if (!this.ruleForm.get('advlogo').value) {
      this.file_not_selected = true;
      return false;
    }
    if (data['page'].length == 0) {
      this.pages_not_selected = true;
      return false;
    } else {
      this.pages_not_selected = false;
    }

    if (this.ruleForm['status'] == this.config['INVALID_FORM']) {
      return false;
    }
    return true;
  }


  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }
  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }
  confirmtionBox() {
    this.activeModal = this.modalService.open(this.mconfirm, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }

  closeError() {
    this.activeModal.close();
  }
}
export interface PeriodicElement {
  page: string;
}
