import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-admin-mgmt',
  templateUrl: './admin-mgmt.component.html',
  styleUrls: ['./admin-mgmt.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdminMgmtComponent implements OnInit {
  sendNavData: any = [];

  constructor() { }

  ngOnInit() {}


  sendSidebarNav($event: any) {
    this.sendNavData = $event;
  }

}
