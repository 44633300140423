import { Component, OnInit, ViewEncapsulation, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SegmentService } from "../../common/_service/segment.service";
import { UtilService } from "../../common/_service/util.service";
import { ConfigSettings } from "../../common/config/config";
import { AppUrl } from "../../common/config/apiurl";
@Component({
  templateUrl: "./staticcontent.component.html",
  styleUrls: ["./staticcontent.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class StaticContentComponent implements OnInit {
  @Input() event: Event;
  loader: boolean = false;
  _config: any = ConfigSettings;
  public iFrameUrl: any;
  public showNoDataDiv: any = false;
  public currentUrl: any = "";
  constructor(
    private _service: SegmentService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private _utilservice: UtilService
  ) {
    _service.validateUser();
  }

  ngOnInit() {}

  getActiveSection() {
    let currentReport = "";
    this.currentUrl = this._router.url;
    let urlArr = this.currentUrl.split("/");
    if (urlArr[2] != "" && urlArr[2] == "sart") {
      currentReport = "art";
    } else if (urlArr[2] != "" && urlArr[2] == "soverlap") {
      currentReport = "overlapIndex";
    }
    return currentReport;
  }
  /**
   * @Method : getQueryParam
   * @description : get parameter from url
   **/
  renderReport(data: any) {
    this.loader = true;
    let section = this.getActiveSection();
    if (section == "") {
      this.showNoDataDiv = true;
    } else {
      this.showNoDataDiv = false;
      var _url = AppUrl.getAPIURL();
      let user_id = this._service.getUserid();
      _url = _url + "/analytics/getStaticIframeUrl/" + user_id;
      this._service.getDefaultApi(_url).subscribe(
        (data: any) => {
          if (data.s == 200) {
            console.log(data["d"]);
            let iFrameUrl =
              data["d"] && data["d"][section] != "" ? data["d"][section] : "";
            if (iFrameUrl == "") {
              this.loader = false;
              this.showNoDataDiv = true;
            } else {
              let ifrm = this._utilservice.prepareIframe(iFrameUrl);
              document.getElementById("dashboard").appendChild(ifrm);
              window.setTimeout(() => {
                var iframe: any = document.getElementById("frameStyle");
                var iframeDoc =
                  iframe.contentDocument || iframe.contentWindow.document;
                if (iframeDoc.readyState == "complete") {
                  iframe.onload = () => {
                    this.loader = false;
                  };
                  return;
                }
              }, 100);
            }
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }
}
