import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "./core.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LoaderComponent } from "./loader/loader.component";
import { NodataComponent } from "./nodatadisplay/nodatadisplay.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { MonthpickerComponent } from "./monthpicker/index";


@NgModule({
  imports: [CommonModule, CoreModule, MatProgressSpinnerModule],
  declarations: [
    LoaderComponent,
    NodataComponent,
    FooterComponent,
    HeaderComponent,
    MonthpickerComponent
  ],
  exports: [
    MatProgressSpinnerModule,
    LoaderComponent,
    NodataComponent,
    FooterComponent,
    HeaderComponent,
    CoreModule,
    MonthpickerComponent
  ]
})

export class sharedModule { }