/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

export class ConfigSettings {
  //public static env = "dev";
  //public static env = "uat";
  public static env = "live";
  //public static browserWindow = window || {};

  //public static secretid =window['_env']['secretid'];//"VtpNvnUje3y2F2vb4MSPsS8E";
  //sidebar, header, footer and filter

  public static SIDEBAR_ARRAY = {
    login: { sidebar: false, filter: false, footer: false, header: false },
    forgot: { sidebar: false, filter: false, footer: false, header: false },
    register: { sidebar: false, filter: false, footer: false, header: false },
    error: { sidebar: false, filter: false, footer: false, header: false },
    dashboard: { sidebar: false, filter: true, footer: true, header: true },
    segments: { sidebar: true, filter: true, footer: true, header: true },
    csegments: { sidebar: true, filter: false, footer: true, header: true },
    category: { sidebar: true, filter: false, footer: true, header: true },
    acategory: { sidebar: true, filter: false, footer: true, header: true },
    user: { sidebar: true, filter: true, footer: true, header: true },
    pass: { sidebar: true, filter: true, footer: true, header: true },
    ulist: { sidebar: true, filter: true, footer: true, header: true },
    edituser: { sidebar: true, filter: true, footer: true, header: true },
  };
  public static STATIC_CONTENT_ID = 171;
  public static STATIC_CONTENT_ID_UB = 177;
  public static STATIC_CONTENT_ID_SWIGGY = 77;
  public static STATIC_CONTENT_ID_ARR = [171, 177,77];
  public static STATIC_CONTENT_ID_MONDELEZ = 182;
  public static STATIC_CONTENT_ID_DEMO = 160;
  public static _token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9";
  public static GOOGLE_CAPTCHA_SITE_KEY =
    "6Lc-IvYUAAAAAAiEEato-rG_DqUL1-q8_56ig5yw";
  public static GOOGLE_PROVIDER_ID =
    "1040062867164-b4t8n9h1v5mc351m14oe9evjnlr0gvde.apps.googleusercontent.com";
  public static GAPI = "https://apis.google.com/js/api:client.js";

  public static ADDUSER_VALID = "All fields are required";
  public static DEFAULT_TIME = "alltime";
  public static CREATE_RULE = "Please create segment rule";
  public static SEGMENT_NAME = "Please enter segment name";
  public static PROCESSED_MSG =
    " Note: It may take up to 30 minutes to compile large segments. You will receive an email once the Custom Segment is ready.";

  public static LOOK_PROCESSED_MSG =
    " Note: It may take up to 30 minutes to compile large segments. You will be intimated through email once the lookalike segment has been created";
  public static NO_UC = 0;
  public static A_DOWNLOAD = 1;
  public static SYNC_REVX = 2;
  public static SYNC_VIZURY = 3;
  public static REVIEW_AUDIENCE = 4;
  public static CREATE_AUDIENCE = 5;
  public static EDIT_SEGMENT = 6;
  public static SEGMENT_STATUS_COMPLETE = 2;
  public static SEGMENT_STATUS_FAIL = 3;

  public static PRE_SEGMENT_TYPE = "1";
  public static CUSTOM_SEGMENT_TYPE = "2";
  public static LOOKALIKE_SEGMENT_TYPE = "6";

  public static category_id = 1;
  public static user_admin = 1;
  public static user_manager = 2;
  public static user_readonly = 3;
  public static STATUS_CODE = {
    OK: 200,
    DENIED: 403,
  };

  //Local
  //public static GOOGLE_PROVIDER_ID = "590339128384-a3pemb5ei8m0v3f6r5tuqokea1cnl1hc.apps.googleusercontent.com";
  //Live

  //public static GOOGLE_PROVIDER_ID = "337589215797-q8fpu9biibcqiokm6ect5l421ssm842h.apps.googleusercontent.com";

  public static user_id = "12121212";
  public static user_mail = "dmp@affle.com";
  public static data_source = {
    affle: "Affle",
    vizuri: "vizuri",
    revx: "revx",
  };
  public static device_type = { android: "1", ios: "2", others: "4" };
  public static created = "created";
  public static updated = "updated";
  public static custom = "Custom";
  public static lookalike = "Lookalike";
  public static inprogress = "In Progress";
  public static selectOption = "Select a option";
  public static syncStart = "Sync segment start process!";
  public static pending = "Pending";
  public static INTERNAL_DOMAIN = ["affle.com", "vizury.com", "shoffr.io"];
  public static domain_error = "Affle internal domain not allow";
  public static wrong_credential = "Invalid email and password";
  public static operatorlbl = { eq: "Include", neq: "Exclude" };

  public static DEFAULT_COUNTRY = { key: "356", v: "India", charcode: "in" };
  public static refreshSegment =
    "Segment is being refreshed. You will receive an email once the segment refresh is completed. Thanks.";
  public static editSegmentname = "Segment name updated";
  public static issegment_delete = "Do you want to delete this Custom Segment?";
  public static issegment_syc =
    " Do you want to sync the selected Custom Segment?";
  public static segment_delete = "Segment deleted successfully.";
  public static sync_success =
    "You will receive an email shortly with sync details.";
  public static skey_value = {
    category_id: "Category",
    country_code: "Country",
    isp: "ISP",
    region_code: "Region Code",
    event_name: "Event Name",
    os_version: "OS Version",
    device_brand: "Device Make",
    device_model: "Device Model",
    os_type: "OS Type",
  };

  public static VALID_PASS = "Wrong Password?";
  public static COUNTRY_MANDATORY = "Country field is mandatory";
  public static Vx = "Please check your mailbox for the reset password link";
  public static isLeave_Page = "Are you want to leave this page?";
  public static isDifferentpas =
    "New password should be different from old password";
  public static isconfirm_pass = "Password and confirm password do not match.";
  public static min_max_length =
    "Password length should be greater than or equal 10 and less than 15";
  public static regx_valid_msg =
    "Password length should be 10 to 15 characters, including one numeric, one special character, one upper case and one lower case.";
  public static rpass_successmsg = "Password set successfull";
  public static password_notset = "Password do not set.Try again";
  public static pass_field_mandatory = "Password fields can't be blank";
  public static passw_rule =
    "Note* - Password length should be min. 10 and max 15 including alphanumeric and special char.";
  public static regx_pattern =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/;
  public static EMAIL_PATTERN =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  public static NAME_PATTERN = "^[0-9a-zA-Z - _-]+";
  public static USER_NAME_PATTERN = "^(?=.*[a-zA-Z])[0-9a-zA-Z - _-]+";
  public static ID_PATTERN = "^[A-Za-z0-9?_-]+$";
  public static ADV_ID_PATTERN = "^[0-9]+";
  public static DEFAULT_DEMO_ADV_ID = "160";
  public static SUPER_ADMIN_ADV_MSG =
    "SuperAdmin can access all advertisers. Default advertiser will be demo.";
  public static UPLOAD_IMG_PATH = "/uploads/";

  public static OK_TEXT = "OK";
  public static CANCEL_TEXT = "Cancel";
  public static SAVE_TXT = "Save";
  public static UPDATE_TXT = "Update";

  public static YES_TEXT = "Yes";
  public static REVIEW_TEXT = "Review";
  public static OR_TEXT = "OR";
  public static AND_TEXT = "AND";
  public static PRODUCTAFFINITY_TEXT = "Product Affinity";
  public static INTENTAFFINITY_TEXT = "Intent Affinity";
  public static APPUSES_TEXT = "App Usage";

  public static FILE_SIZE_LIMIT = "Maximum upload file size limit is 5 MB";
  public static SOMETHING_WRONG = "Something went wrong!";
  public static EMAIL_REQUIRED_MSG = "Please enter a valid email";
  public static MAX_FILE_SIZE = 5242880;
  public static FILE_UPLOAD_MSG = "Please upload csv file";
  public static FILE_UPLOAD_MAX_SIZE =
    " File too large, maximum size allowed is 5 MB";

  public static INVALID_FORM = "INVALID";
  public static VALID_FORM = "VALID";
  public static ACCOUNT_TBL_HDR = [
    "name",
    "email",
    "country_count",
    "user_count",
    "status",
    "action",
  ];
  public static ACTNAME_FIELD_REQUIRED = "Enter account name";
  public static UNAME_FIELD_REQUIRED = "Enter valid name";
  public static EMAIL_FIELD_REQUIRED = "Enter valid email";
  public static PAID_TYPE = "Select paid type ";
  public static BILLING_ADDRESS = "Enter billing Address";
  public static COUNTRY_LIST = "Select country from list";
  public static PAGE_LIST = "Select page from list";
  public static DLEETE_USER = "Do you want to deactivate user?";
  public static ACTIVATE_USER = "Do you want to activate user?";
  public static DELETE_PAGE = "Do you want to delete page?";

  public static DEACTIVATE_ADVERTISER = "Do you want to deactivate advertiser?";
  public static ACTIVATE_ADVERTISER = "Do you want to activate advertiser?";

  public static ACCOUNT_ROLE_MSG = "Please select atleast one account";
  public static ROLE_MSG = "Please select role";
  public static RS_EMAIL_MSG = "Please enter atleast one emailid";
  public static RS_PAGES_MSG = "Please select atleast one page";
  public static RS_WEEKDAY_MSG = "Please select atleast one day";
  public static DATE_MSG = "Please select date";
  public static DURATION_MSG = "Please select repeat frequency";
  public static ADVERTISER_ROLE_MSG = "Please select atleast one advertiser";
  public static LOGIN = "login";
  public static ASSIGN_REMOVE = "assign_remove";
  public static DEL_ACC = "del_acc";
  public static CREATE_ACC = "create_acc";
  public static CREATE_ACC_ADMIN = "create_acc_admin";
  public static ACC_SETUP_EMAIL = "acc_setup_email";
  public static ACC_COUNTRY_ENABLE = "acc_country_enable";
  public static ENBL_PRE_DEF_SEGMENT = "enbl_pre_def_segmt";
  public static ACC_PAGE_LINK = "acc_page_link";
  public static CREATE_USER = "create_user";
  public static CHANGE_USER_ROLE = "change_user_role";
  public static RESET_PASS = "reset_pass";

  public static CREATE_CUSTOM_SEGMT = "create_custm_sgmt";
  public static VIEW_SYNC_SEGMT = "view_sync_sgmt";

  public static DEL_CST_SEGMT = "del_cst_sgmt";
  public static UPDATE_CST_SGMT = "update_cst_sgmt";
  public static LOOKLIKE_CST_SGMT = "create_looklike";

  public static REFRES_CST_SGMT = "refres_cst_sgmt";
  public static CLONE_CST_SGMT = "clone_cst_sgmt";
  public static SYNC_CST_SGMT = "sync_cst_sgmt";
  public static SYNC_PRECST_SGMT = "sync_precst_sgmt";

  public static SHARED_LINK = "shared_link";
  public static VIEW_CST_SGMT = "view_cstm_sgmt";
  public static VIEW_PRE_DEF_SGMT = "view_pre_def_sgmt";
  public static VIEW_DASHBOARD = "view_dashboard";
  public static COUNTRY_LIST_P = "country_list";
  public static PAGEACCESS_LIST = "pageaccess_list";
  public static SET_PASS = "set_pass";
  public static USER_CHANGE_OWNROLE = "own_role";
  public static AFFLE_EMAIL_ONLY = "affle.com";
  public static AFFLE_EMAIL_ONLY_CAPS = "AFFLE.COM";
  public static AFFLE_EMAIL_MSG = "Please enter Affle email only";

  public static permissionKey = {
    add_adv: "1",
    edit_adv: "2",
    act_deact_adv: "3",
  };
  public static KPI_BUNDLE_RULE = "Please Select at least one rule";
  public static GEO_SELECT_LIST = "Geo List";
  public static OS_SELECT_LIST = "Os List";
  public static SELECT_CLONE = "Please fill os, Playstore/Appstore URL and geo";
  public static OS_LIST = [
    { id: "android", name: "Android" },
    { id: "ios", name: "iOS" },
  ];

  public static MMP_LIST = [
    { id: "1", name: "Appsflyer" }
  ];

  public static REPEAT_FREQ = [
    { id: "daily", name: "Daily" },
    { id: "weekly", name: "Weekly" },
    { id: "monthly", name: "Monthly" },
    { id: "everyday", name: "Every weekday (Monday to Friday)" },
  ];
  public static CAMPAIGN_COLORS_LIST = [
    { id: "green", name: "Green" },
    { id: "blue", name: "Blue" },
    { id: "orange", name: "Orange" },
    { id: "red", name: "Red" },
    { id: "pink", name: "Pink" },
    { id: "sky", name: "Sky" },
  ];
  public static APPSFLYER = 1;
  public static ADJUST = 2;
  public static BRANCH = 4;
  public static ADJUST_EVENT_LIMIT = 15;
  public static BRANCH_EVENT_LIMIT = 7;

  public static DUPLICATE_EVENT =
    "Duplicate events configuration is not allowed.";
  public static DUPLICATE_EVENT_NAME =
    "Duplicate event name configuration is not allowed.";
  public static EMPTY_EVENT_ERROR = "Please select an event.";
  public static EMPTY_EVENT_NAME_ERROR = "Event name can not be blank.";
  public static EMPTY_CAMPAIGN_ID_ERROR = "Campaign id can not be blank.";
  public static DUPLICATE_CAMPAIGN_ID_ERROR = "Duplicate campaign id error.";
  public static ONLY_NUMERIC_ERROR = "Invalid campaign id";
  public static CHANNELS_PAGES_MSG =
    "Please select atleast one page for channels";
  public static PAGES_MSG = "Please select atleast one page for MAAS Exclusive";
  public static PARENT_ANALYTICS_ICONS = {
    "Competition Overview": {
      icon: "sports_score",
      class: "material-symbols-outlined",
    },
    mDSP: { icon: "storage", class: "material-icons" },
    "Social ": { icon: "group", class: "material-icons" },
    menu1: { icon: "dashboard", class: "material-icons" },
    menu2: { icon: "dashboard", class: "material-icons" },
  };
  public static ANALYTICS_ICONS = {
    1: { icon: "dashboard", class: "material-icons" },
    2: { icon: "summarize", class: "material-icons" },
    3: { icon: "dashboard", class: "material-icons" },
    4: { icon: "dashboard", class: "material-icons" },
    5: { icon: "shuffle", class: "material-icons" },
    6: { icon: "storage", class: "material-icons" },
    7: { icon: "dashboard", class: "material-icons" },
    8: { icon: "dashboard", class: "material-icons" },
    9: { icon: "dashboard", class: "material-icons" },
    10: { icon: "memory", class: "material-icons" },
    11: { icon: "group", class: "material-symbols-outlined" },
    12: { icon: "ads_click", class: "material-symbols-outlined" },
    13: { icon: "dashboard", class: "material-icons" },
    14: { icon: "dashboard", class: "material-icons" },
    15: { icon: "dashboard", class: "material-icons" },
    16: { icon: "dashboard", class: "material-icons" },
    17: { icon: "dashboard", class: "material-icons" },
    18: { icon: "dashboard", class: "material-icons" },
    19: { icon: "dashboard", class: "material-icons" },
    20: { icon: "dashboard", class: "material-icons" },
    21: { icon: "dashboard", class: "material-icons" },
    22: { icon: "dashboard", class: "material-icons" },
    23: { icon: "dashboard", class: "material-icons" },
    24: { icon: "dashboard", class: "material-icons" },
    25: { icon: "dashboard", class: "material-icons" },
    26: { icon: "dashboard", class: "material-icons" },
    27: { icon: "dashboard", class: "material-icons" },
    28: { icon: "dashboard", class: "material-icons" },
    29: { icon: "dashboard", class: "material-icons" },
    30: { icon: "dashboard", class: "material-icons" },
    31: { icon: "dashboard", class: "material-icons" },
    32: { icon: "dashboard", class: "material-icons" },
    33: { icon: "dashboard", class: "material-icons" },
    34: { icon: "dashboard", class: "material-icons" },
    35: { icon: "dashboard", class: "material-icons" },
    36: { icon: "dashboard", class: "material-icons" },
  };

  public static ADMIN_ICONS = {
    Advertisers: "supervisor_account",
    Users: "people",
    "ART app settings": "settings",
    "Campaign Notes": "notes",
  };

  public static ART_ICONS = {
    "Audience Composition": "dashboard",
    Exclusive: "summarize",
  };
  public static EXCLUSIVE_TOOLTIP = {
    venn: "This represents traffic overlap across supply channels to help you idendify the percentage share of each channel",
    conv_events:
      "Channel wise conversion & events contribution from overlapped traffic",
    firstint_attr:
      "This graph represents channel wise first interaction to ads & conversion attribution to channel",
    conv_channels:
      "Funnel represent the Conversion and events drived from overlapped traffic of particular channel",
    contri_channels:
      "This graph represents channel wise conversion and events driven from unique & overlapped clicks",
    exchange_overlap:
      "Source wise traffic overlap distribution of a particular channelSource wise traffic overlap distribution of a particular channel",
    real_true:
      "Identify the diffrence between present & true value of the channel",
    present_proposed_plan:
      "These two pie charts shows your current media plan and proposed media plan that helps attain true channel value",
  };
}
