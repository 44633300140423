/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
export class AppUrl {
  public static getAPIURL() {
    const browserWindow = window || {};
    return browserWindow['_env']['apiUrl'];
  }

  public static getAPPURL() {
    const browserWindow = window || {};
    return browserWindow['_env']['appUrl'];
  }
}