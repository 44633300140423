/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import { Injectable } from "@angular/core";

@Injectable()
export class AnalyticsChartService {
  public chartObj: Object = {};

  constructor() {}

  renderBarChart(chartData: any) {
    this.chartObj = {
      chart: {
        type: chartData.type ? chartData.type : "column",
        height: chartData.height ? chartData.height : 400,
      },
      title: {
        text: chartData.chartTitle ? chartData.chartTitle : "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        visible: true,
        categories: chartData.categories,
        title: {
          text: chartData.chartHeader.xt ? chartData.chartHeader.xt : "", //chartData.xAxisTitle ? chartData.xAxisTitle : ''
        },
      },
      yAxis: {
        visible: true,
        min: 0,
        title: {
          text: chartData.chartHeader.yt ? chartData.chartHeader.yt : "", //chartData.title,
        },
        labels: {
          overflow: "justify",
          format:
            chartData.formatyaxis == "percentage" ? "{value}%" : "{value}",
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: false,
          },
        },
        series: {
          cursor: chartData.pointer ? chartData.pointer : "pointer",
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: chartData.chartHeader.t ? chartData.chartHeader.t : "", //chartData.title,
          data: chartData.data,
          color: "#3582df",
        },
      ],
      tooltip: {
        headerFormat: chartData.htooltipData ? chartData.htooltipData : "",
        pointFormat: chartData.ptooltipData ? chartData.ptooltipData : "",
      },
    };
    return this.chartObj;
  }

  renderVennChart(chartData: any) {
    this.chartObj = {
      chart: {
        height: chartData.height ? chartData.height : 460,
      },
      color: ["#3582df", "#51baba", "#f38b3f"],
      accessibility: {
        point: {
          descriptionFormatter: function (point) {
            var intersection = point.sets.join(", "),
              name = point.name,
              ix = point.index + 1,
              val = point.value;
            return (
              ix +
              ". Intersection: " +
              intersection +
              ". " +
              (point.sets.length > 1 ? name + ". " : "") +
              "Value " +
              val +
              "."
            );
          },
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        venn: {
          dataLabels: {
            enabled: true,
            // format: '<b>{point.name} </b> {point.value}%',
            formatter: function () {
              if (this.point.name == "I") {
                return this.point.value + "%";
              } else {
                return (
                  "<b>" + this.point.name + "</b>: " + this.point.value + "%"
                );
              }
            },
            softConnector: true,
          },
          width: "70%",
          opacity: 0.75,
          marker: {
            radius: 108,
          },
        },
      },
      tooltip: {
        pointFormat: "{point.name}: <b>{point.value}% </b>",
      },
      series: [
        {
          type: "venn",
          name: "Channel Overlap",
          states: {
            hover: {
              enabled: true,
              opacity: 1.0,
            },
          },
          data: JSON.parse(JSON.stringify(chartData.data)),
        },
      ],
      title: {
        text: "",
      },
    };
    return this.chartObj;
  }

  renderPieChart(chartData: any) {
    this.chartObj = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: chartData.height ? chartData.height : 430,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: chartData.title ? chartData.title : null,
      },
      tooltip: {
        pointFormat: chartData.tooltipData
          ? chartData.tooltipData
          : "{series.name}: <b>{point.y}% </b>",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: chartData.cursor ? chartData.cursor : "pointer",
          dataLabels: {
            enabled: true,
            format: chartData.plotData ? chartData.plotData : "{y} %",
          },
          colors: chartData.piColors ? chartData.piColors : "",
          showInLegend: true,
        },
      },
      series: [
        {
          name: (chartData.chartHeader && chartData.chartHeader.t) ? chartData.chartHeader.t : '',
          colorByPoint: true,
          data: chartData.data,
        },
      ],
    };
    return this.chartObj;
  }

  //Render pie chart for summary report
  renderSummaryPieChart(chartData: any) {
    this.chartObj = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: chartData.height ? chartData.height : 430,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tooltip: {
        headerFormat: chartData.key ? chartData.key : "",
        pointFormat: chartData.phover, //'{point.name}:<b>{point.y}</b>({point.z}%)'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "default",
          dataLabels: {
            enabled: false,
          },
          colors: chartData.piColors ? chartData.piColors : "",
          showInLegend: true,
        },
      },
      series: [
        {
          name: chartData.chartHeader.t,
          colorByPoint: true,
          data: chartData.data,
        },
      ],
    };
    return this.chartObj;
  }

  renderLineChart(chartData: any) {
    this.chartObj = {
      chart: {
        type: "line",
        height: chartData.height ? chartData.height : 400,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: chartData.title ? chartData.title : null,
      },

      yAxis: {
        title: {
          text: chartData.yAxisTitle,
        },
      },
      legend: {
        enabled: true,
      },
      xAxis: {
        title: {
          text: chartData.xAxisTitle,
        },
        categories: chartData.categories,
      },
      tooltip: {
        //  pointFormat: '<b>{point.y}</b>'
      },
      plotOptions: {},
      series: chartData.data,
    };
    return this.chartObj;
  }

  renderStackChart(chartData: any) {
    this.chartObj = {
      chart: {
        type: chartData.type ? chartData.type : "column",
        height: chartData.height ? chartData.height : 400,
        zoomType: "xy",
      },
      colors: chartData.colors ? chartData.colors : "",
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: chartData.categories,
        crosshair: true,
        labels: {
          rotation: chartData.xAxisRotation == true ? -45 : 0,
        },
      },
      yAxis: [
        {
          title: {
            text:
              chartData.chartHeader && chartData.chartHeader.ytl
                ? chartData.chartHeader.ytl
                : chartData.yAxisTitleLeft,
          },
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: "bold",
            },
          },
          labels: {
            overflow: "justify",
            format:
              chartData.formatyaxis == "percentage" ? "{value}%" : "{value}",
          },
        },
        {
          title: {
            text:
              chartData.chartHeader && chartData.chartHeader.ytr
                ? chartData.chartHeader.ytr
                : chartData.yAxisTitleRight,
            style: {
              color: chartData.yAxisTitleRightColor
                ? chartData.yAxisTitleRightColor
                : "#FF7F50",
            },
          },
          opposite: true,
        },
      ],
      legend: {},
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y} %</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
            format: "{y}%",
          },
        },
        series: {
          cursor: "pointer",
        },
      },
      series: chartData.data,
    };
    return this.chartObj;
  }

  public renderPyramidChart(chartData): any {
    this.chartObj = {
      chart: {
        type: "pyramid",
        height: chartData.height ? chartData.height : 398,
      },
      colors: ["#3582df", "#51baba", "#f38b3f"],
      title: {
        text: chartData.title,
        x: -50,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        columnpyramid: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
            color: "white",
          },
        },
        series: {
          dataLabels: {
            // enabled: true,
            // inside: true,
            // format: '<b>{point.name}</b> ({point.v:,.0f})',
            formatter: function () {
              let amillion = 1000000;
              let rDT = this.point.v;
              let tlpDt: any = Number(rDT);
              if (rDT < amillion && rDT > 9999) {
                tlpDt = (rDT / 1000).toFixed(2) + "K";
              } else if (rDT >= amillion && rDT <= 999999999999) {
                tlpDt = (rDT / amillion).toFixed(2) + "M";
              } else if (rDT >= 1000000000 && rDT <= 999999999999) {
                tlpDt = (rDT / 1000000000).toFixed(2) + "B";
              } else if (rDT >= 1000000000000) {
                tlpDt = (rDT / 1000000000000).toFixed(2) + "T";
              }
              return this.point.name + " : " + tlpDt;
            },
            softConnector: true,
          },
          center: ["40%", "50%"],
          width: "45%",
        },
        pyramid: {
          reversed: false,
        },
      },
      tooltip: {
        headerFormat: "<b>{series.name}</b><br/>",
        pointFormat: "{point.name}: {point.v:,.0f}",
        formatter: function () {
          let amillion = 1000000;
          let rDT = this.point.v;
          let tlpDt: any = Number(rDT);
          if (rDT < amillion && rDT > 9999) {
            tlpDt = (rDT / 1000).toFixed(2) + "K";
          } else if (rDT >= amillion && rDT <= 999999999999) {
            tlpDt = (rDT / amillion).toFixed(2) + "M";
          } else if (rDT >= 1000000000 && rDT <= 999999999999) {
            tlpDt = (rDT / 1000000000).toFixed(2) + "B";
          } else if (rDT >= 1000000000000) {
            tlpDt = (rDT / 1000000000000).toFixed(2) + "T";
          }
          return (
            "<b>" +
            this.series["name"] +
            "</b><br/>" +
            this.point.name +
            " : " +
            tlpDt
          );
        },
        valueSuffix: "",
      },
      legend: {
        enabled: false,
      },
      series: chartData.data,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              plotOptions: {
                series: {
                  dataLabels: {
                    inside: true,
                  },
                  center: ["50%", "50%"],
                  width: "100%",
                },
              },
            },
          },
        ],
      },
    };
    return this.chartObj;
  }

  public renderPyramidChartExRC(chartData): any {
    this.chartObj = {
      chart: {
        type: "pyramid",
        height: chartData.height ? chartData.height : 398,
      },
      colors: ["#3582df", "#51baba", "#f38b3f", "#6eae77", "#767676"],
      title: {
        text: chartData.title,
        x: -50,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        columnpyramid: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
            color: "white",
          },
        },
        series: {
          dataLabels: {
            // enabled: true,
            // inside: true,
            format: "<b>{point.name} </b>  {point.v}", //({point.v:,.0f})",
            // formatter: function () {
            //   let amillion = 1000000;
            //   let rDT = this.point.v;
            //   let tlpDt: any = Number(rDT);
            //   if (rDT < amillion && rDT > 9999) {
            //     tlpDt = (rDT / 1000).toFixed(2) + 'K';
            //   }
            //   else if (rDT >= amillion && rDT <= 999999999999) {
            //     tlpDt = (rDT / amillion).toFixed(2) + 'M';
            //   } else if (rDT >= 1000000000 && rDT <= 999999999999) {
            //     tlpDt = (rDT / 1000000000).toFixed(2) + 'B';
            //   } else if (rDT >= 1000000000000) {
            //     tlpDt = (rDT / 1000000000000).toFixed(2) + 'T';
            //   }
            //   return this.point.name + " : " + tlpDt;
            // },
            softConnector: true,
          },
          center: ["40%", "50%"],
          width: "45%",
        },
        pyramid: {
          reversed: false,
        },
      },
      tooltip: {
        headerFormat: "<b>{series.name}</b><br/>",
        pointFormat: "{point.name}: {point.v}", //{point.v:,.0f}",
        formatter: function () {
          let amillion = 1000000;
          let rDT = this.point.v;
          let tlpDt: any = Number(rDT);
          // if (rDT < amillion && rDT > 9999) {
          //   tlpDt = (rDT / 1000).toFixed(2) + "K";
          // } else if (rDT >= amillion && rDT <= 999999999999) {
          //   tlpDt = (rDT / amillion).toFixed(2) + "M";
          // } else if (rDT >= 1000000000 && rDT <= 999999999999) {
          //   tlpDt = (rDT / 1000000000).toFixed(2) + "B";
          // } else if (rDT >= 1000000000000) {
          //   tlpDt = (rDT / 1000000000000).toFixed(2) + "T";
          // }
          return (
            "<b>" +
            this.series["name"] +
            "</b><br/>" +
            this.point.name +
            " : " +
            tlpDt
          );
        },
        valueSuffix: "",
      },
      legend: {
        enabled: false,
      },
      series: chartData.data,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              plotOptions: {
                series: {
                  dataLabels: {
                    inside: true,
                  },
                  center: ["50%", "50%"],
                  width: "100%",
                },
              },
            },
          },
        ],
      },
    };
    return this.chartObj;
  }

  renderDistributionStackChart(chartData: any) {
    this.chartObj = {
      chart: {
        type: chartData.type ? chartData.type : "bar",
        // width: chartData.width ? chartData.width : 800,
        height: chartData.height ? chartData.height : 400,
      },
      colors: chartData.colors ? chartData.colors : "",
      title: {
        text: chartData.title ? chartData.title : null,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: chartData.categories,
        title: {
          text: "",
        },
        labels: {
          style: {
            color: "black",
          },
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        tickInterval: 25,
        visible: chartData.yAxixVisible ? chartData.yAxixVisible : false,
        title: {
          text:
            chartData.chartHeader && chartData.chartHeader.yt
              ? chartData.chartHeader.yt
              : "",
        },
        labels: {
          format:
            chartData.formatyaxis == "percentage" ? "{value}%" : "{value}",
          style: {
            color: "black",
          },
        },
      },
      tooltip: {
        headerFormat: "<b>{point.x}</b><br/>",
        pointFormat: "{series.name}: {point.y}",
        valueSuffix: "%",
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: "{y}%",
            color: "black",
          },
        },
        series: {
          cursor: chartData.cursor ? chartData.cursor : "pointer",
          stacking: "normal",
        },
      },
      legend: {
        enabled: true,
        reversed: true,
      },
      series: chartData.data,
    };
    return this.chartObj;
  }

  renderSummaryBarChart(chart: any) {
    this.chartObj = {
      chart: {
        type: chart.type ? chart.type : "bar",
        height: chart.height ? chart.height : 400,
      },
      colors: chart.colors ? chart.colors : "",
      title: {
        text: chart.title ? chart.title : null,
      },
      xAxis: {
        categories: chart.categories,
      },
      yAxis: {
        min: 0,
        title: {
          text: null,
          align: "high",
        },
        labels: {
          format: chart.formatyaxis == "percentage" ? "{value}%" : "{value}",
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            // inside: true,
            format: "{y}%",
          },
        },
        bar: {
          dataLabels: {
            enabled: true,
            format: "{y}%",
          },
        },
        series: {
          cursor: "pointer",
        },
      },
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        // positioner: function (labelWidth: any, labelHeight: any, point: any) {
        //   var tooltipX, tooltipY;
        //   if (point.plotX + labelWidth > this.chart.plotWidth) {
        //     tooltipX = point.plotX + this.chart.plotLeft - labelWidth - 40;
        //   } else {
        //     tooltipX = point.plotX + this.chart.plotLeft;
        //   }
        //   tooltipY = point.plotY + this.chart.plotTop - 20;
        //   return {
        //     x: tooltipX,
        //     y: tooltipY
        //   };
        // },
        pointFormat: "{series.name}: <b>{point.y}%</b>",
        // pointFormat: '<b>{point.y}</b>'
      },
      series: chart.data,
    };

    return this.chartObj;
  }

  renderRainfallChart(chartData: any) {
    this.chartObj = {
      chart: {
        type: "column",
        height: chartData.height ? chartData.height : 400,
        zoomType: "xy",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: chartData.categories,
        crosshair: true,
        labels: {
          rotation: chartData.xAxisRotation == true ? -45 : 0,
        },
      },
      yAxis: [
        {
          title: {
            text:
              chartData.chartHeader && chartData.chartHeader.ytl
                ? chartData.chartHeader.ytl
                : chartData.yAxisTitleLeft,
          },
          stackLabels: {
            enabled: false,
            style: {
              fontWeight: "bold",
            },
          },
        },
        {
          title: {
            text:
              chartData.chartHeader && chartData.chartHeader.ytr
                ? chartData.chartHeader.ytr
                : chartData.yAxisTitleRight,
            style: {
              color: chartData.yAxisTitleRightColor
                ? chartData.yAxisTitleRightColor
                : "#FF7F50",
            },
          },
          opposite: true,
        },
      ],
      legend: {},
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        series: {
          cursor: chartData.cursor ? chartData.cursor : "pointer",
        },
      },
      series: chartData.data,
    };
    return this.chartObj;
  }

  renderColumnLineChart(chart: any) {
    this.chartObj = {
      title: {
        text: chart.title ? chart.title : null,
      },
      xAxis: {
        categories: chart.categories,
      },
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value}₹',
        },
        title: {
          text: 'eCPA',
        }
      }, { // Secondary yAxis
        title: {
          text: ' ',
        },
        labels: {
          format: '{value} k',
        },
        opposite: true
      }],
      tooltip: {
        // shared: true
        pointFormat: "{series.name}: <b>{point.y}</b>",
      },
      series: chart.data,
    };
    return this.chartObj;
  }

  renderGaugeChart(chart: any) {
    this.chartObj = {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '59%'
      },

      credits: {
        enabled: false,
      },
      title: {
        text: ''
      },

      pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ['50%', '75%'],
        size: '130%'
      },

      // the value axis
      yAxis: {
        min: 0,
        max: 100,
        tickPixelInterval: 72,
        tickPosition: 'inside',
        tickColor: '#FFFFFF',
        tickLength: 20,
        tickWidth: 2,
        minorTickInterval: null,
        labels: {
          distance: 20,
          style: {
            fontSize: '14px'
          }
        },
        lineWidth: 0,
        plotBands: [{
          from: 0,
          to: 45,
          color: '#DF5353', // red
          thickness: 20
        }, {
          from: 46,
          to: 70,
          color: '#DDDF0D', // yellow
          thickness: 20
        }, {
          from: 71,
          to: 100,
          color: '#55BF3B', // green
          thickness: 20
        }]
      },

      series: [{
        name: 'Reach',
        data: chart.data,
        tooltip: {
          valueSuffix: ' %'
        },
        dataLabels: {
          format: '{y} %',
          borderWidth: 0,
          color: '#333333',
          style: {
            fontSize: '16px'
          }
        },
        dial: {
          radius: '80%',
          backgroundColor: 'gray',
          baseWidth: 12,
          baseLength: '0%',
          rearLength: '0%'
        },
        pivot: {
          backgroundColor: 'gray',
          radius: 6
        }

      }]
    }
    return this.chartObj;
  }

}
