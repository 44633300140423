import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ConfigSettings } from "./common/config/config";
import { AppUrl } from "./common/config/apiurl";
import { SegmentService } from "./common/_service/segment.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  loader: boolean = false;
  pagenav: any = [];
  _config: any = ConfigSettings;
  public compRef: any;
  public constructor(
    private router: Router,
    private _service: SegmentService
  ) {}

  onActivateAppComponent(componentRef: any) {
    let currUrl = this.router.url;
    this.compRef = componentRef;
    let userDt = this._service._getUserDt();
    if (userDt && userDt["user_id"] && currUrl != "/login") {
      this.getPageAccessInfo();
    }
  }
  getPageAccessInfo() {
    this.loader = true;
    let userDt = this._service._getUserDt();
    let _url = AppUrl.getAPIURL();
    _url = _url + "/admin/getLeftNavigationsByUserId/" + userDt["user_id"];
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s && data.s == this._config["STATUS_CODE"]["OK"]) {
          this.pagenav = data;
          this._service.setCurrentPageIdS(data["d"]["defaultNavigationPageId"]);
          this.compRef.sendSidebarNav(this.pagenav);
        } else {
          this.loader = false;
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }
}
