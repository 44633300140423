import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AnalyticsComponent } from "./analytics.component";
import { ReportComponent } from "./report/report.component";
import { StaticContentComponent } from "./staticcontent/staticcontent.component";
import { CampanalyticsComponent } from "./campanalytics/campanalytics.component";
const analyticsroutes: Routes = [
  {
    path: "analytics",
    component: AnalyticsComponent,
    children: [
      { path: "campnotes", component: CampanalyticsComponent },
      { path: "mdirect", component: ReportComponent },
      { path: "mdsp", component: ReportComponent },
      { path: "transparency", component: ReportComponent },
      { path: "creatives", component: ReportComponent },
      { path: "oem", component: ReportComponent },
      { path: "landscape", component: ReportComponent },
      { path: "audience", component: ReportComponent },
      { path: "cpl", component: ReportComponent },
      { path: "cps", component: ReportComponent },
      { path: "influncermkt", component: ReportComponent },
      { path: "social", component: ReportComponent },
      { path: "asa", component: ReportComponent },
      { path: "internal1", component: ReportComponent },
      { path: "internal2", component: ReportComponent },
      { path: "channelsummary", component: ReportComponent },
      { path: "saudience", component: ReportComponent },
      { path: "screatives", component: ReportComponent },
      { path: "sdevice", component: ReportComponent },
      { path: "sregion", component: ReportComponent },
      { path: "internal3", component: ReportComponent },
      { path: "internal4", component: ReportComponent },
      { path: "internal5", component: ReportComponent },
      { path: "internal6", component: ReportComponent },
      { path: "internal7", component: ReportComponent },
      { path: "internal8", component: ReportComponent },
      { path: "internal9", component: ReportComponent },
      { path: "internal10", component: ReportComponent },
      { path: "internal11", component: ReportComponent },
      { path: "internal12", component: ReportComponent },
      { path: "sart", component: StaticContentComponent },
      { path: "soverlap", component: StaticContentComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(analyticsroutes)],
  exports: [RouterModule],
})
export class AnalyticsRoutingModule {}
