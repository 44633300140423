import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ConfigSettings } from '../../../common/config/config';

@Component({
  selector: "admin-sidebar",
  templateUrl: "./admin-sidebar.component.html",
  styleUrls: ["./admin-sidebar.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AdminSidebarComponent implements OnInit {
  public sidenav: any = true;
  _config: any = ConfigSettings;
  public adminIcons: any = this._config['ADMIN_ICONS'];
  @Input() sendSidebar: any = [];
  constructor() {  }
  showToggle() {
    if (this.sidenav) {
      document.getElementById("sidenavButton").classList.add("sidenav_shown");
      this.sidenav = false;
    } else {
      document
        .getElementById("sidenavButton")
        .classList.remove("sidenav_shown");
      this.sidenav = true;
    }
  }
  close(a:any){

  }
  ngOnInit() { }
  public mini: any = true;
  public sidebarClass = '';
  toggleSidebar() {
    if (this.mini) {
      this.sidebarClass = 'sidebarexpanded';
      this.mini = false;
    } else {
      this.sidebarClass = 'sidebarcollapsed';
      this.mini = true;
    }
  }
}
