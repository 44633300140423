import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Md5 } from "ts-md5/dist/md5";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { ConfigSettings } from "../../common/config/config";
import { SegmentService } from "../../common/_service/segment.service";
import { AppUrl } from "../../common/config/apiurl";

declare var gapi: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  signinForm: FormGroup;
  showSpinner = false;
  email = "";
  password = "";
  config: any = ConfigSettings;
  error = null;
  hide = true;
  loaderStatus: boolean = false;
  loading: boolean = false;
  lgerror: any = "";
  constructor(
    private router: Router,
    public snackBar: MatSnackBar,
    private _service: SegmentService,
    private formBuilder: FormBuilder
  ) {
    this.isScriptLoaded();
  }

  ngOnInit() {
    this.signinForm = this.formBuilder.group({
      emailFormControl: [
        "",
        [Validators.required, Validators.pattern(this.config["EMAIL_PATTERN"])],
      ],
      passFormControl: ["", Validators.required],
    });

    this._logout();
  }

  login() {
    let email = this.signinForm.controls["emailFormControl"].value;
    let pass = this.signinForm.controls["passFormControl"].value;
    this.showSpinner = true;
    let model = { email: email, credential: pass };

    let isvalid = this.isValidForm();
    if (isvalid == false) {
      this.showSpinner = false;
      return;
    }

    var _url = AppUrl.getAPIURL();
    _url = _url + "/auth/login";
    model.credential = Md5.hashStr(pass);
    this._service._postDefaultApi(_url, model).subscribe(
      (data: any) => {
        this.showSpinner = false;
        if (data.s == 200 && data.data && data.data.user_id) {
          localStorage.setItem("userdetail", JSON.stringify(data.data));
          this.onlogged(data.data);
        } else {
          this.lgerror = data.msg;
          this.snackBar.open(this.lgerror, "X", {
            duration: 5000,
          });
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  isValidForm() {
    if (this.signinForm["status"] == this.config["INVALID_FORM"]) {
      return false;
    }
    return true;
  }

  //This function need to remove from this file as well as from .html file for live google auth.
  _logout() {
    localStorage.removeItem("userdetail");
    localStorage.removeItem("sortkey");
    localStorage.removeItem("router_path");
    localStorage.removeItem("route_id");
    this._service.clearLocalStorage();
  }

  validateUser(udata: any) {
    this.showSpinner = true;
    var _obj = udata;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/auth/getUserInfoByEmail";
    this._service._postApi(_url, _obj).subscribe(
      (data: any) => {
        this.showSpinner = false;
        if (data.s == 200 && data.data && data.data.user_id) {
          localStorage.setItem("userdetail", JSON.stringify(data.data));
          this.onlogged(data.data);
        } else {
          window.location.href = AppUrl.getAPPURL() + "/#/login";
          this.lgerror = data.msg;
          this.snackBar.open(this.lgerror, "X", {
            duration: 5000,
          });
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  onlogged(data) {
    let defPageId =
      data.defAnalyticsPageId && data.defAnalyticsPageId != ""
        ? data.defAnalyticsPageId
        : "";
    let defAnalyticsPage =
      data.defAnalyticsPage && data.defAnalyticsPage != ""
        ? data.defAnalyticsPage
        : "/analytics";
    this._service.setCurrentPageIdS(defPageId);

    setTimeout(() => {
      var _url = AppUrl.getAPPURL();
      window.location.href = _url + "/#" + defAnalyticsPage;
      //this.router.navigate([defAnalyticsPage]);
    }, 200);
  }

  //var url = "https://apis.google.com/js/api:client.js";
  loadScript(src: any) {
    return new Promise(function (resolve, reject) {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = ConfigSettings.GAPI; //"https://apis.google.com/js/api:client.js";

      script.onload = () => resolve(script);
      script.onerror = () => reject(new Error(`Script load error for ${src}`));
      document.getElementsByTagName("head")[0].appendChild(script);
    });
  }

  startApp() {
    gapi.load("auth2", () => {
      var auth2 = gapi.auth2.init({
        client_id: window["_env"]["GOOGLE_PROVIDER_ID"],
        cookiepolicy: "single_host_origin",
      });
      this.attachSignin(auth2, document.getElementById("googleBtn"));
    });
  }

  attachSignin(auth2: any, element: any) {
    auth2.attachClickHandler(
      element,
      {},
      (googleUser: any) => {
        let email = googleUser.getBasicProfile().getEmail();
        let name = googleUser.getBasicProfile().getName();
        //console.log("attachSignin.....attachSignin");
        //console.log(googleUser.getBasicProfile());
        let _uData: any = { email: email, name: name };
        // this.register(_uData);
        this.validateUser(_uData);
        //this.signout(auth2);
        // setTimeout(() => {
        //   let email = _uData.email.split("@")[1];
        //   if (ConfigSettings.INTERNAL_DOMAIN.indexOf(email) != -1) {
        //     console.log(email);
        //     this.register(_uData);

        //   } else {
        //     this.lgerror = ConfigSettings.domain_error;
        //     this.ref.detectChanges();
        //   }
        //   console.log("suryadev register..register");
        // });
      },
      (error: any) => {
        //console.log(JSON.stringify(error, undefined, 2));
      }
    );
  }

  signout(auth2: any) {
    auth2.disconnect();
  }

  isScriptLoaded() {
    let _promise = this.loadScript("url");
    _promise.then(
      (script: any) => console.log(`${script.src} is loaded!`),
      (error: any) => console.log(`Error: ${error.message}`)
    );

    _promise.then((script) => {
      this.startApp();
    });

    //let _obj = JSON.parse(localStorage.getItem("userdetail"));
    //Clear user information of logged user.
    //_obj ? this.clearToken(_obj) : '';
  }
}
