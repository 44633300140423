import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Router, ActivatedRoute } from "@angular/router";
import { SegmentService } from "../../../common/_service/segment.service";
import { AppUrl } from "../../../common/config/apiurl";
import { ConfigSettings } from "../../../common/config/config";
@Component({
  selector: "analytics-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  @Output() eventClicked = new EventEmitter<Event>();
  @Input() sendSidebar: any = [];
  _config: any = ConfigSettings;
  public sidenav: any = true;
  public userdata: any;
  public maasLogo: any;
  public advName: any;
  public status: any;
  public closeOthers = false;
  public activeTab: any;
  public activeTab1: any;
  public logoClassLi: any = "mrgtbrl_collapsed";
  public logoClassImg: any = "sideimg_collapsed";
  public spacediv: any = "height40";
  public logoTitleCls: any = "hide-element";
  public analyticsIcons: any = this._config["ANALYTICS_ICONS"];
  public parentAnalyticsIcons: any = this._config["PARENT_ANALYTICS_ICONS"];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _service: SegmentService
  ) {
    this.setTopHeaderClass();
  }

  setTopHeaderClass() {
    let url = this.router.url.substring(1, this.router.url.length).split("/");
    if (url[1] == "mdsp" || url[1] == "transparency" || url[1] == "creatives") {
      this.activeTab = "sidenavmDSP";
    }
    if (url[1] == "landscape" || url[1] == "audience") {
      this.activeTab = "sidenavCompetition Overview";
    }
    if (url[1] == "dashboard" || url[1] == "mdirect" || url[1] == "oem") {
      this.activeTab = "";
    }
  }

  showToggle() {}
  close(a: any) {}
  toggle() {
    if (this.status) {
      document
        .getElementById("navbar_tog")
        .classList.remove("mat-sidenav-closed");
      document.getElementById("navbar_tog").classList.add("mat-sidenav-opened");
      document.getElementById("nav_icon").classList.add("sidenav_shown");
      this.status = false;
    } else {
      document.getElementById("navbar_tog").classList.add("mat-sidenav-closed");
      document
        .getElementById("navbar_tog")
        .classList.remove("mat-sidenav-opened");
      document.getElementById("nav_icon").classList.remove("sidenav_shown");
      this.status = true;
    }
  }
  ngOnInit() {
    var userDetail = this._service._getUserDt();
    if (userDetail) {
      if (userDetail.user_id != "") {
        this.getSideBarLogo();
      }
    }
  }

  getSideBarLogo() {
    var _url = AppUrl.getAPIURL();
    let user_id = this._service.getUserid();
    _url = _url + "/analytics/getSideBarLogo/" + user_id;
    this._service.getDefaultApi(_url).subscribe(
      (data: any) => {
        if (data.s == 200) {
          this.advName =
            data["d"][0] && data["d"][0]["name"] != ""
              ? data["d"][0]["name"]
              : "";
          this.maasLogo =
            data["d"][0] && data["d"][0]["logo"] != ""
              ? AppUrl.getAPIURL() +
                this._config["UPLOAD_IMG_PATH"] +
                data["d"][0]["logo"]
              : "";
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  onClick(event: Event): void {
    let page_id = event["page_id"];
    this._service.setCurrentPageIdS(page_id);
    this.eventClicked.emit(event);
    this.router.navigate([event["page_url"]]);
  }

  onSubClick(event: Event){
    let page_id = event["page_id"];
    this._service.setCurrentPageIdS(page_id);
    this.eventClicked.emit(event);
    this.router.navigate(['/analytics/'+ event['page_name'] + '/' + page_id]);
  }

  onrightClick(event: Event, mdata): void {
    let page_id = mdata["page_id"];
    this._service.setCurrentPageIdL(page_id);
  }

  onCampNotesClick() {
    this.router.navigate(["/analytics/campnotes"]);
  }

  public mini: any = true;
  public sidebarClass = "";
  toggleSidebar() {
    if (this.mini) {
      this.logoClassLi = "mrgtbrl";
      this.logoClassImg = "sideimg";
      this.sidebarClass = "sidebarexpanded";
      this.logoTitleCls = "show-element";
      this.spacediv = "height0";
      this.mini = false;
    } else {
      this.logoClassLi = "mrgtbrl_collapsed";
      this.logoClassImg = "sideimg_collapsed";
      this.sidebarClass = "sidebarcollapsed";
      this.logoTitleCls = "hide-element";
      this.spacediv = "height40";
      this.mini = true;
    }
  }
}
