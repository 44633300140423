import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// import { CoreModule } from "./../common/core.module";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
// import { cSharedModule } from "../analytics/common/shared.analytics.module";

import { CoreModule } from "../common/core.module";
import { sharedModule } from "../common/shared.module";
import { AdminMgmtComponent } from "./admin-mgmt.component";
import { AdvertiserComponent } from "./advertiser/advertiser.component";
import { UserComponent } from "./user/user.component";
import { AdminSidebarComponent } from "./common/admin-sidebar/admin-sidebar.component";
import { AddAdvertiserComponent } from "./advertiser/add-advertiser/add-advertiser.component";
import { KPIComponent } from "./kpi-add-advertiser/kpi-add-advertiser.component";
import { EditKPIComponent } from "./kpi-edit-advertiser/kpi-edit-advertiser.component";
import { EditAdvertiserComponent } from "./advertiser/edit-advertiser/edit-advertiser.component";
import { AddUserComponent } from "./user/add-user/add-user.component";
import { EditUserComponent } from "./user/edit-user/edit-user.component";
import { AccountListComponent } from "./common/account-list/account-list.component";
import { geolistComponent } from "./common/geo-list/geolist-list.component";
import { AdvertiserListComponent } from "./common/advertiser-list/advertiser-list.component";
import { PagesListComponent } from "./common/pages-list/pages-list.component";
import { ChannelPagesListComponent } from "./common/channel-pages-list/channel-pages-list.component";
import { ArtSettingsComponent } from "./artsettings/artsettings.component";
import { AddArtSettingsComponent } from "./artsettings/add-artsettings/add-artsettings.component";
import { EditArtSettingsComponent } from "./artsettings/edit-artsettings/edit-artsettings.component";
import { CampNotesComponent } from "./campaignnotes/campnotes.component";
import { AddCampNotesComponent } from "./campaignnotes/add-campnotes/add-campnotes.component";
import { EditCampNotesComponent } from "./campaignnotes/edit-campnotes/edit-campnotes.component";
import { MaterialImportModule } from "./material.module";


@NgModule({
  declarations: [
    AdminMgmtComponent,
    AdvertiserComponent,
    UserComponent,
    AdminSidebarComponent,
    AddAdvertiserComponent,
    KPIComponent,
    EditKPIComponent,
    EditAdvertiserComponent,
    AddUserComponent,
    EditUserComponent,
    AccountListComponent,
    AdvertiserListComponent,
    geolistComponent,
    PagesListComponent,
    ChannelPagesListComponent,
    ArtSettingsComponent,
    AddArtSettingsComponent,
    EditArtSettingsComponent,
    CampNotesComponent,
    AddCampNotesComponent,
    EditCampNotesComponent,
  ],
  imports: [CommonModule, CoreModule, sharedModule, MatSlideToggleModule,MaterialImportModule],
  entryComponents: [
    AddAdvertiserComponent,
    EditAdvertiserComponent,
    AddUserComponent,
    EditKPIComponent,
    EditUserComponent,
    AddArtSettingsComponent,
    EditArtSettingsComponent,
    AddCampNotesComponent,
    EditCampNotesComponent,
  ],
})
export class AdminMgmtModule { }
