import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { LoginModule } from "./auth/login/login.module";
import { ForgotPasswordModule } from "./auth/forgot-password/forgot-password.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { passwordModule } from "./password/password.module";
import { AnalyticsModule } from "./analytics/analytics.module";
import { MatSidenavModule } from "@angular/material";
import { AdminMgmtModule } from "./admin-mgmt/admin-mgmt.module";
import { CustomPageComponent } from "./analytics/customPage/customPage.component";

@NgModule({
  declarations: [AppComponent, CustomPageComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LoginModule,
    passwordModule,
    MatSidenavModule,
    ForgotPasswordModule,
    AnalyticsModule,
    HttpClientModule,
    AdminMgmtModule,
  ],
  providers: [
    NgbActiveModal,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
