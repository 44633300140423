import { Component, OnInit, ViewChild, Inject, Optional } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Validators, FormControl, FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUrl } from '../../../common/config/apiurl';
import { SegmentService } from '../../../common/_service/segment.service';
import { ConfigSettings } from '../../../common/config/config';

@Component({
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.css"],
})
export class EditUserComponent implements OnInit {
  @ViewChild('successrule', { static: true }) successrule: any;
  @ViewChild('errorrrule', { static: true }) errorrrule: any;
  @ViewChild('advertiserlist', { static: true }) advertiserlist: any;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditUserComponent>,
    private _service: SegmentService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.accountDT = data;
  }
  loader: boolean = false;
  selectedValue: any = '';
  accountDT: object = [];
  config: any = ConfigSettings;
  success_msg: any = '';
  errorMessage: any = '';
  ruleEditForm: FormGroup;
  public roles: any = '';
  public selected_role: any;
  advertiserList: any = [];
  tblDT: any = [];
  closeMe() {
    this.dialogRef.close();
  }
  namePattern = "^[a-zA-Z -']+";
  emailPattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  nameFormControl = new FormControl("", [
    Validators.required,
    Validators.pattern(this.namePattern),
  ]);
  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.pattern(this.emailPattern),
  ]);

  ngOnInit() {
    this.selected_role = this.accountDT['role'];
    this.ruleEditForm = this.formBuilder.group({
      "uname": [this.accountDT['uname'], [Validators.required, Validators.pattern(this.config['USER_NAME_PATTERN'])]],
      "uemail": [this.accountDT['uemail'], [Validators.required, Validators.pattern(this.config['EMAIL_PATTERN'])]],
      "role": [this.accountDT['role'], [Validators.required]],
    });
    this.getRoleList();
    this.getAdvertiser();
    this.selectedValue = this.selected_role;
  }

  getRoleList() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/user/getRoleList';
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.loader = false;
          this.roles = data['d'] ? data['d'] : [];
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
* @Method : getAdvertiser
* @description : Get advertiser details
**/
  getAdvertiser() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/user/getEditAdvertiserList/' + this.accountDT['user_id'];
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.advertiserList = data['d'];
          this.renderAdvertiserList(data['d']);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
          this.closeMe();
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
 * @Method : renderAdvertiserList
 * @description : Render advertiser list
 **/
  renderAdvertiserList(data: any) {
    let xDt = [];
    let sarr = [];
    this.tblDT.filter((elm: any) => {
      sarr.push(String(elm['id']));
    });
    for (let key in data) {
      if (data[key]['id']) {
        let _ccode = data[key]['id'];
        //let _show = sarr.indexOf(String(_ccode)) != -1 ? true : false;
        xDt.push({ id: _ccode, n: data[key]['name'], show: data[key]['show'] });
      }
    }
    this.advertiserlist ? this.advertiserlist.pubDataList(xDt) : "";
  }

  removeAdvertiserTag(tagname: any) {
    for (let i = 0; i < this.advertiserlist.jsObj.length; i++) {
      if (this.advertiserlist.jsObj[i]['id'] == tagname['id']) {
        this.advertiserlist.jsObj[i]['show'] = false;
      }
    }
    this.advertiserlist.pubDataList(this.advertiserlist.jsObj);
  }

  /**
  * @Method : updateUser
  * @description : Update user details
  **/
  updateUser() {
    let rdt = {};
    let _url = AppUrl.getAPIURL();
    _url = _url + '/user/updateUserDetails';
    let data = this.ruleEditForm.value;

    if (data['role'] == 1) {
      rdt = {
        user_id: this.accountDT['user_id'],
        uname: data['uname'],
        urole: data['role'],
        adv_ids: this.config['DEFAULT_DEMO_ADV_ID']
      };
    } else {
      rdt = {
      user_id: this.accountDT['user_id'],
      uname: data['uname'],
      urole: data['role'],
      adv_ids: this.advertiserlist.advLbl.join()
    };

    let isvalid = this.isValidForm();
    if (isvalid == false) {
      return
      }
    }
    this.loader = true;
    this._service._postApi(_url, rdt).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.success_msg = data.msg;
          this.success(this.successrule);
          this.closeMe();
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  account_role: boolean = false;
  isValidForm() {
    if (!this.advertiserlist.advLbl || this.advertiserlist.advLbl.length == 0) {
      this.account_role = true;
      return false;
    } else {
      this.account_role = false;
    }
    if (this.ruleEditForm['status'] == this.config['INVALID_FORM']) {
      return false;
    }
    return true;
  }

  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }

  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }
}
