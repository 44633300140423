import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatPaginator, MatTableDataSource } from "@angular/material";
import { MatCheckboxChange } from "@angular/material/checkbox";
import {
  Validators,
  FormControl,
  FormBuilder,
  FormGroup,
  FormArray,
  FormGroupDirective,
} from "@angular/forms";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { AppUrl } from "../../common/config/apiurl";
import { SegmentService } from "../../common/_service/segment.service";
import { ConfigSettings } from "../../common/config/config";
import { config } from "process";

interface branch {
  value: string;
  viewValue: string;
}
@Component({
  templateUrl: "./kpi-add-advertiser.component.html",
  styleUrls: ["./kpi-add-advertiser.component.css"],
})
export class KPIComponent implements OnInit {
  config: any = ConfigSettings;
  @ViewChild("errorrrule", { static: true }) errorrrule: any;
  @ViewChild("successrule", { static: true }) successrule: any;
  @ViewChild("mconfirm", { static: true }) mconfirm: any;
  @ViewChild("geocompetlist", { static: true }) geoCompetlist: any;
  @ViewChild("oslist", { static: true }) oslist: any;
  @ViewChild("formDirective", { static: true })
  formDirective: FormGroupDirective;
  @ViewChild("fileInput", { static: true }) fileInputVariable: ElementRef;
  loader: boolean = false;
  ruleForm: FormGroup;
  success_msg: any = "";
  errorMessage: any = "";
  modalWidth: any = "700px";
  ELEMENT_DATA: any = [];
  page_list: any = [];
  geoDTList: any = [];
  tblDT: any = [];
  apgdt: any = "";
  fileInputLabel: string;
  minDate: Date;
  maxDate: Date;
  selectCompetclone: boolean = false;
  is_affle_email: boolean = false;
  complete_not_selected: boolean = false;
  file_not_selected: boolean = false;
  isOrganicselected: boolean = false;
  isASAselected: boolean = false;
  isCompetitorselected: boolean = false;
  filetype_not_allowed: boolean = false;
  adjust_acc_not_sel: boolean = false;
  asa_not_selected: boolean = false;

  selectMMP: boolean = false;
  selectAsaAccount: boolean = false;
  branches: branch[] = [];
  mmpdata: branch[] = [];
  adjustAccData: branch[] = [];
  billingentity: branch[] = [];
  asaAccData: branch[] = [];
  selectBillingEntity: boolean = false;
  constructor(
    public _service: SegmentService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    private router: Router
  ) {
    // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 1, 0, 1);
    this.maxDate = new Date(
      currentYear - 0,
      new Date().getMonth(),
      new Date().getDate() - 1
    );
  }
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit() {
    this.ruleForm = this.formBuilder.group({
      emailid: [
        "",
        [Validators.required, Validators.pattern(this.config["EMAIL_PATTERN"])],
      ],
      AppName2: ["", [Validators.pattern(this.config["NAME_PATTERN"])]],
      os: [""],
      pstoreurl2: [""],
      advid: [
        "",
        [Validators.required, Validators.pattern(this.config["ID_PATTERN"])],
      ],
      advname: [
        "",
        [Validators.required, Validators.pattern(this.config["NAME_PATTERN"])],
      ],
      advlogo: ["", [Validators.required]],
      date: ["", [Validators.required]],
      pstoreurl: [""],
      kpis: ["", [Validators.required]],
      kpimetrics: ["", [Validators.required]],
      pertracker: [""],
      model: [""],
      payout: [""],
      revx_adv_id: [""],
      notes: [""],
      organiccheck: [],
      asacheck: [],
      competitorcheck: [],
      affiescheck: [],
      mdspcheck: [],
      oemcheck: [],
      adjustcheck: [],
      branchcheck: [],
      facebookcheck: [],
      billingentity: [],
      organic: this.formBuilder.array([]),
      competitor: this.formBuilder.array([]),
      mmp: [0],
      adjust_acc: [""],
      asa_org_id: [""],
      asa_account_id: [""],
    });
    this.dataSource.paginator = this.paginator;
    this.renderOsCompetList();
    this.getGEOAccount();
    this.activateActionClone();
    this.getMMPList();
    this.getBillingEntities();
    this.getMMPData();
    this.getAdjustAccounts();
    this.getAsaAccounts();
  }

  getMMPList = () => {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/internal/getMMPList";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data["s"] == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.branches = data["d"];
        } else {
          this.errorMessage = data["msg"];
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  };

  getMMPData = () => {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/internal/getMMPData";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data["s"] == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.mmpdata = data["d"];
        } else {
          this.errorMessage = data["msg"];
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  };

  getAdjustAccounts = () => {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/internal/getAdjustAccounts";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data["s"] == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.adjustAccData = data["d"];
        } else {
          this.errorMessage = data["msg"];
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  };

  getAsaAccounts = () => {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/internal/getAsaAccounts";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data["s"] == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.asaAccData = data["d"];
        } else {
          this.errorMessage = data["msg"];
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  };

  getBillingEntities = () => {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/internal/getBillingEntities";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data["s"] == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.billingentity = data["d"];
        } else {
          this.errorMessage = data["msg"];
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  };

  /**
   * @Method : getGEOAccount
   * @description : Get geo details
   **/
  getGEOAccount() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/internal/getGeoList";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.geoDTList = [...data["d"]];
          this.renderGeoCompetList(this.geoDTList);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
          this.closeMe();
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
   * @Method : activateActionClone
   * @description : Activate clone element on page load time
   **/
  activateActionClone = () => {
    this.ruleForm.get("organiccheck").valueChanges.subscribe((val: any) => {
      this.isOrganicselected = val;
      this.selectMMP = false;
      this.clearOrganicField();
      if (val) {
        this.createOrganicClone();
      }
    });

    this.ruleForm.get("asacheck").valueChanges.subscribe((val: any) => {
      this.isASAselected = val;
    });

    this.ruleForm.get("competitorcheck").valueChanges.subscribe((val: any) => {
      this.isCompetitorselected = val;
      if (val) {
        setTimeout(() => {
          this.renderGeoCompetList(this.geoDTList);
        }, 1000);
      }
    });
  };

  /**
   * @Method : closeMe
   * @description : Clear all element of form
   **/
  closeMe() {
    this.formDirective.resetForm();
    this.fileInputVariable.nativeElement.value = "";
    this.clearOrganicField();
    const cl = (<FormArray>this.ruleForm.controls["competitor"]["value"])
      .length;
    for (let j = 0; j <= cl; j++) {
      this.deleteCompetRow(0);
    }
    this.isCompetitorselected = false;
  }

  /**
   * @Method : onFileChange
   * @description : On file upload upadte file reactive form key
   **/
  onFileChange(event: any) {
    const file = event.target.files[0];
    this.fileInputLabel = file.name;
    if (!this.validateFile(this.fileInputLabel)) {
      this.filetype_not_allowed = true;
      return false;
    } else {
      this.filetype_not_allowed = false;
    }
    this.ruleForm.get("advlogo").setValue(file);
  }

  /**
   * @Method : onChangeApptopiaCB
   * @description : On change of apptopia checkbox
   **/
  onChangeApptopiaCB(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      const cl = (<FormArray>this.ruleForm.controls["competitor"]["value"])
        .length;
      for (let j = 0; j <= cl; j++) {
        this.deleteCompetRow(0);
      }
    }
  }

  /**
   * @Method : validateFile
   * @description : Validate file type
   **/
  validateFile(name: any) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "png" ||
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "jpeg"
    ) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * @Method : renderOsCompetList
   * @description : Render oslist for competitor sub-form
   **/
  renderOsCompetList() {
    this.oslist = this.config["OS_LIST"];
  }

  /**
   * @Method : renderGeoCompetList
   * @description : Render geolist for competitor sub-form
   **/
  renderGeoCompetList(data: any) {
    let xDt = [];
    for (let key in data) {
      if (data[key]["id"]) {
        xDt.push({ id: data[key]["id"], n: data[key]["name"], show: false });
      }
    }
    this.geoCompetlist
      ? this.geoCompetlist.pubDataList(xDt, this.config["GEO_SELECT_LIST"])
      : "";
  }

  /**
   * @Method : setUsersFormArray
   * @description : set competitor form array
   **/
  setUsersFormArray(appname: any, os: any, pstoreurl: any, geo: any) {
    return this.formBuilder.group({
      appname: [appname],
      os: [os],
      pstoreurl: [pstoreurl],
      geo: [geo],
    });
  }

  /**
   * @Method : onAptopiaGeoList
   * @description : onAptopiaGeoList change update list in table/modalbox
   **/
  onAptopiaGeoList(event: any) {
    this.apgdt = "";
    event.filter((elm: any) => {
      if (elm.show == true) {
        this.apgdt = this.apgdt.length ? this.apgdt + " ," + elm.id : elm.id;
        return elm;
      }
    });
  }

  /**
   * @Method : createCompetClone
   * @description : Create clone of competitor form element
   **/
  createCompetClone = () => {
    this.selectCompetclone = false;
    this.complete_not_selected = false;
    let adval = this.ruleForm.value;
    if (
      adval.pstoreurl2 &&
      adval.pstoreurl2.length &&
      adval.os.length &&
      this.apgdt.length
    ) {
      this.competitorArry().push(
        this.setUsersFormArray(
          adval["AppName2"],
          adval["os"],
          adval["pstoreurl2"],
          this.apgdt
        )
      );
      (<any>this.ruleForm.controls["AppName2"]).setValue("");
      (<any>this.ruleForm.controls["os"]).setValue("");
      (<any>this.ruleForm.controls["pstoreurl2"]).setValue("");
    } else {
      this.selectCompetclone = true;
    }
  };

  /**
   * @Method : addFilledCompetClone
   * @description : add last clone to final array without clicking on + icon
   **/
  addFilledCompetClone = () => {
    let adval = this.ruleForm.value;
    if (
      adval.pstoreurl2 &&
      adval.pstoreurl2.length &&
      adval.os.length &&
      this.apgdt.length
    ) {
      this.competitorArry().push(
        this.setUsersFormArray(
          adval["AppName2"],
          adval["os"],
          adval["pstoreurl2"],
          this.apgdt
        )
      );
      (<any>this.ruleForm.controls["AppName2"]).setValue("");
      (<any>this.ruleForm.controls["os"]).setValue("");
      (<any>this.ruleForm.controls["pstoreurl2"]).setValue("");
    }
  };

  /**
   * @Method : createOrganicClone
   * @description : Create clone of organic form element
   **/
  createOrganicClone = () => {
    this.selectMMP = false;
    let a = this.organicArry()["value"].length;
    if (a == 0) {
      this.organicArry().push(this.newOrganicFormArray());
      return;
    }
    let mtxt = this.organicArry()["value"][a - 1]["branchctrl"];
    if (mtxt && String(mtxt).length) {
      this.organicArry().push(this.newOrganicFormArray());
    } else {
      this.selectMMP = true;
    }
  };

  newOrganicFormArray = () => {
    return this.formBuilder.group({
      orgbundleid: ["", [Validators.required]],
      branchctrl: ["", [Validators.required]],
    });
  };

  /**
   * @Method : clearOrganicField
   * @description : Clear organic form elements
   **/
  clearOrganicField = () => {
    let ll = this.organicArry().value.length;
    for (let i = 0; i <= ll; i++) {
      this.deleteOrgRow(0);
    }
  };

  /**
   * @Method : addUserAccount
   * @description : Save user detail/roles/permission
   **/
  addAdvertiser() {
    this.addFilledCompetClone();
    let _url = AppUrl.getAPIURL();
    _url = _url + "/internal/createAdvertiser";
    let data = this.ruleForm.value;
    const formData = new FormData();
    formData.append("emailid", this.ruleForm.get("emailid").value);
    formData.append("advid", this.ruleForm.get("advid").value);
    formData.append("advname", this.ruleForm.get("advname").value);
    formData.append("advlogo", this.ruleForm.get("advlogo").value);
    formData.append("date", this.ruleForm.get("date").value);
    formData.append("notes", this.ruleForm.get("notes").value);
    formData.append("kpis", this.ruleForm.get("kpis").value);
    formData.append("kpimetrics", this.ruleForm.get("kpimetrics").value);
    formData.append("pertracker", this.ruleForm.get("pertracker").value);
    formData.append("model", this.ruleForm.get("model").value);
    formData.append("payout", this.ruleForm.get("payout").value);
    formData.append("asa_org_id", this.ruleForm.get("asa_org_id").value);
    formData.append(
      "asa_account_id",
      this.ruleForm.get("asa_account_id").value
    );
    formData.append(
      "organic",
      JSON.stringify(this.ruleForm.get("organic").value)
    );
    formData.append(
      "apptopia",
      JSON.stringify(this.ruleForm.get("competitor").value)
    );
    formData.append("mmp", this.ruleForm.get("mmp").value);
    formData.append("adjust_acc", this.ruleForm.get("adjust_acc").value);

    let source = {};
    source["affise"] = this.ruleForm.get("affiescheck").value ? 1 : 0;
    source["mdsp"] = this.ruleForm.get("mdspcheck").value ? 1 : 0;
    source["oem"] = this.ruleForm.get("oemcheck").value ? 1 : 0;
    source["adjust"] = this.ruleForm.get("adjustcheck").value ? 1 : 0;
    source["branch"] = this.ruleForm.get("branchcheck").value ? 1 : 0;
    source["facebook"] = this.ruleForm.get("facebookcheck").value ? 1 : 0;
    source["msm"] = 0;
    source["revx"] = 0;
    source["apptopia"] = this.ruleForm.get("competitorcheck").value ? 1 : 0;
    source["organic"] = this.ruleForm.get("organiccheck").value ? 1 : 0;
    source["asa"] = this.ruleForm.get("asacheck").value ? 1 : 0;
    formData.append("source", JSON.stringify(source));
    let isvalid = this.isValidForm(data);
    if (isvalid == false) {
      return;
    }
    this.loader = true;
    this._service._postApi(_url, formData).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.success_msg = data.msg;
          this.success(this.successrule);
          this.closeMe();
        } else {
          if (data.valueMissingFor && data.valueMissingFor != "") {
            this.errorMessage = data.msg + " " + data.valueMissingFor;
          } else {
            this.errorMessage = data.msg;
          }
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
   * @Method : isValidForm
   * @description : validate form
   **/
  isValidForm(data: any) {
    this.file_not_selected = false;
    this.is_affle_email = false;
    if (
      this.ruleForm.get("emailid").value.split("@")[1] !=
        this.config["AFFLE_EMAIL_ONLY"] &&
      this.ruleForm.get("emailid").value.split("@")[1] !=
        this.config["AFFLE_EMAIL_ONLY_CAPS"]
    ) {
      this.is_affle_email = true;
    }
    if (
      this.ruleForm.get("adjustcheck").value == true &&
      this.ruleForm.get("adjust_acc").value == ""
    ) {
      this.adjust_acc_not_sel = true;
      return false;
    } else {
      this.adjust_acc_not_sel = false;
    }

    if (!this.ruleForm.get("advlogo").value) {
      this.file_not_selected = true;
      return false;
    }

    if (
      this.ruleForm.get("asacheck").value == true &&
      (this.ruleForm.get("asa_org_id").value == "" ||
        this.ruleForm.get("asa_account_id").value == "")
    ) {
      this.asa_not_selected = true;
      return false;
    } else {
      this.asa_not_selected = false;
    }

    if (this.isOrganicselected && this.selectMMP == true) {
      return false;
    }

    if (this.isCompetitorselected && data["competitor"].length == 0) {
      this.complete_not_selected = true;
      return false;
    } else {
      this.complete_not_selected = false;
    }

    if (this.ruleForm["status"] == this.config["INVALID_FORM"]) {
      return false;
    }
    return true;
  }

  /**
   * @Method : onMMPChange
   * @description : On change of mmp dropdown
   **/
  checkoem: any = false;
  checkadjust: any = false;
  checkbranch: any = false;
  onMMPChange(event) {
    //console.log(event.source.triggerValue);
    if (event.value == this.config["APPSFLYER"]) {
      this.checkoem = true;
      this.checkadjust = false;
      this.checkbranch = false;
      this.ruleForm.get("oemcheck").setValue(true);
    } else if (event.value == this.config["ADJUST"]) {
      this.checkadjust = true;
      this.checkbranch = false;
      this.checkoem = false;
      this.ruleForm.get("adjustcheck").setValue(true);
    } else if (event.value == this.config["BRANCH"]) {
      this.checkbranch = true;
      this.checkadjust = false;
      this.checkoem = false;
      this.ruleForm.get("branchcheck").setValue(true);
    }
  }

  onChangeMMP(mmp, ob: MatCheckboxChange) {
    if (ob.checked == true) {
      this.ruleForm.get("mmp").setValue(mmp);
    } else {
      this.ruleForm.get("mmp").setValue(0);
    }
  }

  organicArry(): FormArray {
    return this.ruleForm.get("organic") as FormArray;
  }

  competitorArry(): FormArray {
    return this.ruleForm.get("competitor") as FormArray;
  }

  deleteOrgRow = (indx: any) => {
    this.organicArry().removeAt(indx);
  };

  deleteCompetRow = (indx: any) => {
    this.competitorArry().removeAt(indx);
  };

  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  confirmtionBox() {
    this.activeModal = this.modalService.open(this.mconfirm, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  closeError() {
    this.activeModal.close();
  }

  cancelAddAdvertiser() {
    let isUserloggedin = this._service.isUserloggedin();
    if (isUserloggedin) {
      this.router.navigate(["/admin/advertisers/"]);
    } else {
      this.closeMe();
    }
  }
}
export interface PeriodicElement {
  page: string;
}
