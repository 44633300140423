import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import {
  MatPaginator,
  MatTableDataSource,
  MatSort,
  MatDialog,
} from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { AddAdvertiserComponent } from "./add-advertiser/add-advertiser.component";
import { EditAdvertiserComponent } from "./edit-advertiser/edit-advertiser.component";
import { EditKPIComponent } from "../kpi-edit-advertiser/kpi-edit-advertiser.component";
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUrl } from '../../common/config/apiurl';
import { SegmentService } from '../../common/_service/segment.service';
import { ConfigSettings } from '../../common/config/config';
@Component({
  templateUrl: "./advertiser.component.html",
  styleUrls: ["./advertiser.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AdvertiserComponent implements OnInit {
  @ViewChild('errorrrule', { static: true }) errorrrule: any;
  @ViewChild('successrule', { static: true }) successrule: any;
  @ViewChild('mconfirm', { static: true }) mconfirm: any;

  loader: boolean = false;
  config: any = ConfigSettings;
  ELEMENT_DATA: any = [];
  errorMessage: any = '';
  modalWidth: any = '700px';
  rowDT: any = [];
  page: any = [];
  success_msg: any = '';
  checked = false;
  color: any = 'primary';
  advStatusMsg: any = '';
  permissionArr: any = [];

  constructor(public dialog: MatDialog, private router: Router, private _service: SegmentService, public activatedroute: ActivatedRoute, public activeModal: NgbActiveModal, private modalService: NgbModal) { }
  // Dialog/Modals

  addAdvertiser() {
    this.router.navigate(['/admin/kpi/']);
    // let dialogRef = this.dialog.open(AddAdvertiserComponent, {
    //   width: "700px",
    //   disableClose: true
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   // console.log(`Dialog result: ${result}`);
    //   this.getAdvertiserList();
    // });
  }

  renderAdvUsersList(elm: any) {
    let adv_id = elm.id;
    this._service.setSelectedAdvId(adv_id);
    this.router.navigate(['/admin/users/']);
  }

  updateAdvertiser(elm: any) {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/admin/adv/getEditAdvertiserlist/' + elm.id;
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.loader = false;
          this.openEditAdvertiserBox(data['d']);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }


  updateAdvertiserApps(elm: any) {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/admin/adv/getEditAdvertiserAppslist/' + elm.id;
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.loader = false;
          this.openEditKpiBox(data['d'], elm.id);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  openEditAdvertiserBox(d: any) {
    let dialogRef = this.dialog.open(EditAdvertiserComponent, {
      width: "700px",
      disableClose: true,
      data: d
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
      this.getAdvertiserList();
    });
  }

  openEditKpiBox(d: any, aid: any) {
    d.aid = aid;
    let dialogRef = this.dialog.open(EditKPIComponent, {
      width: "1000px",
      disableClose: true,
      data: d
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
      this.getAdvertiserList();
    });
  }

  // Material Table
  displayedColumns: string[] = ["adv_id", "name", "users", "status", "action"];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getAdvertiserList();
  }

  getAdvertiserList() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    let user_id = this._service.getUserid();
    _url = _url + '/admin/adv/getAdvList/' + user_id;
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.loader = false;
          this.dataSource.data = (data['d'] && data['d']['advList']) ? data['d']['advList'] : [];
          this.permissionArr = (data['d'] && data['d']['permissions']) ? data['d']['permissions'] : [];
        } else {
          if (data.s == 555) {
            this.router.navigate(['/login']);
          } else {
            this.errorMessage = data.msg;
            this.error(this.errorrrule);
          }
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  updateAdvStatusCheck(data: any, event: any) {
    this.rowDT = data;
    this.rowDT.isactive = event.checked;
    this.advStatusMsg = event.checked ? this.config['ACTIVATE_ADVERTISER'] : this.config['DEACTIVATE_ADVERTISER'];
    this.confirmtionBox();
  }


  updateAdvStatus() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/admin/adv/updateAdvStatus/' + this.rowDT['id'] + '/' + this.rowDT['isactive'];
    this._service._deleteApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        this.activeModal.close();
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.loader = false;
          this.activeModal.close();
          this.getAdvertiserList();
          this.success_msg = data.msg;
          this.success(this.successrule);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }

  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }

  confirmtionBox() {
    this.activeModal = this.modalService.open(this.mconfirm, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }

  closeError() {
    this.activeModal.close();
  }

  closeConfirm() {
    this.getAdvertiserList();
    this.activeModal.close();
  }
}
export interface PeriodicElement {
  name: string;
  status: string;
  adv_id: number;
  users: number;
}
