import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from "@angular/material";
import { SegmentService } from '../../common/_service/segment.service';
import { AppUrl } from '../../common/config/apiurl';
import { ConfigSettings } from '../../common/config/config';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  showSpinner = false;
  email = "";
  error = null;
  config: any = ConfigSettings;
  forgotmsg: any = '';
  lgerror: any = '';

  constructor(
    public snackBar: MatSnackBar,
    private _service: SegmentService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      emailFormControl: ['', [Validators.required, Validators.pattern(this.config['EMAIL_PATTERN'])]]
    });
  }

  _captcha = '';
  resolved(captchaResponse: string) {
    this._captcha = captchaResponse;
  }

  forgotPassword() {
    this.forgotmsg = '';
    this.lgerror = '';
    this.showSpinner = true;
    let _email = this.forgotForm.controls['emailFormControl'].value;

    let isvalid = this.isValidForm();
    if (isvalid == false) {
      this.showSpinner = false;
      return
    }

    let data = { email: _email, captcha: this._captcha };
    var _url = AppUrl.getAPIURL();
    _url = _url + '/auth/forgotPassword';
    this._service._postApi(_url, data).subscribe(
      (data: any) => {
        //grecaptcha.reset();
        this.showSpinner = false;
        if (data && data.s == 200) {
          this.forgotmsg = data.msg;
          this.snackBar.open(this.forgotmsg, "X", {
            duration: 5000
          });
        } else {
          this.lgerror = data.msg;
          this.snackBar.open(this.lgerror, "X", {
            duration: 5000
          });
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  isValidForm() {
    if (this.forgotForm['status'] == this.config['INVALID_FORM']) {
      return false;
    }
    return true;
  }
}