import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatTableDataSource } from "@angular/material";
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUrl } from '../../../common/config/apiurl';
import { SegmentService } from '../../../common/_service/segment.service';
import { ConfigSettings } from '../../../common/config/config';

@Component({
  templateUrl: "./add-artsettings.component.html",
  styleUrls: ["./add-artsettings.component.css"],
})
export class AddArtSettingsComponent implements OnInit {
  @ViewChild('successrule', { static: true }) successrule: any;
  @ViewChild('errorrrule', { static: true }) errorrrule: any;
  @ViewChild('channelpageslist', { static: true }) channelpageslist: any;
  @ViewChild('pageslist', { static: true }) pageslist: any;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddArtSettingsComponent>,
    private _service: SegmentService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
  ) { }
  loader: boolean = false;
  config: any = ConfigSettings;
  success_msg: any = '';
  errorMessage: any = '';
  public roles: any = '';
  advertiserList: any = [];
  finalPagesList: any = [];
  pagesList: any = [];
  channelsPagesList: any = [];
  ELEMENT_DATA: any = [];
  tblDT: any = [];
  ruleForm: FormGroup;
  closeMe() {
    this.dialogRef.close();
  }

  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  ngOnInit() {
    this.ruleForm = this.formBuilder.group({
      "advertiser": ['', [Validators.required]],
    });

    this.getAdvertiser();
    // this.getPages(0);
  }

  /**
 * @Method : getAdvertiser
 * @description : Get advertiser details
 **/
  getAdvertiser() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/user/getAdvertiserList';
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.advertiserList = data['d'] ? data['d'] : [];
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
          this.closeMe();
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }


  /**
 * @Method : getAdvertiser
 * @description : Get advertiser details
 **/
  getPages(adv_id: any) {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = (adv_id == 0) ? _url + '/appsintegration/getPagesList' : _url + '/appsintegration/getPagesList/' + adv_id;
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.finalPagesList = data['d'];
          this.pagesList = data['d'];
          this.renderPagesList(data['d']);
          this.channelsPagesList = data['d'];
          this.renderChannelsPagesList(data['d']);

        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
          this.closeMe();
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
* @Method : renderPagesList
* @description : Render pages list
**/
  renderPagesList(data: any) {
    let xDt = [];
    let sarr = [];
    // this.tblDT.filter((elm: any) => {
    //   sarr.push(String(elm['id']));
    // });
    for (let key in data) {
      if (data[key]['id']) {
        let _ccode = data[key]['id'];
        let _show = data[key]['show'] ? data[key]['show'] : false;
        xDt.push({ id: _ccode, n: data[key]['n'], show: _show });
      }
    }
    this.pageslist ? this.pageslist.pubDataList(xDt) : "";
  }



  removePagesTag(tagname: any) {
    for (let i = 0; i < this.pageslist.jsObj.length; i++) {
      if (this.pageslist.jsObj[i]['id'] == tagname['id']) {
        this.pageslist.jsObj[i]['show'] = false;
      }
    }
    this.pageslist.pubDataList(this.pageslist.jsObj);
    this.onChangeChannelList();
  }


  /**
* @Method : renderPagesList
* @description : Render pages list
**/
  renderChannelsPagesList(chdata: any) {
    let xDt1 = [];
    let sarr1 = [];
    // console.log('this.tblDT ', this.tblDT);
    // this.tblDT.filter((elm: any) => {
    //   sarr1.push(String(elm['id']));
    // });
    for (let key in chdata) {
      if (chdata[key]['id']) {
        let _ccode = chdata[key]['id'];
        // let _show = sarr1.indexOf(String(_ccode)) != -1 ? true : false;
        let _show = chdata[key]['show'] ? chdata[key]['show'] : false;
        xDt1.push({ id: _ccode, n: chdata[key]['n'], show: _show });
      }
    }
    this.channelpageslist ? this.channelpageslist.chpubDataList(xDt1) : "";
  }



  removeChannelPagesTag(tagname: any) {
    for (let i = 0; i < this.channelpageslist.jsObj.length; i++) {
      if (this.channelpageslist.jsObj[i]['id'] == tagname['id']) {
        this.channelpageslist.jsObj[i]['show'] = false;
      }
    }
    this.channelpageslist.chpubDataList(this.channelpageslist.jsObj);
    this.onChangePagesList();
  }

  /**
  * @Method : addUserAccount
  * @description : Save user detail/roles/permission
  **/
  addAppSetting() {
    let rdt = {};
    let _userDT = this._service._getUserDt();
    let _url = AppUrl.getAPIURL();
    _url = _url + '/appsintegration/createNewAppSetting';
    let data = this.ruleForm.value;

    rdt = {
      advertiser: data['advertiser'],
      channelpages_ids: this.channelpageslist.advLbl.join(),
      pages_ids: this.pageslist.advLbl.join()
    };
    let isvalid = this.isValidForm();
    if (isvalid == false) {
      return
    }


    this.loader = true;
    rdt['puser_id'] = _userDT['user_id'];
    this._service._postApi(_url, rdt).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.success_msg = data.msg;
          this.success(this.successrule);
          this.closeMe();
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  channel_pages_selected: boolean = false;
  pages_selected: boolean = false;
  isValidForm() {
    if (!this.channelpageslist.advLbl || this.channelpageslist.advLbl.length == 0) {
      this.channel_pages_selected = true;
      return false;
    } else {
      this.channel_pages_selected = false;
    }

    if (!this.pageslist.advLbl || this.pageslist.advLbl.length == 0) {
      this.pages_selected = true;
      return false;
    } else {
      this.pages_selected = false;
    }

    if (this.ruleForm['status'] == this.config['INVALID_FORM']) {
      return false;
    }
    return true;
  }

  onAdvChange(event: any) {
    let adv_id = event.value;
    this.getPages(adv_id);
  }

  /**
* @Method : onChannelList
* @description : onChannelList change update list in table/modalbox
**/
  onChangeChannelList() {
    let selectedChannelsArr = this.channelpageslist.advLbl;
    let selectedPagesArr = this.pageslist.advLbl;
    let pagesArr = [];
    for (let i = 0; i < this.finalPagesList.length; i++) {
      if (selectedChannelsArr.indexOf(String(this.finalPagesList[i]['id'])) !== -1) {
        // pagesArr.push({ id: this.finalPagesList[i]['id'], n: this.finalPagesList[i]['n'], show: true });
      }
      else {
        if (selectedPagesArr.indexOf(String(this.finalPagesList[i]['id'])) !== -1) {
          pagesArr.push({ id: this.finalPagesList[i]['id'], n: this.finalPagesList[i]['n'], show: true });
        } else {
          pagesArr.push({ id: this.finalPagesList[i]['id'], n: this.finalPagesList[i]['n'], show: false });
        }
      }
    }
    if (this.finalPagesList.length == selectedChannelsArr.length) { // handling for checkall option
      this.pageslist._clearList();
    }
    this.renderPagesList(pagesArr);
  }

  onChangePagesList() {
    let selectedChannelsArr = this.channelpageslist.advLbl;
    let selectedPagesArr = this.pageslist.advLbl;
    let channelArr = [];
    for (let i = 0; i < this.finalPagesList.length; i++) {
      if (selectedPagesArr.indexOf(String(this.finalPagesList[i]['id'])) !== -1) {
        // pagesArr.push({ id: this.finalPagesList[i]['id'], n: this.finalPagesList[i]['n'], show: true });
      }
      else {
        if (selectedChannelsArr.indexOf(String(this.finalPagesList[i]['id'])) !== -1) {
          channelArr.push({ id: this.finalPagesList[i]['id'], n: this.finalPagesList[i]['n'], show: true });
        } else {
          channelArr.push({ id: this.finalPagesList[i]['id'], n: this.finalPagesList[i]['n'], show: false });
        }
      }
    }
    if (this.finalPagesList.length == selectedPagesArr.length) { // handling for checkall option
      this.channelpageslist._clearList();
    }
    this.renderChannelsPagesList(channelArr);
  }

  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }

  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }

  closeError() {
    this.activeModal.close();
  }
}

export interface PeriodicElement {
  account: string;
}
