import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import {
  MatPaginator,
  MatTableDataSource,
  MatSort,
  MatDialog,
} from "@angular/material";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUrl } from '../../common/config/apiurl';
import { SegmentService } from '../../common/_service/segment.service';
import { ConfigSettings } from '../../common/config/config';
import { SelectionModel } from "@angular/cdk/collections";
import { AddArtSettingsComponent } from "./add-artsettings/add-artsettings.component";
import { EditArtSettingsComponent } from "./edit-artsettings/edit-artsettings.component";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  // selector: 'app-user',
  templateUrl: "./artsettings.component.html",
  styleUrls: ["./artsettings.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ArtSettingsComponent implements OnInit {
  @ViewChild('successrule', { static: true }) successrule: any;
  @ViewChild('errorrrule', { static: true }) errorrrule: any;
  @ViewChild('mconfirm', { static: true }) mconfirm: any;
  constructor(private router: Router, public dialog: MatDialog, private _service: SegmentService, public activeModal: NgbActiveModal, private modalService: NgbModal) { }
  loader: boolean = false;
  config: any = ConfigSettings;
  deleteAccountMsg: any = '';
  ELEMENT_DATA: any = [];
  rowDT: any = [];
  success_msg: any = '';
  errorMessage: any = '';
  // Dialog/Modals
  addAppSettings() {
    let dialogRef = this.dialog.open(AddArtSettingsComponent, {
      width: "700px",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getAdvArtData();
    });
  }

  updateArtData(elm: any) {
    console.log('elm ', elm);
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/appsintegration/getEditAdvArtData/' + elm.adv_id;
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.openEditAccountMBox(data['d']);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  openEditAccountMBox(d) {
    let updateRef = this.dialog.open(EditArtSettingsComponent, {
      width: '700px',
      disableClose: true,
      data: d
    });

    updateRef.afterClosed().subscribe(result => {
      this.getAdvArtData();
    });
  }

  // Material Table
  displayedColumns: string[] = [
    "name",
    // "email",
    // "adv_count",
    // "role",
    // "status",
    "action",
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getAdvArtData();
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  getAdvArtData() {
    this.loader = true;
    let adv_id = this._service.getSelectedAdvId();
    var _url = AppUrl.getAPIURL();
    _url = _url + '/appsintegration/getAdvArtData';
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this._service.resetSelectedAdvId();
          this.dataSource.data = data['d'] ? data['d'] : [];
        } else {
          if (data.s == 555) {
            this.router.navigate(['/login']);
          } else {
            this.errorMessage = data.msg;
            this.error(this.errorrrule);
          }
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  // disableUser(data: any, event: any) {
  //   this.rowDT = data;
  //   this.rowDT.isactive = event.checked;
  //   this.deleteAccountMsg = event.checked ? this.config['ACTIVATE_USER'] : this.config['DLEETE_USER'];
  //   this.confirmtionBox();
  // }

  // enableOrDisableUser() {
  //   this.loader = true;
  //   var _url = AppUrl.getAPIURL();
  //   _url = _url + '/user/disableUser/' + this.rowDT['user_id'] + '/' + this.rowDT['isactive'];
  //   this._service._deleteApi(_url).subscribe(
  //     (data: any) => {
  //       this.loader = false;
  //       this.activeModal.close();
  //       if (data.s == this.config['STATUS_CODE']['OK']) {
  //         this.getAdvArtData();
  //         this.success_msg = data.msg;
  //         this.success(this.successrule);

  //       } else {
  //         this.errorMessage = data.msg;
  //         this.error(this.errorrrule);
  //       }
  //     },
  //     (error: any) => {
  //       this.loader = false;
  //       console.log(error);
  //     }
  //   );
  // }

  // closedEnableOrDisableUser() {
  //   this.getAdvArtData();
  //   this.activeModal.close();
  // }

  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }

  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }

  confirmtionBox() {
    this.activeModal = this.modalService.open(this.mconfirm, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }
}
export interface PeriodicElement {
  name: string;
  // email: string;
  // assoc: number;
  // role: string;
  // status: string;
}
