import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { ConfigSettings } from "../config/config";
import { AppUrl } from "../config/apiurl";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

let httpOptions: any = new HttpHeaders().set("Content-Type", "text/plain");

@Injectable({
  providedIn: "root",
})
export class SegmentService {
  constructor(private http: HttpClient, private router: Router) { }

  _getApi(url: any) {
    let params = this.getUseridParam();
    return this.http.get(url, { params });
  }

  getDefaultApi(url: any) {
    return this.http.get(url);
  }

  _postApi(url: any, data: any) {
    // let params = this.getUseridParam();
    let params = {};
    return this.http.post(url, data, { params });
  }

  _postDefaultApi(url: any, data: any) {
    return this.http.post(url, data);
  }
  _deleteApi(url: any) {
    let params = this.getUseridParam();
    return this.http.delete(url, { params });
  }

  _putApi(url: any, data: any) {
    let params = this.getUseridParam();
    return this.http.put(url, data, { params });
  }

  _uploadThumnil(url: any, data: any) {
    let params = this.getUseridParam();
    return this.http.post(url, data, { params });
  }

  _getUserDt() {
    return JSON.parse(localStorage.getItem("userdetail"));
  }

  isUserloggedin() {
    const currentUser = JSON.parse(localStorage.getItem("userdetail"));
    if (currentUser) {
      return true;
    }
    return false;
  }

  setDefaultCountry(cont: any) {
    let _obj = cont; //ConfigSettings.DEFAULT_COUNTRY;
    localStorage.setItem("country_code", JSON.stringify(_obj));
  }

  getDefaultCountry() {
    // console.log(cont);
    // let country_code = localStorage.getItem('country_code');
    // if (!country_code) {
    //     let _obj = cont //ConfigSettings.DEFAULT_COUNTRY;
    //     localStorage.setItem('country_code', JSON.stringify(_obj));
    // }

    return localStorage.getItem("country_code");
  }

  getUserid() {
    let user = this._getUserDt();
    let user_id = user ? user["user_id"] : 0;
    return user_id;
  }

  getUseridParam() {
    let user = JSON.parse(localStorage.getItem("userdetail")) || {};
    let params = new HttpParams().set("u", user["user_id"]);
    return params;
  }

  validateUser() {
    let isUserloggedin = this.isUserloggedin();
    if (isUserloggedin) {
      return true;
    } else {
      this.userLogOut();
    }
  }

  clearLocalStorage() {
    localStorage.removeItem("userdetail");
    localStorage.removeItem("currentPageId");
    localStorage.removeItem("activeAdvId");
    sessionStorage.removeItem("currentPageId");
    return true;
  }

  userLogOut() {
    this.clearLocalStorage();
    var _url = AppUrl.getAPPURL();
    window.location.href = _url;
  }

  getCurrentPageId() {
    const currentPageIdL = localStorage.getItem("currentPageId") != 'undefined' ? JSON.parse(localStorage.getItem("currentPageId")) : "";
    const currentPageIdS = sessionStorage.getItem("currentPageId") != 'undefined' ? JSON.parse(sessionStorage.getItem("currentPageId")) : "";
    let currentPageId = currentPageIdS ? currentPageIdS : currentPageIdL;
    return currentPageId;
  }

  setCurrentPageIdL(page_id: any) {
    localStorage.setItem("currentPageId", JSON.stringify(page_id));
  }

  setCurrentPageIdS(page_id: any) {
    sessionStorage.setItem("currentPageId", JSON.stringify(page_id));
  }

  getSelectedAdvId() {
    const selectedAdvId = JSON.parse(localStorage.getItem("selectedAdvId"));
    return selectedAdvId;
  }

  setSelectedAdvId(selectedAdvId: any) {
    localStorage.setItem("selectedAdvId", JSON.stringify(selectedAdvId));
  }

  resetSelectedAdvId() {
    localStorage.removeItem("selectedAdvId");
  }
  getParamFromLocalStorage(param: any) {
    const selectedParam = JSON.parse(localStorage.getItem(param));
    return selectedParam;
  }

  setParamInLocalStorage(param: any, value: any) {
    localStorage.setItem(param, JSON.stringify(value));
  }
}
