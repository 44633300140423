import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { passwordComponent } from "./password/password.component";
import { AnalyticsComponent } from "./analytics/analytics.component";
import { ReportComponent } from "./analytics/report/report.component";
import { AdminMgmtComponent } from "./admin-mgmt/admin-mgmt.component";
import { AdvertiserComponent } from "./admin-mgmt/advertiser/advertiser.component";
import { UserComponent } from "./admin-mgmt/user/user.component";
import { KPIComponent } from "./admin-mgmt/kpi-add-advertiser/kpi-add-advertiser.component";
import { ArtSettingsComponent } from "./admin-mgmt/artsettings/artsettings.component";
import { CampNotesComponent } from "./admin-mgmt/campaignnotes/campnotes.component";
import { AddCampNotesComponent } from "./admin-mgmt/campaignnotes/add-campnotes/add-campnotes.component";
import { EditCampNotesComponent } from "./admin-mgmt/campaignnotes/edit-campnotes/edit-campnotes.component";
import { CustomPageComponent } from "./analytics/customPage/customPage.component";

import { AuthGuard } from "./auth/_guards/auth.guard";
const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "forgotPassword", component: ForgotPasswordComponent },
  { path: "pass", component: passwordComponent },
  {
    path: "analytics",
    component: AnalyticsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      {
        path: "dashboard",
        component: ReportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: ":name/:pageId",
        component: CustomPageComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "admin",
    component: AdminMgmtComponent,
    children: [
      { path: "", redirectTo: "advertisers", pathMatch: "full" },
      {
        path: "advertisers",
        component: AdvertiserComponent,
        canActivate: [AuthGuard],
      },
      { path: "users", component: UserComponent, canActivate: [AuthGuard] },
      {
        path: "artsettings",
        component: ArtSettingsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "campnotes",
        component: CampNotesComponent,
        canActivate: [AuthGuard],
      },
      { path: "kpi", component: KPIComponent },
      { path: "addcampnotes", component: AddCampNotesComponent },
      { path: "editcampnotes", component: EditCampNotesComponent },
    ],
  },
  {
    path: "appsintegration",
    loadChildren: "./apps-integration/apps-int.module#AppsIntModule",
    canActivate: [AuthGuard],
  },
  {
    path: "changepassword",
    loadChildren:
      "./auth/change_password/change_password.module#changepasswordModule",
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
