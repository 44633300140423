import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'nodatadiv',
  styleUrls: ['nodatadisplay.component.css'],
  templateUrl: `nodatadisplay.html`
})
export class NodataComponent {
}
