import { Component, OnInit, ViewChild, Inject, Optional } from "@angular/core";
import {
  MatPaginator,
  MatTableDataSource,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { MatCheckboxChange } from "@angular/material/checkbox";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormArray,
  FormGroupDirective,
} from "@angular/forms";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { AppUrl } from "../../../common/config/apiurl";
import { SegmentService } from "../../../common/_service/segment.service";
import { ConfigSettings } from "../../../common/config/config";
import moment from "moment";
interface branch {
  value: string;
  viewValue: string;
}
@Component({
  templateUrl: "./add-campnotes.component.html",
  styleUrls: ["./add-campnotes.component.css"],
})
export class AddCampNotesComponent implements OnInit {
  config: any = ConfigSettings;
  @ViewChild("errorrrule", { static: true }) errorrrule: any;
  @ViewChild("successrule", { static: true }) successrule: any;
  @ViewChild("mconfirm", { static: true }) mconfirm: any;
  @ViewChild("formDirective", { static: true })
  colorslist: any;
  formDirective: FormGroupDirective;
  loader: boolean = false;
  campnotesForm: FormGroup;
  success_msg: any = "";
  errorMessage: any = "";
  headings: any = [];
  advertisers: any = [];
  trendstblDT: any = [];
  minDate: Date;
  maxDate: Date;
  selectAdv: Boolean = false;
  sdateSel: Boolean = false;
  edateSel: Boolean = false;
  selDateValid: boolean = false;
  trendsSel: Boolean = false;
  recommendationsSel: Boolean = false;
  presentplanSel: Boolean = false;

  /**
   * @Method : constructor
   * @description : constructor function
   **/
  constructor(
    public _service: SegmentService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 1, 0, 1);
    this.maxDate = new Date(
      currentYear - 0,
      new Date().getMonth(),
      new Date().getDate() - 1
    );
  }

  /**
   * @Method : ngOnInit
   * @description : initialization
   **/
  ngOnInit() {
    this.campnotesForm = this.formBuilder.group({
      advertiser_id: ["", [Validators.required]],
      sdate: ["", [Validators.required]],
      edate: ["", [Validators.required]],
      trends: this.formBuilder.array([]),
      recommendations: this.formBuilder.array([]),
      presentplan: this.formBuilder.array([]),
    });
    this.setTrendsForm();
    this.setRecommendationsForm();
    this.setPresentplanForm();
    this.renderColorsList();
    this.getAdvertisersList();
    this.getHeadingList();
  }

  /**
   * @Method : getAdvertisersList
   * @description : get Advertisers List
   **/
  getAdvertisersList = () => {
    this.loader = true;
    let _url = AppUrl.getAPIURL();
    let user_id = this._service.getUserid();
    _url = _url + "/admin/adv/getAdvList/" + user_id;
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data["s"] == this.config["STATUS_CODE"]["OK"]) {
          console.log(data["d"]["advList"]);
          this.loader = false;
          this.advertisers =
            data["d"] && data["d"]["advList"] ? data["d"]["advList"] : [];
        } else {
          this.errorMessage = data["msg"];
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  };

  /**
   * @Method : getHeadingList
   * @description : get Heading List
   **/
  getHeadingList = () => {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/admin/campnotes/getHeadingList";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data["s"] == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.headings = data["d"];
        } else {
          this.errorMessage = data["msg"];
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  };

  /**
   * @Method : renderColorsList
   * @description : Render color list
   **/
  renderColorsList() {
    this.colorslist = this.config["CAMPAIGN_COLORS_LIST"];
  }

  /* trends related functions */

  TrendsArry(): FormArray {
    return this.campnotesForm.get("trends") as FormArray;
  }

  /**
   * @Method : createTrendsClone
   * @description : create Trends Clone
   **/
  createTrendsClone = () => {
    let a = this.TrendsArry()["value"].length;
    if (a == 0) {
      this.TrendsArry().push(this.newTrendsFormArray());
      return;
    }
    let mtxt = this.TrendsArry()["value"][a - 1]["trends_desc"];
    if (mtxt && String(mtxt).length) {
      let trendsData = {
        trends_heading: "",
        trends_color: "",
        trends_desc: "",
        trendsneedattention: false,
      };
      this.TrendsArry().push(this.setTrendsFormArray(trendsData));
    } else {
      // this.selectAdjustEvent = true
    }
  };

  /**
   * @Method : setTrendsForm
   * @description : set Trends Form
   **/
  setTrendsForm() {
    this.trendstblDT.forEach((adjustdata: any) => {
      this.TrendsArry().push(this.setTrendsFormArray(adjustdata));
    });
  }

  /**
   * @Method : setTrendsFormArray
   * @description : set Trends Form Array
   **/
  setTrendsFormArray(trendsData: any) {
    return this.formBuilder.group({
      trends_heading: [trendsData["trends_heading"]],
      trends_color: [trendsData["trends_color"]],
      trends_desc: [trendsData["trends_desc"]],
      trendsneedattention: [
        trendsData["trendsneedattention"]
          ? trendsData["trendsneedattention"]
          : false,
      ],
    });
  }

  /**
   * @Method : newTrendsFormArray
   * @description : create new Trends Form Array
   **/
  newTrendsFormArray() {
    return this.formBuilder.group({
      trends_heading: ["", [Validators.required]],
      trends_color: ["", [Validators.required]],
      trends_desc: ["", [Validators.required]],
      trendsneedattention: [false],
    });
  }

  /**
   * @Method : clearTrendsField
   * @description : clear Trends Field
   **/
  clearTrendsField = () => {
    let ml = this.TrendsArry().value.length;
    for (let j = 0; j <= ml; j++) {
      this.deleteTrendsRow(0);
    }
  };

  /**
   * @Method : deleteTrendsRow
   * @description : Delete TrendsRow formArray
   **/
  deleteTrendsRow = (indx: any) => {
    this.TrendsArry().removeAt(indx);
  };

  /* Recommendations related functions */
  /**
   * @Method : RecommendationsArry
   * @description : Recommendations Array
   **/
  RecommendationsArry(): FormArray {
    return this.campnotesForm.get("recommendations") as FormArray;
  }

  /**
   * @Method : createRecommendationsClone
   * @description : create Recommendations Clone
   **/
  createRecommendationsClone = () => {
    let a = this.RecommendationsArry()["value"].length;
    if (a == 0) {
      this.RecommendationsArry().push(this.newRecommendationsFormArray());
      return;
    }
    let mtxt =
      this.RecommendationsArry()["value"][a - 1]["recommendations_desc"];
    if (mtxt && String(mtxt).length) {
      let recommendationsData = {
        recommendations_heading: "",
        recommendations_color: "",
        recommendations_desc: "",
        recommendationsneedattention: "",
      };
      this.RecommendationsArry().push(
        this.setRecommendationsFormArray(recommendationsData)
      );
    } else {
      // this.selectAdjustEvent = true
    }
  };

  /**
   * @Method : setRecommendationsForm
   * @description : set Recommendations Form
   **/
  setRecommendationsForm() {
    this.trendstblDT.forEach((adjustdata: any) => {
      this.RecommendationsArry().push(
        this.setRecommendationsFormArray(adjustdata)
      );
    });
  }

  /**
   * @Method : setRecommendationsFormArray
   * @description : set Recommendations Form rray
   **/
  setRecommendationsFormArray(adjustdata: any) {
    return this.formBuilder.group({
      recommendations_heading: [adjustdata["recommendations_heading"]],
      recommendations_color: [adjustdata["recommendations_color"]],
      recommendations_desc: [adjustdata["recommendations_desc"]],
      recommendationsneedattention: [
        adjustdata["recommendationsneedattention"],
      ],
    });
  }

  /**
   * @Method : newRecommendationsFormArray
   * @description : create new Recommendations Form Array
   **/
  newRecommendationsFormArray() {
    return this.formBuilder.group({
      recommendations_heading: ["", [Validators.required]],
      recommendations_color: ["", [Validators.required]],
      recommendations_desc: ["", [Validators.required]],
      recommendationsneedattention: [""],
    });
  }

  /**
   * @Method : clearRecommendationsField
   * @description : clear Recommendations Field array
   **/
  clearRecommendationsField = () => {
    let ml = this.RecommendationsArry().value.length;
    for (let j = 0; j <= ml; j++) {
      this.deleteRecommendationsRow(0);
    }
  };

  /**
   * @Method : deleteRecommendationsRow
   * @description : Delete Row from RecommendationsArry
   **/
  deleteRecommendationsRow = (indx: any) => {
    this.RecommendationsArry().removeAt(indx);
  };

  /* Presentplan related functions */
  /**
   * @Method : PresentplanArry
   * @description : Present plan Array
   **/
  PresentplanArry(): FormArray {
    return this.campnotesForm.get("presentplan") as FormArray;
  }

  /**
   * @Method : createPresentplanClone
   * @description : create present plan clone
   **/
  createPresentplanClone = () => {
    let a = this.PresentplanArry()["value"].length;
    if (a == 0) {
      this.PresentplanArry().push(this.newPresentplanFormArray());
      return;
    }
    let mtxt = this.PresentplanArry()["value"][a - 1]["presentplan_desc"];
    if (mtxt && String(mtxt).length) {
      let presentplanData = {
        presentplan_heading: "",
        presentplan_color: "",
        presentplan_desc: "",
        presentplanneedattention: false,
      };
      this.PresentplanArry().push(
        this.setPresentplanFormArray(presentplanData)
      );
    } else {
      // this.selectAdjustEvent = true
    }
  };

  /**
   * @Method : setPresentplanForm
   * @description : set present plan form
   **/
  setPresentplanForm() {
    this.trendstblDT.forEach((adjustdata: any) => {
      this.PresentplanArry().push(this.setPresentplanFormArray(adjustdata));
    });
  }

  /**
   * @Method : setPresentplanFormArray
   * @description : set present plan form array
   **/
  setPresentplanFormArray(adjustdata: any) {
    return this.formBuilder.group({
      presentplan_heading: [adjustdata["presentplan_heading"]],
      presentplan_color: [adjustdata["presentplan_color"]],
      presentplan_desc: [adjustdata["presentplan_desc"]],
      presentplanneedattention: [
        adjustdata["presentplanneedattention"] ? true : false,
      ],
    });
  }

  /**
   * @Method : newPresentplanFormArray
   * @description : create new present plan array
   **/
  newPresentplanFormArray() {
    return this.formBuilder.group({
      presentplan_heading: ["", [Validators.required]],
      presentplan_color: ["", [Validators.required]],
      presentplan_desc: ["", [Validators.required]],
      presentplanneedattention: [""],
    });
  }

  /**
   * @Method : clearPresentplanField
   * @description : Clear present plan array
   **/
  clearPresentplanField = () => {
    let ml = this.PresentplanArry().value.length;
    for (let j = 0; j <= ml; j++) {
      this.deletePresentplanRow(0);
    }
  };

  /**
   * @Method : deletePresentplanRow
   * @description : Delete resent plan Row
   **/
  deletePresentplanRow = (indx: any) => {
    this.PresentplanArry().removeAt(indx);
  };

  /**
   * @Method : addCampnotes
   * @description : Save campaign notes
   **/
  addCampnotes() {
    let _url = AppUrl.getAPIURL();
    _url = _url + "/admin/campnotes/addCampnotes";
    let user_id = this._service.getUserid();
    let rData = this.campnotesForm.value;
    rData["sdate"] = moment(rData["sdate"]).format("YYYY-MM-DD");
    rData["edate"] = moment(rData["edate"]).format("YYYY-MM-DD");
    let isvalid = this.isValidForm(rData);
    if (isvalid == false) {
      return;
    }
    this.loader = true;
    this._service._postApi(_url, rData).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.success_msg = data.msg;
          this.success(this.successrule);
          this.router.navigate(["/admin/campnotes/"]);
        } else {
          if (data.valueMissingFor && data.valueMissingFor != "") {
            this.errorMessage = data.msg + " " + data.valueMissingFor;
          } else {
            this.errorMessage = data.msg;
          }
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
   * @Method : isValidForm
   * @description : validate form
   **/
  isValidForm(data: any) {
    this.selectAdv = false;
    this.sdateSel = false;
    this.edateSel = false;
    this.selDateValid = false;
    if (this.campnotesForm.get("advertiser_id").value == "") {
      this.selectAdv = true;
      return false;
    }

    if (this.campnotesForm.get("sdate").value == "") {
      this.sdateSel = true;
      return false;
    }

    if (this.campnotesForm.get("edate").value == "") {
      this.edateSel = true;
      return false;
    }

    if (data["sdate"] > data["edate"]) {
      this.selDateValid = true;
      return false;
    }

    if (data["trends"].length == 0) {
      this.trendsSel = true;
      return false;
    } else {
      this.trendsSel = false;
    }

    if (data["recommendations"].length == 0) {
      this.recommendationsSel = true;
      return false;
    } else {
      this.recommendationsSel = false;
    }

    if (data["presentplan"].length == 0) {
      this.presentplanSel = true;
      return false;
    } else {
      this.presentplanSel = false;
    }

    if (this.campnotesForm["status"] == this.config["INVALID_FORM"]) {
      return false;
    }
    return true;
  }

  /**
   * @Method : cancelAddCampnotes
   * @description : cancel add campaign notes
   **/
  cancelAddCampnotes() {
    let isUserloggedin = this._service.isUserloggedin();
    if (isUserloggedin) {
      this.router.navigate(["/admin/campnotes/"]);
    }
  }

  /**
   * @Method : error
   * @description : error modal box
   **/
  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  /**
   * @Method : error
   * @description : error modal box
   **/
  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  /**
   * @Method : closeError
   * @description : close error
   **/
  closeError() {
    this.activeModal.close();
  }
}
export interface PeriodicElement {
  page: string;
}
