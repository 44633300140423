import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppUrl } from '../common/config/apiurl';
import { Md5 } from 'ts-md5/dist/md5';

import { MatSnackBar } from "@angular/material";
import { ConfigSettings } from '../common/config/config';
import { SegmentService } from '../common/_service/segment.service';

@Component({
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})

export class passwordComponent implements OnInit {
  public ruleForm: FormGroup;
  public rData: any = {};
  public matchPass: any = '';
  public loader: boolean = false;
  public sloader: boolean = false;
  hide1 = true;
  hide2 = true;
  forgotmsg: any = '';
  lgerror: any = '';
  browserWindow = window || {};
  white_logo: any = this.browserWindow['_env']['white_logo'];
  blue_logo: any = this.browserWindow['_env']['blue_logo'];
  header_title: any = this.browserWindow['_env']['header_title'];
  config: any = ConfigSettings;

  constructor(public snackBar: MatSnackBar, private fb: FormBuilder, private _service: SegmentService, private route: ActivatedRoute, private _router: Router) {
    //console.log("constructor..constructor");
  }

  ngOnInit() {
    this.checkurlExist();
    this.ruleForm = this.fb.group({
      "_spass": ['', Validators.required],
      "_repass": ['', Validators.required]
    });
  }

  checkurlExist() {
    let token = this.route.snapshot.queryParamMap.get('t');
    if (token == null || token == undefined || token == '') {
      this._router.navigate(['/login']);
    }

    this.sloader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/auth/checkurl/' + token;
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.sloader = false;
        if (data.s == 200) {
          // this.forgotmsg = data.msg;
          // setTimeout(()=>{
          //     this._router.navigate(['/login']);
          //   },1000)
        } else {
          this.lgerror = data.msg;
          this.snackBar.open(this.lgerror, "X", {
            duration: 5000
          });
          setTimeout(() => {
            this._router.navigate(['/login']);
          }, 5000)
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  setPassword() {
    this.rData = {};
    this.matchPass = '';
    let token = this.route.snapshot.queryParamMap.get('t');

    let isValid = this.isValidpass();
    if (!isValid) {
      return;
    }

    this.sloader = true;
    let _obj = { active_code: token, credential: Md5.hashStr(this.ruleForm.value._spass) };
    var _url = AppUrl.getAPIURL();
    _url = _url + '/auth/resetPassword';
    this._service._postApi(_url, _obj).subscribe(
      (data: any) => {
        this.sloader = false;
        if (data.s == 200) {
          this.forgotmsg = data.msg;
          this.snackBar.open(this.forgotmsg, "X", {
            duration: 5000
          });
          setTimeout(() => {
            this._router.navigate(['/login']);
          }, 5000)
        } else {
          this.lgerror = data.msg;
          this.snackBar.open(this.lgerror, "X", {
            duration: 5000
          });
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  isValidpass() {
    let p1 = this.ruleForm.value._spass;
    let p2 = this.ruleForm.value._repass;

    if (p1 != p2) {
      this.matchPass = ConfigSettings.isconfirm_pass;
      this.snackBar.open(this.matchPass, "X", {
        duration: 5000
      });
      return false;
    }

    if (p2.length <= 10 || p2.length > 15) {
      this.matchPass = ConfigSettings.min_max_length;
      this.snackBar.open(this.matchPass, "X", {
        duration: 5000
      });
      return false;
    }

    let _pat = ConfigSettings.regx_pattern;

    if (!_pat.test(p2)) {
      this.matchPass = ConfigSettings.regx_valid_msg;
      this.snackBar.open(this.matchPass, "X", {
        duration: 5000
      });
      return false;
    }

    return true;
  }
}