import { Component, OnInit, ViewChild, Inject, Optional } from "@angular/core";
import {
  MatPaginator,
  MatTableDataSource,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { MatCheckboxChange } from "@angular/material/checkbox";
import {
  Validators,
  FormControl,
  FormBuilder,
  FormGroup,
  FormArray,
} from "@angular/forms";
//import { EditKPIComponent } from "./../kpi-edit-advertiser/kpi-edit-advertiser.component";

import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppUrl } from "../../common/config/apiurl";
import { SegmentService } from "../../common/_service/segment.service";
import { ConfigSettings } from "../../common/config/config";
import { Md5 } from "ts-md5/dist/md5";

interface branch {
  value: string;
  viewValue: string;
}
@Component({
  //selector: "kpi-app-add-advertiser",
  templateUrl: "./kpi-edit-advertiser.component.html",
  styleUrls: ["./kpi-edit-advertiser.component.css"],
})
export class EditKPIComponent implements OnInit {
  config: any = ConfigSettings;
  @ViewChild("errorrrule", { static: true }) errorrrule: any;
  @ViewChild("successrule", { static: true }) successrule: any;
  @ViewChild("mconfirm", { static: true }) mconfirm: any;
  // @ViewChild('geolist', { static: true }) geolist: any;
  @ViewChild("geocompetlist", { static: true }) geocompetlist: any;
  @ViewChild("oslist", { static: true }) oslist: any;
  loader: boolean = false;
  ruleForm: FormGroup;
  success_msg: any = "";
  errorMessage: any = "";
  modalWidth: any = "700px";
  ELEMENT_DATA: any = [];
  page_list: any = [];
  geoDTList: any = [];
  tblDT: any = [];
  msmtblDT: any = [];
  organictblDT: any = [];
  oemtblDT: any = [];
  adjusttblDT: any = [];
  branchtblDT: any = [];
  facebooktblDT: any = [];
  gdt: any = "";
  apgdt: any = "";
  aposdt: any = "";
  fileInputLabel: string;
  minDate: Date;
  maxDate: Date;
  accountDT: any = [];
  complete_not_selected: boolean = false;
  selectclone: boolean = false;
  selectedBranch: string;
  isAffiseselected: boolean = false;
  isOrganicselected: boolean = false;
  isAsaselected: boolean = false;
  isRevxselected: boolean = false;
  isMSMselected: boolean = false;
  isOEMselected: boolean = false;
  isOEMAggSelected: boolean = false;
  isOEMRawSelected: boolean = false;
  isAdjustselected: boolean = false;
  isBranchselected: boolean = false;
  isFacebookselected: boolean = false;
  isCompetitorselected: boolean = false;
  isPausedCompetitiveSelected: boolean = false;
  selectMMP: boolean = false;
  branches: branch[] = [];
  billingentity: branch[] = [];
  eventlist: branch[] = [];
  events: branch[] = [];
  asaAccData: branch[] = [];
  selectBillingEntity: boolean = false;
  selectAsaAccount: boolean = false;
  selectOemEvent: boolean = false;
  selectAdjustEvent: boolean = false;
  selectBranchEvent: boolean = false;
  asa_not_selected: boolean = false;
  constructor(
    //public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditKPIComponent>,
    public _service: SegmentService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public formBuilder: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.accountDT = data ? data : {};
    let arrt = [];
    for (let key in this.accountDT["appinfo"]) {
      arrt[key] = {
        appname: this.accountDT["appinfo"][key]["app_name"],
        os: this.accountDT["appinfo"][key]["os"],
        pstoreurl: this.accountDT["appinfo"][key]["pstoreurl"],
        geo: this.accountDT["appinfo"][key]["geo"],
      };
    }
    this.tblDT = arrt;

    let arrmsmt = [];
    for (let key in this.accountDT["msminfo"]) {
      arrmsmt[key] = {
        cid: this.accountDT["msminfo"][key]["cid"],
        bid: this.accountDT["msminfo"][key]["bid"],
      };
    }
    this.msmtblDT = arrmsmt;

    let arrorganict = [];
    for (let key in this.accountDT["organicinfo"]) {
      arrorganict[key] = {
        mmp_id: this.accountDT["organicinfo"][key]["mmp_id"],
        bundle_id: this.accountDT["organicinfo"][key]["bundle_id"],
      };
    }
    this.organictblDT = arrorganict;

    let arroemt = [];
    for (let key in this.accountDT["oeminfo"]) {
      arroemt[key] = {
        oem_pstoreurl: this.accountDT["oeminfo"][key]["oem_pstoreurl"],
        oem_os: this.accountDT["oeminfo"][key]["oem_os"],
        event: this.accountDT["oeminfo"][key]["event_key"],
        event_name: this.accountDT["oeminfo"][key]["event_name"],
      };
    }
    this.oemtblDT = arroemt;

    let arradjust = [];
    for (let key in this.accountDT["adjustinfo"]) {
      arradjust[key] = {
        apptoken: this.accountDT["adjustinfo"][key]["app_token"],
        tracker: this.accountDT["adjustinfo"][key]["tracker_value"],
        adjust_event: this.accountDT["adjustinfo"][key]["event_key"],
        adjust_event_name: this.accountDT["adjustinfo"][key]["event_name"],
      };
    }
    this.adjusttblDT = arradjust;

    let arrbranch = [];
    for (let key in this.accountDT["branchinfo"]) {
      arrbranch[key] = {
        appid: this.accountDT["branchinfo"][key]["app_id"],
        access_token: this.accountDT["branchinfo"][key]["access_token"],
        branch_event: this.accountDT["branchinfo"][key]["event_key"],
        branch_event_name: this.accountDT["branchinfo"][key]["event_name"],
      };
    }
    this.branchtblDT = arrbranch;
    let arrfacebook = [];
    for (let key in this.accountDT["facebookinfo"]) {
      arrfacebook[key] = {
        campaignid: this.accountDT["facebookinfo"][key]["campaign_id"],
      };
    }
    this.facebooktblDT = arrfacebook;
  }
  displayedColumns: string[] = ["AppName", "os", "pstoreurl", "Geo", "action"];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit() {
    this.ruleForm = this.formBuilder.group({
      AppName2: ["", [Validators.pattern(this.config["NAME_PATTERN"])]],
      os: [""],
      pstoreurl2: [""],
      kpis: [this.accountDT["kpis"], [Validators.required]],
      kpimetrics: [this.accountDT["kpimetrics"], [Validators.required]],
      pertracker: [this.accountDT["pertracker"]],
      model: [this.accountDT["model"]],
      payout: [this.accountDT["payout"]],
      notes: [this.accountDT["notes"]],
      appinfo: this.formBuilder.array([]),
      campaignid: [],
      revx_adv_id: [
        this.accountDT["revxinfo"],
        [Validators.pattern(this.config["ADV_ID_PATTERN"])],
      ],
      asa_org_id: [this.accountDT["asainfo"]["org_id"]],
      asa_account_id: [this.accountDT["asainfo"]["account_id"]],
      organiccheck: [],
      Revxcheck: [],
      asacheck: [],
      msmcheck: [],
      oemcheck: [],
      oemagg: [],
      oemraw: [],
      adjustcheck: [],
      branchcheck: [],
      facebookcheck: [],
      competitorcheck: [],
      affiescheck: [],
      billingentity: [],
      eventlist: [],
      events: [],
      oem_pstoreurl: [""],
      oem_os: [""],
      event: [],
      event_name: [""],
      organic: this.formBuilder.array([]),
      msm: this.formBuilder.array([]),
      competitor: this.formBuilder.array([]),
      oem: this.formBuilder.array([]),
      adjust: this.formBuilder.array([]),
      branch: this.formBuilder.array([]),
      facebook: this.formBuilder.array([]),
      paused_competitive: [],
    });
    this.setSources(this.accountDT["sourceinfo"]);
    this.isPausedCompetitiveSelected = this.accountDT["paused_apptopia"];
    this.dataSource.paginator = this.paginator;
    this.renderOsCompetList();
    // this.renderOsOemList();
    this.setUsersForm();
    this.getGEOAccount();
    this.getMMPList();
    this.getBillingEntities();
    this.getEventsList();
    this.getAsaAccounts();
    this.getEvents();
    this.setMSMForm();
    this.setOrganicForm();
    this.setOEMForm();
    this.setAdjustForm();
    this.setBranchForm();
    this.setFacebookForm();
    // this.activateActionClone();
    this.displayMMPForm();
  }

  closeMe() {
    this.dialogRef.close();
  }

  getMMPList = () => {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/internal/getMMPList";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data["s"] == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.branches = data["d"];
        } else {
          this.errorMessage = data["msg"];
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  };

  getBillingEntities = () => {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/internal/getBillingEntities";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data["s"] == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.billingentity = data["d"];
        } else {
          this.errorMessage = data["msg"];
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  };

  getEventsList = () => {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/internal/getEventsList";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data["s"] == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.eventlist = data["d"];
        } else {
          this.errorMessage = data["msg"];
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  };

  getAsaAccounts = () => {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/internal/getAsaAccounts";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data["s"] == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.asaAccData = data["d"];
        } else {
          this.errorMessage = data["msg"];
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  };

  getEvents = () => {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/internal/getEvents";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data["s"] == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.events = data["d"];
        } else {
          this.errorMessage = data["msg"];
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  };

  /**
   * @Method : setSources
   * @description : set selected sources
   **/
  setSources(sourceinfo: any) {
    this.isAffiseselected = sourceinfo["affise"];
    this.isCompetitorselected = sourceinfo["apptopia"];
    this.isOrganicselected = sourceinfo["organic"];
    this.isMSMselected = sourceinfo["msm"];
    this.isRevxselected = sourceinfo["revx"];
    this.isAsaselected = sourceinfo["asa"];
    this.isOEMselected =
      sourceinfo["oemagg"] == 1 || sourceinfo["oemraw"] == 1 ? true : false;
    this.isOEMAggSelected = sourceinfo["oemagg"];
    this.isOEMRawSelected = sourceinfo["oemraw"];
    this.isAdjustselected = sourceinfo["adjust"];
    this.isBranchselected = sourceinfo["branch"];
    this.isFacebookselected = sourceinfo["facebook"];
  }

  /**
   * @Method : renderOsCompetList
   * @description : Render oslist for competitor sub-form
   **/
  renderOsCompetList() {
    this.oslist = this.config["OS_LIST"];
  }

  /**
   * @Method : getGEOAccount
   * @description : Get account details
   **/
  getGEOAccount() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + "/internal/getGeoList";
    this._service._getApi(_url).subscribe(
      (data: any) => {
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.loader = false;
          this.geoDTList = data["d"];
          // this.renderGeoList(data['d']);
          this.renderGeoCompetList(this.geoDTList);
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
          this.closeMe();
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
   * @Method : renderGeoCompetList
   * @description : Render geolist for competitor sub-form
   **/
  renderGeoCompetList(data: any) {
    let xDt = [];
    for (let key in data) {
      if (data[key]["id"]) {
        xDt.push({ id: data[key]["id"], n: data[key]["name"], show: false });
      }
    }
    this.geocompetlist
      ? this.geocompetlist.pubDataList(xDt, this.config["GEO_SELECT_LIST"])
      : "";
  }

  /**
   * @Method : activateActionClone
   * @description : Activate clone element on page load time
   **/
  activateActionClone = () => {
    this.ruleForm.get("organiccheck").valueChanges.subscribe((val: any) => {
      this.isOrganicselected = val;
      this.selectMMP = false;
      // this.clearOrganicField();
      // if (val) {
      //   this.createOrganicClone();
      // }
    });

    this.ruleForm.get("Revxcheck").valueChanges.subscribe((val: any) => {
      this.isRevxselected = val;
    });

    this.ruleForm.get("asacheck").valueChanges.subscribe((val: any) => {
      this.isAsaselected = val;
    });

    this.ruleForm.get("msmcheck").valueChanges.subscribe((val: any) => {
      this.isMSMselected = val;
      this.selectBillingEntity = false;
      if (val) {
        // this.clearMSMField();
        // this.createMSMClone();
      }
    });

    // this.ruleForm.get('competitorcheck').valueChanges.subscribe((val: any) => {
    //   this.isCompetitorselected = val;
    //   if (val) {
    //     setTimeout(() => {
    //       this.renderGeoCompetList(this.geoDTList);
    //     }, 1000)
    //   }
    // });
  };

  onChangeApptopiaCB(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      const cl = (<FormArray>this.ruleForm.controls["competitor"]["value"])
        .length;
      for (let j = 0; j <= cl; j++) {
        this.deleteCompetRow(0);
      }
    }
  }

  onChangeMSMCB(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      const cl = (<FormArray>this.ruleForm.controls["msm"]["value"]).length;
      if (cl > 0) {
        // for (let j = 0; j <= cl; j++) {
        //   this.deleteMSMRow(0);
        // }
      } else {
        this.createMSMClone();
      }
    }
  }

  onChangeOrganicCB(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      const cl = (<FormArray>this.ruleForm.controls["organic"]["value"]).length;
      if (cl > 0) {
        // for (let j = 0; j <= cl; j++) {
        //   this.deleteOrgRow(0);
        // }
      } else {
        this.createOrganicClone();
      }
    }
  }

  createOrganicClone = () => {
    this.selectMMP = false;
    let a = this.organicArry()["value"].length;
    if (a == 0) {
      this.organicArry().push(this.newOrganicFormArray());
      return;
    }
    let mtxt = this.organicArry()["value"][a - 1]["branchctrl"];
    if (mtxt && String(mtxt).length) {
      this.organicArry().push(this.newOrganicFormArray());
    } else {
      this.selectMMP = true;
    }
  };

  clearOrganicField = () => {
    let ll = this.organicArry().value.length;
    for (let i = 0; i <= ll; i++) {
      this.deleteOrgRow(0);
    }
  };

  deleteOrgRow = (indx: any) => {
    this.organicArry().removeAt(indx);
  };

  /**
   * @Method : createCompetClone
   * @description : Create clone of competitor form element
   **/
  createCompetClone = () => {
    this.selectclone = false;
    this.complete_not_selected = false;
    let adval = this.ruleForm.value;
    if (
      adval.pstoreurl2 &&
      adval.pstoreurl2.length &&
      adval.os.length &&
      this.apgdt.length
    ) {
      let obj = {
        appname: adval.AppName2,
        pstoreurl: adval.pstoreurl2,
        os: adval.os,
        geo: this.gdt,
      };
      this.competitorArry().push(this.setUsersFormArray(obj));
      (<any>this.ruleForm.controls["AppName2"]).setValue("");
      (<any>this.ruleForm.controls["os"]).setValue("");
      (<any>this.ruleForm.controls["pstoreurl2"]).setValue("");
    } else {
      this.selectclone = true;
    }
  };

  MSMArry(): FormArray {
    return this.ruleForm.get("msm") as FormArray;
  }

  createMSMClone = () => {
    this.selectBillingEntity = false;
    let a = this.MSMArry()["value"].length;
    if (a == 0) {
      this.MSMArry().push(this.newMSMFormArray());
      return;
    }
    let mtxt = this.MSMArry()["value"][a - 1]["billingentity"];
    if (mtxt && String(mtxt).length) {
      this.MSMArry().push(this.newMSMFormArray());
    } else {
      this.selectBillingEntity = true;
    }
  };

  setMSMForm() {
    this.msmtblDT.forEach((user: any) => {
      this.MSMArry().push(this.setMSMFormArray(user));
    });
  }

  setMSMFormArray(msmdata: any) {
    return this.formBuilder.group({
      campaignid: [msmdata["cid"]],
      billingentity: [msmdata["bid"]],
    });
  }

  newMSMFormArray() {
    return this.formBuilder.group({
      campaignid: ["", [Validators.required]],
      billingentity: ["", [Validators.required]],
    });
  }

  clearMSMField = () => {
    let ml = this.MSMArry().value.length;
    for (let j = 0; j <= ml; j++) {
      this.deleteMsmRow(0);
    }
  };

  setOrganicForm() {
    this.organictblDT.forEach((user: any) => {
      this.organicArry().push(this.setOrganicFormArray(user));
    });
  }

  setOrganicFormArray(msmdata: any) {
    return this.formBuilder.group({
      orgbundleid: [msmdata["bundle_id"]],
      branchctrl: [msmdata["mmp_id"]],
    });
  }

  newOrganicFormArray = () => {
    return this.formBuilder.group({
      orgbundleid: ["", [Validators.required]],
      branchctrl: ["", [Validators.required]],
    });
  };

  organicArry(): FormArray {
    return this.ruleForm.get("organic") as FormArray;
  }

  deleteMsmRow = (indx: any) => {
    this.MSMArry().removeAt(indx);
  };

  /**
   * @Method : onAptopiaOsList
   * @description : onAptopiaOsList change update list in table/modalbox
   **/
  onAptopiaOsList(event: any) {
    this.aposdt = "";
    event.filter((elm: any) => {
      if (elm.show == true) {
        this.aposdt = this.aposdt.length ? this.aposdt + " ," + elm.id : elm.id;
        return elm;
      }
    });
  }

  /**
   * @Method : onAptopiaGeoList
   * @description : onAptopiaGeoList change update list in table/modalbox
   **/
  onAptopiaGeoList(event: any) {
    this.apgdt = "";
    event.filter((elm: any) => {
      if (elm.show == true) {
        this.apgdt = this.apgdt.length ? this.apgdt + " ," + elm.id : elm.id;
        return elm;
      }
    });
  }

  /**
   * @Method : onAccountList
   * @description : onAccountList change update list in table/modalbox
   **/
  onAccountList(event: any) {
    this.gdt = "";
    event.filter((elm: any) => {
      if (elm.show == true) {
        this.gdt = this.gdt.length ? this.gdt + " ," + elm.id : elm.id;
        return elm;
      }
    });
  }

  competitorArry(): FormArray {
    return this.ruleForm.get("competitor") as FormArray;
  }

  createClone = () => {
    this.selectclone = false;
    this.complete_not_selected = false;
    let adval = this.ruleForm.value;
    if (
      adval.pstoreurl2 &&
      adval.pstoreurl2.length &&
      adval.os.length &&
      this.apgdt.length
    ) {
      let obj = {
        appname: adval.AppName2,
        pstoreurl: adval.pstoreurl2,
        os: adval.os,
        geo: this.apgdt,
      };
      this.competitorArry().push(this.setUsersFormArray(obj));
      (<any>this.ruleForm.controls["AppName2"]).setValue("");
      (<any>this.ruleForm.controls["os"]).setValue("");
      (<any>this.ruleForm.controls["pstoreurl2"]).setValue("");
    } else {
      this.selectclone = true;
    }
  };

  addCloneVal() {
    let adval = this.ruleForm.value;
    if (
      adval.pstoreurl2 &&
      adval.pstoreurl2.length &&
      adval.os.length &&
      this.apgdt.length
    ) {
      let obj = {
        appname: adval.AppName2,
        pstoreurl: adval.pstoreurl2,
        os: adval.os,
        geo: this.apgdt,
      };
      this.competitorArry().push(this.setUsersFormArray(obj));
      this.setUsersForm();
      (<any>this.ruleForm.controls["AppName2"]).setValue("");
      (<any>this.ruleForm.controls["os"]).setValue("");
      (<any>this.ruleForm.controls["pstoreurl2"]).setValue("");
    }
  }

  setUsersForm() {
    this.tblDT.forEach((user: any) => {
      this.competitorArry().push(this.setUsersFormArray(user));
    });
  }

  setUsersFormArray(user: any) {
    return this.formBuilder.group({
      appname: [user["appname"]],
      pstoreurl: [user["pstoreurl"]],
      os: [user["os"]],
      geo: [user["geo"]],
    });
  }

  resetFormArray() {
    const userCtrl = <FormArray>this.ruleForm.controls["appinfo"];
    while (userCtrl["value"].length != 0) {
      userCtrl.removeAt(0);
    }
  }

  deleteCompetRow = (indx: any) => {
    this.competitorArry().removeAt(indx);
  };

  /**
   * @Method : deleteRow
   * @description : Delete Row roll and change/update formArray
   **/
  deleteRow(elm: any, indx: any) {
    let tblElm = this.tblDT;
    let elmt = tblElm.filter((elm1: any, i: number) => {
      if (i != indx) {
        return elm1;
      }
    });
    this.tblDT = elmt;
    this.setUsersForm();
  }

  /**
   * @Method : addUserAccount
   * @description : Save user detail/roles/permission
   **/
  updateAdvertiser() {
    this.addCloneVal(); // to set value before
    let _url = AppUrl.getAPIURL();
    _url = _url + "/admin/adv/updateAdvertiserKpiDetails";
    let data = this.ruleForm.value;
    let formData: any = {};
    let userDt = JSON.parse(localStorage.getItem("userdetail"));
    formData.id = this.accountDT.aid;
    formData.notes = this.ruleForm.get("notes").value;
    formData.paused_competitive = this.ruleForm.get("paused_competitive").value
      ? 1
      : 0;
    formData.kpis = this.ruleForm.get("kpis").value;
    formData.pertracker = this.ruleForm.get("pertracker").value;
    formData.payout = this.ruleForm.get("payout").value;
    formData.model = this.ruleForm.get("model").value;
    formData.kpimetrics = this.ruleForm.get("kpimetrics").value;

    formData.appinfo = JSON.stringify(this.ruleForm.get("competitor").value);
    formData.organic = JSON.stringify(this.ruleForm.get("organic").value);
    formData.revx_adv_id = this.ruleForm.get("revx_adv_id").value;
    formData.asa_org_id = this.ruleForm.get("asa_org_id").value;
    formData.asa_account_id = this.ruleForm.get("asa_account_id").value;
    formData.msm = JSON.stringify(this.ruleForm.get("msm").value);
    formData.oem = JSON.stringify(this.ruleForm.get("oem").value);
    formData.adjust = JSON.stringify(this.ruleForm.get("adjust").value);
    formData.branch = JSON.stringify(this.ruleForm.get("branch").value);
    formData.facebook = JSON.stringify(this.ruleForm.get("facebook").value);
    let source = {};
    source["affise"] = this.ruleForm.get("affiescheck").value ? 1 : 0;
    // source['art'] = this.ruleForm.get('artcheck').value ? 1 : 0;
    source["apptopia"] = this.ruleForm.get("competitorcheck").value ? 1 : 0;
    source["organic"] = this.ruleForm.get("organiccheck").value ? 1 : 0;
    source["msm"] = this.ruleForm.get("msmcheck").value ? 1 : 0;
    source["revx"] = this.ruleForm.get("Revxcheck").value ? 1 : 0;
    source["oem"] = this.ruleForm.get("oemcheck").value ? 1 : 0;
    source["oemagg"] = this.ruleForm.get("oemagg").value ? 1 : 0;
    source["oemraw"] = this.ruleForm.get("oemraw").value ? 1 : 0;
    source["adjust"] = this.ruleForm.get("adjustcheck").value ? 1 : 0;
    source["branch"] = this.ruleForm.get("branchcheck").value ? 1 : 0;
    source["facebook"] = this.ruleForm.get("facebookcheck").value ? 1 : 0;
    source["asa"] = this.ruleForm.get("asacheck").value ? 1 : 0;

    formData.source = JSON.stringify(source);
    formData.user_id = userDt["user_id"];
    let isvalid = this.isValidForm(data);
    if (isvalid == false) {
      return;
    }
    this.loader = true;
    this._service._postApi(_url, formData).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config["STATUS_CODE"]["OK"]) {
          this.success_msg = data.msg;
          this.success(this.successrule);
          this.closeMe();
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  formateFormDT(data: any) {
    let rdt = { advid: data["advid"], advname: data["advname"], appinfo: [] };
    if (data["appinfo"].length != 0) {
      for (let k in data["appinfo"]) {
        if (
          data["appinfo"][k]["pid"] &&
          data["appinfo"][k]["pid"] != "" &&
          data["appinfo"][k]["pid"] != undefined &&
          data["appinfo"][k]["pid"] != null
        ) {
          rdt["appinfo"].push(data["appinfo"][k]);
        }
      }
    }
    return rdt;
  }

  isValidForm(data: any) {
    let isValidOemData = this.checkOemData(data);
    if (isValidOemData == false) {
      return false;
    }
    let isValidAdjustData = this.checkAdjustData(data);
    if (isValidAdjustData == false) {
      return false;
    }
    let isValidBranchData = this.checkBranchData(data);
    if (isValidBranchData == false) {
      return false;
    }
    let isValidFacebookData = this.checkFacebookData(data);
    if (isValidFacebookData == false) {
      return false;
    }
    if (
      this.ruleForm.get("asacheck").value == true &&
      (this.ruleForm.get("asa_org_id").value == "" ||
        this.ruleForm.get("asa_account_id").value == "")
    ) {
      this.asa_not_selected = true;
      return false;
    } else {
      this.asa_not_selected = false;
    }

    if (this.ruleForm["status"] == this.config["INVALID_FORM"]) {
      return false;
    }
    return true;
  }

  isEventSelected: boolean = true;
  isEventNameSelected: boolean = true;
  hashEventValid: boolean = true;
  hashEventNameValid: boolean = true;
  checkOemData(data) {
    let oemData = data["oem"];
    let fhashArr = [];
    let shashArr = [];
    this.isEventSelected = true;
    this.isEventNameSelected = true;
    if (oemData && oemData.length > 0) {
      for (let k in oemData) {
        let fhash = oemData[k]["oem_pstoreurl"] + "_" + oemData[k]["event"];
        let shash =
          oemData[k]["oem_pstoreurl"] +
          "_" +
          oemData[k]["event_name"].toLowerCase();
        let fhashmd5 = Md5.hashStr(fhash);
        let shashmd5 = Md5.hashStr(shash);
        if (oemData[k]["event"] == "") {
          this.isEventSelected = false;
          return false;
        }
        if (oemData[k]["event_name"] == "") {
          this.isEventNameSelected = false;
          return false;
        }
        if (fhashArr.length > 0 && fhashArr.indexOf(fhashmd5) > -1) {
          this.hashEventValid = false;
          return false;
        }
        if (shashArr.length > 0 && shashArr.indexOf(shashmd5) > -1) {
          this.hashEventNameValid = false;
          return false;
        }
        fhashArr.push(fhashmd5);
        shashArr.push(shashmd5);
      }
      return true;
    }
  }

  isAdjustEventSelected: boolean = true;
  isAdjustEventNameSelected: boolean = true;
  hashAdjustEventValid: boolean = true;
  hashAdjustEventNameValid: boolean = true;
  checkAdjustData(data) {
    let adjustData = data["adjust"];
    let fhashArr = [];
    let shashArr = [];
    this.isAdjustEventSelected = true;
    this.isAdjustEventNameSelected = true;
    this.hashAdjustEventValid = true;
    this.hashAdjustEventNameValid = true;
    if (adjustData && adjustData.length > 0) {
      for (let k in adjustData) {
        let fhash =
          adjustData[k]["apptoken"] +
          "_" +
          adjustData[k]["tracker"] +
          "_" +
          adjustData[k]["adjust_event"];
        let shash =
          adjustData[k]["apptoken"] +
          "_" +
          adjustData[k]["tracker"] +
          "_" +
          adjustData[k]["adjust_event_name"].toLowerCase();
        let fhashmd5 = Md5.hashStr(fhash);
        let shashmd5 = Md5.hashStr(shash);
        if (adjustData[k]["adjust_event"] == "") {
          this.isAdjustEventSelected = false;
          return false;
        }
        if (adjustData[k]["adjust_event_name"] == "") {
          this.isAdjustEventNameSelected = false;
          return false;
        }
        if (fhashArr.length > 0 && fhashArr.indexOf(fhashmd5) > -1) {
          this.hashAdjustEventValid = false;
          return false;
        }
        if (shashArr.length > 0 && shashArr.indexOf(shashmd5) > -1) {
          this.hashAdjustEventNameValid = false;
          return false;
        }
        fhashArr.push(fhashmd5);
        shashArr.push(shashmd5);
      }
      return true;
    }
  }

  isBranchEventSelected: boolean = true;
  isBranchEventNameSelected: boolean = true;
  hashBranchEventValid: boolean = true;
  hashBranchEventNameValid: boolean = true;
  checkBranchData(data) {
    let branchData = data["branch"];
    let fhashArr = [];
    let shashArr = [];
    this.isBranchEventSelected = true;
    this.isBranchEventNameSelected = true;
    this.hashBranchEventValid = true;
    this.hashBranchEventNameValid = true;
    if (branchData && branchData.length > 0) {
      for (let k in branchData) {
        let fhash =
          branchData[k]["appid"] + "_" + branchData[k]["branch_event"];
        let shash =
          branchData[k]["appid"] +
          "_" +
          branchData[k]["branch_event_name"].toLowerCase();
        let fhashmd5 = Md5.hashStr(fhash);
        let shashmd5 = Md5.hashStr(shash);
        if (branchData[k]["branch_event"] == "") {
          this.isBranchEventSelected = false;
          return false;
        }
        if (branchData[k]["branch_event_name"] == "") {
          this.isBranchEventNameSelected = false;
          return false;
        }
        if (fhashArr.length > 0 && fhashArr.indexOf(fhashmd5) > -1) {
          this.hashBranchEventValid = false;
          return false;
        }
        if (shashArr.length > 0 && shashArr.indexOf(shashmd5) > -1) {
          this.hashBranchEventNameValid = false;
          return false;
        }
        fhashArr.push(fhashmd5);
        shashArr.push(shashmd5);
      }
      return true;
    }
  }

  isFacebookCampaignIdValid: boolean = true;
  isFacebookCampaignIdDuplicate: boolean = true;
  isFacebookCampaignIdNumeric: boolean = true;
  checkFacebookData(data) {
    let facebookData = data["facebook"];
    let fhashArr = [];
    this.isFacebookCampaignIdValid = true;
    this.isFacebookCampaignIdDuplicate = true;
    this.isFacebookCampaignIdNumeric = true;
    if (facebookData && facebookData.length > 0) {
      for (let k in facebookData) {
        let fhash = facebookData[k]["campaignid"];
        let fhashmd5 = Md5.hashStr(fhash);

        if (facebookData[k]["campaignid"] == "") {
          this.isFacebookCampaignIdValid = false;
          return false;
        }

        if (fhashArr.length > 0 && fhashArr.indexOf(fhashmd5) > -1) {
          this.isFacebookCampaignIdDuplicate = false;
          return false;
        }

        if (isNaN(facebookData[k]["campaignid"])) {
          this.isFacebookCampaignIdNumeric = false;
          return false;
        }

        fhashArr.push(fhashmd5);
      }
      return true;
    }
  }

  onChangeOEMCB(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      this.isOEMAggSelected = true;
      const cl = (<FormArray>this.ruleForm.controls["oem"]["value"]).length;
      if (cl > 0) {
        // for (let j = 0; j <= cl; j++) {
        //   this.deleteMSMRow(0);
        // }
      } else {
        this.createOEMClone();
      }
    } else {
      this.isOEMAggSelected = false;
      this.isOEMRawSelected = false;
    }
  }

  onChangeOEMAgg(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      this.isOEMselected = true;
    } else if (ob.checked == false && this.isOEMRawSelected == false) {
      this.isOEMselected = false;
    }
  }

  onChangeOEMRaw(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      this.isOEMselected = true;
    } else if (ob.checked == false && this.isOEMAggSelected == false) {
      this.isOEMselected = false;
    }
  }

  OEMArry(): FormArray {
    return this.ruleForm.get("oem") as FormArray;
  }

  createOEMClone = () => {
    this.selectOemEvent = false;
    let a = this.OEMArry()["value"].length;
    if (a == 0) {
      this.OEMArry().push(this.newOEMFormArray());
      return;
    }
    let mtxt = this.OEMArry()["value"][a - 1]["event"];
    if (mtxt && String(mtxt).length) {
      let oemData = {
        oem_pstoreurl: this.OEMArry()["value"][0]["oem_pstoreurl"],
        oem_os: "",
        event: "",
        event_name: "",
      };
      this.OEMArry().push(this.setOEMFormArray(oemData));
    } else {
      this.selectOemEvent = true;
    }
  };

  setOEMForm() {
    this.oemtblDT.forEach((oemdata: any) => {
      this.OEMArry().push(this.setOEMFormArray(oemdata));
    });
  }

  setOEMFormArray(oemdata: any) {
    return this.formBuilder.group({
      oem_pstoreurl: [oemdata["oem_pstoreurl"]],
      oem_os: [oemdata["oem_os"]],
      event: [oemdata["event"]],
      event_name: [oemdata["event_name"]],
    });
  }

  newOEMFormArray() {
    return this.formBuilder.group({
      oem_pstoreurl: ["", [Validators.required]],
      oem_os: ["", [Validators.required]],
      event: ["", [Validators.required]],
      event_name: ["", [Validators.required]],
    });
  }

  clearOEMField = () => {
    let ml = this.OEMArry().value.length;
    for (let j = 0; j <= ml; j++) {
      this.deleteOEMRow(0);
    }
  };

  /**
   * @Method : deleteRow
   * @description : Delete Row roll and change/update formArray
   **/
  deleteOEMRow = (indx: any) => {
    this.OEMArry().removeAt(indx);
  };

  onChangeAdjustCB(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      const cl = (<FormArray>this.ruleForm.controls["adjust"]["value"]).length;
      if (cl > 0) {
        // for (let j = 0; j <= cl; j++) {
        //   this.deleteMSMRow(0);
        // }
      } else {
        this.createAdjustClone();
      }
    }
  }

  AdjustArry(): FormArray {
    return this.ruleForm.get("adjust") as FormArray;
  }

  createAdjustClone = () => {
    this.selectAdjustEvent = false;
    let a = this.AdjustArry()["value"].length;
    if (a == 0) {
      this.AdjustArry().push(this.newAdjustFormArray());
      return;
    }
    let mtxt = this.AdjustArry()["value"][a - 1]["adjust_event"];
    if (mtxt && String(mtxt).length) {
      let adjustData = {
        apptoken: this.AdjustArry()["value"][0]["apptoken"],
        tracker: this.AdjustArry()["value"][0]["tracker"],
        adjust_event: "",
        adjust_event_name: "",
      };
      this.AdjustArry().push(this.setAdjustFormArray(adjustData));
    } else {
      this.selectAdjustEvent = true;
    }
  };

  setAdjustForm() {
    this.adjusttblDT.forEach((adjustdata: any) => {
      this.AdjustArry().push(this.setAdjustFormArray(adjustdata));
    });
  }

  setAdjustFormArray(adjustdata: any) {
    return this.formBuilder.group({
      apptoken: [adjustdata["apptoken"]],
      tracker: [adjustdata["tracker"]],
      adjust_event: [adjustdata["adjust_event"]],
      adjust_event_name: [adjustdata["adjust_event_name"]],
    });
  }

  newAdjustFormArray() {
    return this.formBuilder.group({
      apptoken: ["", [Validators.required]],
      tracker: ["", [Validators.required]],
      adjust_event: ["", [Validators.required]],
      adjust_event_name: ["", [Validators.required]],
    });
  }

  clearAdjustField = () => {
    let ml = this.AdjustArry().value.length;
    for (let j = 0; j <= ml; j++) {
      this.deleteAdjustRow(0);
    }
  };

  /**
   * @Method : deleteRow
   * @description : Delete Row roll and change/update formArray
   **/
  deleteAdjustRow = (indx: any) => {
    this.AdjustArry().removeAt(indx);
  };

  onChangeBranchCB(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      const cl = (<FormArray>this.ruleForm.controls["branch"]["value"]).length;
      if (cl > 0) {
        // for (let j = 0; j <= cl; j++) {
        //   this.deleteMSMRow(0);
        // }
      } else {
        this.createBranchClone();
      }
    }
  }

  BranchArry(): FormArray {
    return this.ruleForm.get("branch") as FormArray;
  }

  createBranchClone = () => {
    this.selectBranchEvent = false;
    let a = this.BranchArry()["value"].length;
    if (a == 0) {
      this.BranchArry().push(this.newBranchFormArray());
      return;
    }
    let mtxt = this.BranchArry()["value"][a - 1]["branch_event"];
    if (mtxt && String(mtxt).length) {
      let branchData = {
        appid: this.BranchArry()["value"][0]["appid"],
        access_token: this.BranchArry()["value"][0]["access_token"],
        branch_event: "",
        branch_event_name: "",
      };
      this.BranchArry().push(this.setBranchFormArray(branchData));
    } else {
      this.selectBranchEvent = true;
    }
  };

  setBranchForm() {
    this.branchtblDT.forEach((branchdata: any) => {
      this.BranchArry().push(this.setBranchFormArray(branchdata));
    });
  }

  setBranchFormArray(branchdata: any) {
    return this.formBuilder.group({
      appid: [branchdata["appid"]],
      access_token: [branchdata["access_token"]],
      branch_event: [branchdata["branch_event"]],
      branch_event_name: [branchdata["branch_event_name"]],
    });
  }

  newBranchFormArray() {
    return this.formBuilder.group({
      appid: ["", [Validators.required]],
      access_token: ["", [Validators.required]],
      branch_event: ["", [Validators.required]],
      branch_event_name: ["", [Validators.required]],
    });
  }

  clearBranchField = () => {
    let ml = this.BranchArry().value.length;
    for (let j = 0; j <= ml; j++) {
      this.deleteBranchRow(0);
    }
  };

  checkoem: any = false;
  checkadjust: any = false;
  checkbranch: any = false;
  displayMMPForm() {
    if (this.accountDT["mmp_id"] == this.config["APPSFLYER"]) {
      this.checkoem = true;
      this.checkadjust = false;
      this.checkbranch = false;
    } else if (this.accountDT["mmp_id"] == this.config["ADJUST"]) {
      this.checkadjust = true;
      this.checkbranch = false;
      this.checkoem = false;
    } else if (this.accountDT["mmp_id"] == this.config["BRANCH"]) {
      this.checkbranch = true;
      this.checkadjust = false;
      this.checkoem = false;
    }
  }

  /**
   * @Method : deleteRow
   * @description : Delete Row roll and change/update formArray
   **/
  deleteBranchRow = (indx: any) => {
    this.BranchArry().removeAt(indx);
  };

  onChangeFacebookCB(ob: MatCheckboxChange) {
    if (ob.checked == true) {
      const cl = (<FormArray>this.ruleForm.controls["facebook"]["value"])
        .length;
      if (cl > 0) {
        // for (let j = 0; j <= cl; j++) {
        //   this.deleteMSMRow(0);
        // }
      } else {
        this.createFacebookClone();
      }
    }
  }

  FacebookArry(): FormArray {
    return this.ruleForm.get("facebook") as FormArray;
  }

  createFacebookClone = () => {
    let a = this.FacebookArry()["value"].length;
    if (a == 0) {
      this.FacebookArry().push(this.newFacebookFormArray());
      return;
    }
    let mtxt = this.FacebookArry()["value"][a - 1]["campaignid"];
    if (mtxt && String(mtxt).length) {
      let facebookData = { campaignid: "" };
      this.FacebookArry().push(this.setFacebookFormArray(facebookData));
    }
  };

  setFacebookForm() {
    this.facebooktblDT.forEach((facebookdata: any) => {
      this.FacebookArry().push(this.setFacebookFormArray(facebookdata));
    });
  }

  setFacebookFormArray(facebookdata: any) {
    return this.formBuilder.group({
      campaignid: [facebookdata["campaignid"]],
    });
  }

  newFacebookFormArray() {
    return this.formBuilder.group({
      campaignid: ["", [Validators.required]],
    });
  }

  clearFacebookField = () => {
    let ml = this.FacebookArry().value.length;
    for (let j = 0; j <= ml; j++) {
      this.deleteFacebookRow(0);
    }
  };

  /**
   * @Method : deleteRow
   * @description : Delete Row roll and change/update formArray
   **/
  deleteFacebookRow = (indx: any) => {
    this.FacebookArry().removeAt(indx);
  };

  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }
  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }
  confirmtionBox() {
    this.activeModal = this.modalService.open(this.mconfirm, {
      windowClass: "success-modal",
      backdrop: "static",
      keyboard: false,
    });
  }

  closeError() {
    this.activeModal.close();
  }
}
export interface PeriodicElement {
  page: string;
}
