import { Component, OnInit, ViewChild, Inject, Optional } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Validators, FormControl, FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUrl } from '../../../common/config/apiurl';
import { SegmentService } from '../../../common/_service/segment.service';
import { ConfigSettings } from '../../../common/config/config';

@Component({
  templateUrl: "./edit-artsettings.component.html",
  styleUrls: ["./edit-artsettings.component.css"],
})
export class EditArtSettingsComponent implements OnInit {
  @ViewChild('successrule', { static: true }) successrule: any;
  @ViewChild('errorrrule', { static: true }) errorrrule: any;
  @ViewChild('channelpageslist', { static: true }) channelpageslist: any;
  @ViewChild('pageslist', { static: true }) pageslist: any;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditArtSettingsComponent>,
    private _service: SegmentService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.advArtDT = data;
  }
  loader: boolean = false;
  selectedValue: any = '';
  advArtDT: object = [];
  config: any = ConfigSettings;
  success_msg: any = '';
  errorMessage: any = '';
  ruleEditForm: FormGroup;
  public roles: any = '';
  public selected_adv: any;
  public selected_adv_name: any;
  finalPagesList: any = [];
  advertiserList: any = [];
  pagesList: any = [];
  channelsPagesList: any = [];
  tblDT: any = [];
  closeMe() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.selected_adv = this.advArtDT['advertiser_id'];
    this.selected_adv_name = this.advArtDT['advertiser_name'];
    this.ruleEditForm = this.formBuilder.group({
      "adv_id": [this.advArtDT['advertiser_id']],
      "advertiser": [this.selected_adv_name, [Validators.required]],
    });
    this.getPages();
    this.selectedValue = this.selected_adv;
  }



  /**
 * @Method : getAdvertiser
 * @description : Get advertiser details
 **/
  getPages() {
    this.loader = true;
    var _url = AppUrl.getAPIURL();
    _url = _url + '/appsintegration/getEditPagesList/' + this.advArtDT['advertiser_id'];
    this._service._getApi(_url).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.finalPagesList = data['d']['pages'];
          this.pagesList = data['d']['maasExclusive'];
          this.renderPagesList(data['d']['maasExclusive']);
          this.channelsPagesList = data['d']['channles'];
          this.renderChannelsPagesList(data['d']['channles']);

        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
          this.closeMe();
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  /**
* @Method : renderPagesList
* @description : Render pages list
**/
  renderPagesList(data: any) {
    let xDt = [];
    let sarr = [];
    // this.tblDT.filter((elm: any) => {
    //   sarr.push(String(elm['id']));
    // });
    for (let key in data) {
      if (data[key]['id']) {
        let _ccode = data[key]['id'];
        // let _show = sarr.indexOf(String(_ccode)) != -1 ? true : false;
        xDt.push({ id: _ccode, n: data[key]['n'], show: data[key]['show'] });
      }
    }
    this.pageslist ? this.pageslist.pubDataList(xDt) : "";
  }



  removePagesTag(tagname: any) {
    for (let i = 0; i < this.pageslist.jsObj.length; i++) {
      if (this.pageslist.jsObj[i]['id'] == tagname['id']) {
        this.pageslist.jsObj[i]['show'] = false;
      }
    }
    this.pageslist.pubDataList(this.pageslist.jsObj);
  }


  /**
* @Method : renderPagesList
* @description : Render pages list
**/
  renderChannelsPagesList(chdata: any) {
    let xDt1 = [];
    let sarr1 = [];
    // this.tblDT.filter((elm: any) => {
    //   sarr1.push(String(elm['id']));
    // });
    for (let key in chdata) {
      if (chdata[key]['id']) {
        let _ccode = chdata[key]['id'];
        // let _show = sarr1.indexOf(String(_ccode)) != -1 ? true : false;
        xDt1.push({ id: _ccode, n: chdata[key]['n'], show: chdata[key]['show'] });
      }
    }
    this.channelpageslist ? this.channelpageslist.chpubDataList(xDt1) : "";
  }

  removeChannelPagesTag(tagname: any) {
    for (let i = 0; i < this.channelpageslist.jsObj.length; i++) {
      if (this.channelpageslist.jsObj[i]['id'] == tagname['id']) {
        this.channelpageslist.jsObj[i]['show'] = false;
      }
    }
    this.channelpageslist.chpubDataList(this.channelpageslist.jsObj);
  }


  /**
  * @Method : updateUser
  * @description : Update user details
  **/
  updateAppSetting() {
    let rdt = {};
    let user_id = this._service.getUserid();
    let _url = AppUrl.getAPIURL();
    _url = _url + '/appsintegration/updateArtAppSetting';

    let data = this.ruleEditForm.value;

    rdt = {
      user_id: user_id,
      adv_id: data['adv_id'],
      advertiser: data['advertiser'],
      channelpages_ids: this.channelpageslist.advLbl.join(),
      pages_ids: this.pageslist.advLbl.join()
    };
    let isvalid = this.isValidForm();
    if (isvalid == false) {
      return
    }
    this.loader = true;
    this._service._postApi(_url, rdt).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.s == this.config['STATUS_CODE']['OK']) {
          this.success_msg = data.msg;
          this.success(this.successrule);
          this.closeMe();
        } else {
          this.errorMessage = data.msg;
          this.error(this.errorrrule);
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );
  }

  channel_pages_selected: boolean = false;
  pages_selected: boolean = false;
  isValidForm() {
    if (!this.channelpageslist.advLbl || this.channelpageslist.advLbl.length == 0) {
      this.channel_pages_selected = true;
      return false;
    } else {
      this.channel_pages_selected = false;
    }

    if (!this.pageslist.advLbl || this.pageslist.advLbl.length == 0) {
      this.pages_selected = true;
      return false;
    } else {
      this.pages_selected = false;
    }

    if (this.ruleEditForm['status'] == this.config['INVALID_FORM']) {
      return false;
    }
    return true;
  }

  /**
* @Method : onChannelList
* @description : onChannelList change update list in table/modalbox
**/
  onChangeChannelList() {
    let selectedChannelsArr = this.channelpageslist.advLbl;
    let selectedPagesArr = this.pageslist.advLbl;
    let pagesArr = [];
    for (let i = 0; i < this.finalPagesList.length; i++) {
      if (selectedChannelsArr.indexOf(String(this.finalPagesList[i]['id'])) !== -1) {
        // pagesArr.push({ id: this.finalPagesList[i]['id'], n: this.finalPagesList[i]['n'], show: true });
      }
      else {
        if (selectedPagesArr.indexOf(String(this.finalPagesList[i]['id'])) !== -1) {
          pagesArr.push({ id: this.finalPagesList[i]['id'], n: this.finalPagesList[i]['n'], show: true });
        } else {
          pagesArr.push({ id: this.finalPagesList[i]['id'], n: this.finalPagesList[i]['n'], show: false });
        }
      }
    }
    if (this.finalPagesList.length == selectedChannelsArr.length) { // handling for checkall option
      this.pageslist._clearList();
    }
    this.renderPagesList(pagesArr);
  }

  onChangePagesList() {
    let selectedChannelsArr = this.channelpageslist.advLbl;
    let selectedPagesArr = this.pageslist.advLbl;
    let channelArr = [];
    for (let i = 0; i < this.finalPagesList.length; i++) {
      if (selectedPagesArr.indexOf(String(this.finalPagesList[i]['id'])) !== -1) {
        // pagesArr.push({ id: this.finalPagesList[i]['id'], n: this.finalPagesList[i]['n'], show: true });
      }
      else {
        if (selectedChannelsArr.indexOf(String(this.finalPagesList[i]['id'])) !== -1) {
          channelArr.push({ id: this.finalPagesList[i]['id'], n: this.finalPagesList[i]['n'], show: true });
        } else {
          channelArr.push({ id: this.finalPagesList[i]['id'], n: this.finalPagesList[i]['n'], show: false });
        }
      }
    }
    if (this.finalPagesList.length == selectedPagesArr.length) { // handling for checkall option
      this.channelpageslist._clearList();
    }
    this.renderChannelsPagesList(channelArr);
  }

  success(successrule: any) {
    this.activeModal = this.modalService.open(successrule, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }

  error(errortatus: any) {
    this.activeModal = this.modalService.open(errortatus, { windowClass: 'success-modal', backdrop: 'static', keyboard: false });
  }
}