import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UtilService {
    constructor() { }

    prepareIframe(iFrameUrl: any) {
        var ifrm = document.createElement("iframe");
        ifrm.setAttribute("src", iFrameUrl);
        ifrm.id = "frameStyle";
        ifrm.style.border = "0px";
        ifrm.frameBorder = "0";
        ifrm.allowFullscreen = true;
        return ifrm;
    }

  validateEmail(email: any) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(email)) {
      return { status: true };
    } else {
      return { status: false };
    }
  }
}
