import { Component, OnInit, Output, HostListener, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'geolist-list',
  templateUrl: './geolist-list.component.html',
  styleUrls: ['./geolist-list.component.css']
})
export class geolistComponent implements OnInit {
  @Output() sendFiltersData = new EventEmitter();
  public _range: any = 300;
  public jsObj: any;
  private _tchunk: number;
  public dstatus: boolean = false;
  aclfsearch = new FormControl('');
  public rData: any;

  private _nidx: number = 1;
  private _sindx: number = 1;
  private _stchunk: number = 0;
  private visibile: boolean = false;
  private checkFlag: boolean = true;
  private isChecked: boolean = true;

  private arrSearch: any = [];
  public filterTxt: any = '';
  private _docclickEvent: MouseEvent;
  isListDisable: boolean = false;

  public lblselect: any = 'Geo list';
  public allselect: any = 'All Selected';
  public listlabel: any = this.allselect;
  public itemselect: any = 'Geo Selected';

  private listWrap: any = "geolistContain";
  private parentElm: any = 'geolist';
  private nparentElm: any = '_geolistDropdown';
  public countryLbl: any = [];

  constructor(private activatedroute: ActivatedRoute, private router: Router) { }
  pubDataList(data: any,llabel:any) {
    this.lblselect = llabel ? llabel : 'Pages list';
    this.checkFlag = this.isChecked;
    if (!data.length) {
      return;
    }

    for (var i in data) {
      data[i]['id'] = (data[i]['id']).toString();
    }

    this.jsObj = data;
    this._tchunk = Math.ceil(this.jsObj.length / this._range);
    this._clearList();
    this._initList();

    this._showListLabel();
    this.createChechedLbl(this.jsObj);
    //this.sendFiltersData.emit(this.jsObj);
  }


  /**
   *@_initList : Render Dropdown list with first chunk data and reset all its var
  **/
  _initList() {
    this._nidx = 1;
    this._sindx = 1;
    this.makeSlice(0);
  }


  /**
   *@_checkAll : Chceck All checkbox of list
  **/
  _checkAll() {
    var div = document.getElementById(this.nparentElm);
    var a = div.getElementsByTagName("input");
    for (var i = 0; i < a.length; i++) {
      if (this.filterTxt.length) { //If search element then only checked searched element
        if (this.arrSearch.indexOf(a[i].id) > -1) {
          a[i].checked = true;
        }
      } else {
        a[i].checked = true;
      }
    }

    this.checkFlag = this.isChecked;
    if (this.jsObj.length) {
      for (var k in this.jsObj) {
        if (this.filterTxt.length) {
          if (this.arrSearch.indexOf(this.jsObj[k]['id']) > -1) {
            this.jsObj[k]['show'] = true;
          }
        } else {
          this.jsObj[k]['show'] = true;
        }
      }

      this._showListLabel();
    }
    this.createChechedLbl(this.jsObj);
    this.sendFiltersData.emit(this.jsObj);

  }


  /**
   *@_uncheckAll : UnChceck All checkbox of list
  **/
  _uncheckAll() {
    var div = document.getElementById(this.nparentElm);
    var a = div.getElementsByTagName("input");
    for (var i = 0; i < a.length; i++) {
      if (this.filterTxt.length) { //If search element then only checked searched element
        if (this.arrSearch.indexOf(a[i].id) > -1) {
          a[i].checked = false;
        }
      } else {
        a[i].checked = false;
      }
    }

    this.checkFlag = !this.isChecked;
    if (this.jsObj.length) {
      for (var k in this.jsObj) {
        if (this.filterTxt.length) {
          if (this.arrSearch.indexOf(this.jsObj[k]['id']) > -1) {
            this.jsObj[k]['show'] = false;
          }
        } else {
          this.jsObj[k]['show'] = false;
        }
      }
      this._showListLabel();
    }
    this.createChechedLbl(this.jsObj);
    this.sendFiltersData.emit(this.jsObj);

  }


  //Create label for dropdown list
  createChechedLbl(jsObj: any) {
    this.countryLbl.length = 0;
    for (let j in this.jsObj) {
      if (this.jsObj[j]['show']) {
        this.countryLbl.push(this.jsObj[j]['id']); //+= comm + (this.jsObj[j]['id']).toLowerCase();
      }
    }
  }
  /**
   *@Method : showListLabel
   *@Descrp :  Show "All select/Item seleted/ <number> + item selected" for dropdown label
  **/
  _showListLabel() {
    var trueelm = this.jsObj.filter((el: any) => { return el.show == true });
    var falseelm = this.jsObj.filter((el: any) => { return el.show == false });

    if (trueelm.length == this.jsObj.length) {
      this.listlabel = this.allselect;
    } else if (trueelm.length && trueelm.length < this.jsObj.length) {
      this.listlabel = trueelm.length + ' ' + this.itemselect;
    } else if (falseelm.length == this.jsObj.length) {
      this.listlabel = this.lblselect;
    }
  }
  /**
   * @makeSlice : Partition data and load into dropdown list
  **/
  makeSlice(_indx: number) {
    
    let lrange = _indx * this._range;
    let uprng = lrange + this._range;
    if (this.filterTxt.length) {
      var nArr = this.searchObj.slice(lrange, uprng);
    } else if (this.jsObj && this.jsObj.length) {
      var nArr = this.jsObj.slice(lrange, uprng);
    }

    var wrapr = document.getElementById(this.listWrap);
    if (wrapr) {
      for (var k in nArr) {
        var checkbox = document.createElement('input');
        checkbox.type = "checkbox";
        checkbox.name = "name";
        checkbox.checked = nArr[k]['show'];
        checkbox.style.margin = '0px 6px';

        checkbox.addEventListener("click", this.clickCheckbox);
        checkbox.id = nArr[k]['id'];
        var lblNode = document.createElement('label');
        lblNode.nodeValue = nArr[k]['id'];

        lblNode.id = nArr[k]['id'];
        lblNode.className = "chkLbl";
        lblNode.style.display = 'inline-flex';
        lblNode.style.width = '100%';
        lblNode.style.padding = '6px 6px 5px 16px';
        lblNode.style.color = '#393939';

        var lbltxt = document.createTextNode(nArr[k]['n']);
        lblNode.appendChild(checkbox);
        lblNode.appendChild(lbltxt);
        wrapr.appendChild(lblNode);
      }
    }
  }

  /**
     *@clicCheckbox : On click checkbox update the state
    **/
  clickCheckbox = ($event: any) => {
    var id = $event.target.id;
    for (var kk in this.jsObj) {
      if (this.jsObj[kk]['id'] == id) {
        this.jsObj[kk]['show'] = $event.target.checked ? true : false;
        break;
      }
    }
    this._showListLabel();
    this.createChechedLbl(this.jsObj);
    this.sendFiltersData.emit(this.jsObj);
  }


  /**
   * @Method  : ngOnInit
   * @Descr   : Render filter list if user type somethis in search box
  **/
  ngOnInit() {
    this.aclfsearch.valueChanges.pipe(
      debounceTime(400)
    )
      .subscribe((val: any) => {
        this.filterTxt = val.toUpperCase();
        this.filterList();
      });
  }

  /**
   *@Method : filterList
   *@Descrp : Filter the list on search in search box
  **/
  private searchObj: any = [];
  filterList() {
    this.arrSearch.length = 0;

    this._clearList();
    setTimeout(() => {
      if (this.filterTxt.length) {
        var str = this.filterTxt.toLowerCase();
        const regexp = new RegExp(this._escapeRegExp(str), 'i');
        this.searchObj = this.jsObj.filter((event: any) => regexp.test(event.n));

        this.arrSearch = this.searchObj.map((el: any) => { return (el.id).toString(); });
        this._stchunk = Math.ceil(this.searchObj.length / this._range);
      } else {
        this.searchObj = [];
        this.arrSearch = [];
      }
      this._initList();
    });
  }

  //On lazy load populate the dropdownlist
  private _escapeRegExp(str: string): string {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }

  /**
   * @Method : _dropen
   * @Descrp : Toggle the dropdown list  on click the label
   *           And keep only 500 Record in list if it is closed.
  **/
  _dropen() {
    document.getElementById(this.nparentElm).classList.toggle("show");
    this.dstatus = !this.dstatus;
    this._toggleList();
  }
  /**
  *@Method : _toggleList
  *@Descp : Clear list and load only define data in _range
  **/
  _toggleList() {
    if (!this.dstatus) {
      this._clearList();
      this._initList();
    }
  }

  /**
    *@Method : _clearList
    *Descrp : Clear all record of the dropdown list
    *
  **/
  _clearList() {
    var wrapr = document.getElementById(this.listWrap);
    if (wrapr && wrapr.firstChild) {
      while (wrapr.firstChild) {
        wrapr.removeChild(wrapr.firstChild);
      }
    }
  }

  /**
   * @Method  : checkScrollPosition
   * @Description : Detect bottom of scrolled of dropdown list.
   *               And render next chunk of data in dropdown list.
  **/
  checkScrollPosition(ev: any) {
    const scrollTop = ev.target.scrollTop;
    const scrollHeight = ev.target.scrollHeight;
    const scrollElementHeight = ev.target.clientHeight;
    const roundingPixel = 1;
    const gutterPixel = 1;
    if (
      scrollTop >=
      scrollHeight -
      1 * scrollElementHeight -
      roundingPixel -
      gutterPixel
    ) {
      if (this.filterTxt.length && this.searchObj.length) {
        this._searchPaging();
      }
      else {
        this._normalPaging();
      }
    }
  }

  /**
    *@Method  : _searchPaging
    *@Decript : Send data for pagination if user search.
                Apply pagination only for searched data/json
  **/
  _searchPaging() {
    if (this._sindx < this._stchunk) {
      this.makeSlice(this._nidx);
      this._sindx++;
    }
  }

  /**
    *@Method  : _normalPaging
    *@Decript : Send data for pagination if user not search anything.
                Apply pagination on all data
  **/
  _normalPaging() {
    if (this._nidx < this._tchunk) {
      this.makeSlice(this._nidx);
      this._nidx++;
    }
  }


  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (event != this._docclickEvent) {
      if (this.visibile) {
        document.getElementById(this.nparentElm).classList.remove('show');
        this._toggleList();
        this.visibile = false;
      }
    }
  }

  /**
   *@clickedOutside: When click out-side of dropdown box then close it.
  **/
  clickedInside(event: MouseEvent) {
    this._docclickEvent = event;
    this.visibile = true;
  }
}

