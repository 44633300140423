import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, Event,ActivatedRoute } from '@angular/router';
import { UtilService } from "../../common/_service/util.service";
import { AppUrl } from "../../common/config/apiurl";
import { SegmentService } from "../../common/_service/segment.service";

@Component({
  selector: 'customepage',
  styleUrls: ['custompage.component.css'],
  templateUrl: `customePage.html`
})
export class CustomPageComponent implements OnInit, OnDestroy {
  loader: boolean = false;
  showNoDataDiv: boolean = false;
  customSub:any;
  constructor(private router: Router,private route: ActivatedRoute, private _utilservice: UtilService, private _service: SegmentService,
  ) { 
    this.customSub = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          this.renderReport();
      }
    })
  }

  ngOnInit() {
    this.renderReport();
  }
  ngOnDestroy() {
    this.customSub.unsubscribe();
}

  renderReport() {

    let page_id = this.route.snapshot.paramMap.get('pageId');
    var _url = AppUrl.getAPIURL();
    let user_id = this._service.getUserid();
    this.loader = true;
    this.showNoDataDiv = false;
    _url =
      _url + "/analytics/getActivePageIframeUrl/" + page_id + "/" + user_id;
    this._service.getDefaultApi(_url).subscribe(
      (data: any) => {
        if (data.s == 200) {
          let iFrameUrl =
            data["d"][0] && data["d"][0]["iframe_url"] != ""
              ? data["d"][0]["iframe_url"]
              : "";
          if (iFrameUrl == "") {
            this.loader = false;
            this.showNoDataDiv = true;
          } else {
            let ifrm = this._utilservice.prepareIframe(iFrameUrl);
            if(document.getElementById("dashboard")){
              let mainDiv = document.getElementById("dashboard");
              while (mainDiv.hasChildNodes())
              mainDiv.removeChild(mainDiv.firstChild)
              mainDiv.appendChild(ifrm);
            }
            window.setTimeout(() => {
              var iframe: any = document.getElementById("frameStyle");
              if(iframe){
                iframe.style.height = '4000px';
                iframe.style.width = '100%';
              }
              var iframeDoc =
                iframe.contentDocument || iframe.contentWindow.document;
              if (iframeDoc.readyState == "complete") {
                iframe.onload = () => {
                  this.loader = false;
                };
                return;
              }
            }, 100);
          }
        }
      },
      (error: any) => {
        this.loader = false;
        console.log(error);
      }
    );

    // if (URL) {
    //   let ifrm = this._utilservice.prepareIframe(URL);
    //   document.getElementById("dashboard").appendChild(ifrm);
    //   window.setTimeout(() => {
    //     var iframe: any = document.getElementById("frameStyle");
    //     var iframeDoc =
    //       iframe.contentDocument || iframe.contentWindow.document;
    //     if (iframeDoc.readyState == "complete") {
    //       iframe.onload = () => {
    //         this.loader = false;
    //       };
    //       return;
    //     }
    //   }, 100);
    // } else{
    //   this.loader = false;
    //   this.showNoDataDiv = true;
    // }
  }

}
